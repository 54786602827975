import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';

import { S3_FOLDER } from '@/config/constants';
import {
  useGetContractByAgentQuery,
  useUpdateContractAgentMutation,
} from '@/apis/extended/contractApi';
import { useGetSignedUrlMutation } from '@/apis/extended/globalApi';
import { uploadFile } from '@/utils/storage';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonMultipleFileInput } from '../Common/CommonMultipleFileInput';

type FormType = {
  sellerName: string;
  sellerEmail: string;
  sellerPhone: string;
  seller2Name: string;
  seller2Email: string;
  seller2Phone: string;
  sellerPOA: boolean;
  sellerLLC: boolean;
  legalDescription: string;
  hoaDeclaration: string;
  condoDeclaration: string;
  sellerDisclosures: string[];
};

const initialForm: FormType = {
  sellerName: '',
  sellerEmail: '',
  sellerPhone: '',
  seller2Name: '',
  seller2Email: '',
  seller2Phone: '',
  sellerPOA: false,
  sellerLLC: false,
  legalDescription: '',
  hoaDeclaration: '',
  condoDeclaration: '',
  sellerDisclosures: [],
};

type ContractDetailAgentSellerInfoProps = {
  uid: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailAgentSellerInfo = ({
  uid,
  onPrev,
  onNext,
}: ContractDetailAgentSellerInfoProps) => {
  const { data: contract } = useGetContractByAgentQuery(uid);
  const [updateContractAgent, { isLoading }] = useUpdateContractAgentMutation();
  const [getSignedUrl] = useGetSignedUrlMutation();

  const [form, setForm] = useState(initialForm);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const agentSellerInfo = contract?.agentSellerInfo;
    if (!agentSellerInfo) return;
    setForm({
      sellerName: agentSellerInfo.sellerName || '',
      sellerEmail: agentSellerInfo.sellerEmail || '',
      sellerPhone: agentSellerInfo.sellerPhone || '',
      seller2Name: agentSellerInfo.seller2Name || '',
      seller2Email: agentSellerInfo.seller2Email || '',
      seller2Phone: agentSellerInfo.seller2Phone || '',
      sellerPOA: agentSellerInfo.sellerPOA || false,
      sellerLLC: agentSellerInfo.sellerLLC || false,
      legalDescription: agentSellerInfo.legalDescription || '',
      hoaDeclaration: agentSellerInfo.hoaDeclaration || '',
      condoDeclaration: agentSellerInfo.condoDeclaration || '',
      sellerDisclosures: agentSellerInfo.sellerDisclosures || [],
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return (
      form.sellerName &&
      form.sellerEmail &&
      form.sellerPhone &&
      form.legalDescription &&
      form.hoaDeclaration &&
      form.condoDeclaration
    );
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const handleAddSellerDisclosure = async (file: File | null) => {
    if (!file) return;
    let documentUrl;
    setIsUploading(true);
    try {
      const signedUrl = await getSignedUrl({
        folderName: S3_FOLDER.DISCLOSURE,
        fileName: file.name,
      }).unwrap();
      if (signedUrl) {
        documentUrl = await uploadFile(signedUrl, file);
      }

      if (!documentUrl) return;

      setForm({
        ...form,
        sellerDisclosures: [...form.sellerDisclosures, documentUrl],
      });
    } catch (err) {
      console.error(err);
    }
    setIsUploading(false);
  };

  const handleRemoveSellerDisclosure = (url: string) => {
    setForm({
      ...form,
      sellerDisclosures: form.sellerDisclosures.filter((item) => item !== url),
    });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractAgent({
      uid,
      agentSellerInfo: {
        ...form,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Seller Information"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-user" />}
        />
        <CardContent>
          <CommonFormControl label="Seller Name:" labelWidth={150}>
            <TextField
              placeholder="Seller Name"
              name="sellerName"
              value={form.sellerName}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Seller Email:"
            labelWidth={150}
          >
            <TextField
              placeholder="Seller Email"
              name="sellerEmail"
              value={form.sellerEmail}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Seller Phone:"
            labelWidth={150}
          >
            <TextField
              placeholder="Seller Phone"
              name="sellerPhone"
              value={form.sellerPhone}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Seller Name 2:"
            labelWidth={150}
          >
            <TextField
              placeholder="Seller Name 2 (Optional)"
              name="seller2Name"
              value={form.seller2Name}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Seller Email 2:"
            labelWidth={150}
          >
            <TextField
              placeholder="Seller Email 2 (Optional)"
              name="seller2Email"
              value={form.seller2Email}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Seller Phone 2:"
            labelWidth={150}
          >
            <TextField
              placeholder="Seller Phone 2 (Optional)"
              name="seller2Phone"
              value={form.seller2Phone}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.sellerPOA}
                  name="sellerPOA"
                  onChange={handleCheckboxChange}
                />
              }
              label="Seller POA"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.sellerLLC}
                  name="sellerLLC"
                  onChange={handleCheckboxChange}
                />
              }
              label="Seller LLC"
            />
          </Box>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Legal Description:"
            labelWidth={150}
          >
            <TextField
              fullWidth
              placeholder="Legal Description"
              name="legalDescription"
              value={form.legalDescription}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="HOA Declaration:"
            labelWidth={150}
          >
            <TextField
              fullWidth
              placeholder="HOA Declaration"
              name="hoaDeclaration"
              value={form.hoaDeclaration}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Condo Declaration:"
            labelWidth={150}
          >
            <TextField
              fullWidth
              placeholder="Condo Declaration"
              name="condoDeclaration"
              value={form.condoDeclaration}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Seller Disclosures:"
            labelWidth={150}
            sx={{ mt: 1 }}
          >
            <CommonMultipleFileInput
              urls={form.sellerDisclosures}
              isLoading={isUploading}
              onFileChange={handleAddSellerDisclosure}
              label="Add Disclosure Document"
              onRemove={handleRemoveSellerDisclosure}
            />
          </CommonFormControl>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
