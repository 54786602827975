import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import validator from 'validator';

import { useSignInMutation } from '@/apis/extended/authApi';

type SigninModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SigninModal = ({ isOpen, onClose }: SigninModalProps) => {
  const theme = useTheme();
  const [signIn] = useSignInMutation();
  const [isVerifyRequired, setIsVerifyRequired] = useState(false);
  const [error, setError] = useState('');

  const [email, setEmail] = useState('');

  const handleSignIn = () => {
    if (!validator.isEmail(email)) {
      setError('Please input valid email address.');
      return;
    }
    signIn({ email })
      .unwrap()
      .then((res) => {
        if (res.verify) {
          setIsVerifyRequired(true);
        }
      });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        sx={{ py: '60px', px: '32px', width: '450px', maxWidth: '100%' }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h2">Welcome to</Typography>
          <Typography
            variant="h2"
            sx={{ color: theme.palette.mainColors.blue }}
          >
            Unbeatable loans
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.greyShades.grey1 }}
          >
            Get started by creating an account. It's free.
          </Typography>
        </Box>
        <Box sx={{ mt: 5 }}>
          <TextField
            label="Email"
            fullWidth
            size="small"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            helperText={error}
            error={!!error}
          />
          {isVerifyRequired ? (
            <Typography
              variant="body1"
              sx={{
                mt: 3,
                textAlign: 'center',
                color: theme.palette.error.main,
              }}
            >
              Your account is secured.
              <br />
              Please check your email to complete login.
            </Typography>
          ) : (
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 3 }}
              onClick={handleSignIn}
            >
              Sign in
            </Button>
          )}
        </Box>
        {!isVerifyRequired && (
          <Box
            sx={{ mt: 1, textAlign: 'center', maxWidth: '300px', mx: 'auto' }}
          >
            <Typography variant="caption">
              By continuing, you agree to our{' '}
              <Link to="/toc">Terms of services</Link> and{' '}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
