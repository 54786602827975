import { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';

import { useAddFeedbackMutation } from '@/apis/extended/globalApi';
import { useGetCurrentUserQuery } from '@/apis/extended/userApi';
import { CommonDialog } from '../Common/CommonDialog';
import { CommonFormControl } from '../Common/CommonFormControl';

const initialForm = {
  name: '',
  email: '',
  content: '',
};

type AddFeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddFeedbackModal = ({
  isOpen,
  onClose,
}: AddFeedbackModalProps) => {
  const [form, setForm] = useState({ ...initialForm });

  const { data: user } = useGetCurrentUserQuery();
  const [addFeedback, { isLoading }] = useAddFeedbackMutation();

  useEffect(() => {
    if (!user) return;
    setForm({
      ...form,
      email: user.email || form.name,
      name: user.name || form.name,
    });
  }, [user]);

  const handleAddFeedback = async () => {
    if (!form.name || !form.email || !form.content) return;
    await addFeedback({
      name: form.name,
      email: form.email,
      content: form.content,
    }).unwrap();
    setForm({ ...form, content: '' });
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      title="Leave Feedback"
      icon="fa-light fa-hexagon-plus"
      confirmButtonText="Submit"
      closeButtonText="Close"
      isLoading={isLoading}
      onConfirm={handleAddFeedback}
      onClose={onClose}
    >
      <Box sx={{ maxWidth: '400px' }}>
        <Typography variant="body1">
          Thanks in advance for your help in making Unbeatable better. We are
          excited to hear from you.
        </Typography>
      </Box>
      <CommonFormControl
        label="Name:"
        sx={{ mt: 3 }}
        error={form.name ? '' : 'This field is required!'}
      >
        <TextField
          placeholder="Name"
          name="name"
          value={form.name}
          onChange={handleInputChange}
        />
      </CommonFormControl>
      <CommonFormControl
        label="Email:"
        sx={{ mt: 1 }}
        error={form.email ? '' : 'This field is required!'}
      >
        <TextField
          placeholder="Email"
          name="email"
          value={form.email}
          onChange={handleInputChange}
        />
      </CommonFormControl>
      <CommonFormControl
        label="Comment:"
        sx={{ mt: 1 }}
        error={form.content ? '' : 'This field is required!'}
      >
        <TextField
          placeholder="Comment"
          name="content"
          multiline
          rows={4}
          value={form.content}
          onChange={handleInputChange}
        />
      </CommonFormControl>
    </CommonDialog>
  );
};
