import { getToken, logout } from '@/utils/auth';
import axios from 'axios';

const AXIOS_TIMEOUT_LIMIT = 30000;

export const apiBaseUrl = process.env.VITE_API_BASE_URL || '';

export const rawApi = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: AXIOS_TIMEOUT_LIMIT,
});

export const setAuthInterceptors = () => {
  const token = getToken();
  if (!token) return;

  rawApi.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  rawApi.interceptors.response.clear();
  rawApi.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        console.log('Unauthorized');
        logout();
      }
      return Promise.reject(error);
    }
  );
};
