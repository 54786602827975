import { useEffect, useMemo, useState } from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';

import { CONTRACT_SELLER_STEP, contractSellerSteps } from '@/config/constants';
import { useGetContractBySellerQuery } from '@/apis/extended/contractApi';
import { Loader } from '../Layout/Loader';
import { ContractDetailSellerInfo } from './ContractDetailSellerInfo';
import { ContractDetailSellerPropertyDisclosure } from './ContractDetailSellerPropertyDisclosure';
import { ContractDetailSellerPropertyInfo } from './ContractDetailSellerPropertyInfo';
import { ContractDetailSellerReview } from './ContractDetailSellerReview';
import { ContractDetailSellerSuccess } from './ContractDetailSellerSuccess';

type ContractDetailSellerProps = {
  uid: string;
};

export const ContractDetailSeller = ({ uid }: ContractDetailSellerProps) => {
  const { data: contract, isLoading } = useGetContractBySellerQuery(uid);

  const [step, setStep] = useState(-1);

  const currentStep = useMemo(() => {
    if (!contract) return 0;

    if (contract.sellerSign?.isStored) return CONTRACT_SELLER_STEP.COMPLETED;
    if (contract.sellerPropertyInfo?.isStored)
      return CONTRACT_SELLER_STEP.REVIEW;
    if (contract.sellerPropertyDisclosure?.isStored)
      return CONTRACT_SELLER_STEP.PROPERTY_INFO;
    if (contract.sellerInfo?.isStored)
      return CONTRACT_SELLER_STEP.PROPERTY_DISCLOSURES;
    return CONTRACT_SELLER_STEP.SELLER_INFO;
  }, [contract]);

  useEffect(() => {
    if (!contract || step !== -1) return;

    setStep(currentStep);
  }, [contract, currentStep]);

  if (isLoading) return <Loader isFull />;
  if (!contract) return null;

  const handleClickStep = (step: number) => {
    if (step > currentStep) return;
    setStep(step);
  };

  return (
    <Box>
      <Stepper activeStep={step} alternativeLabel>
        {contractSellerSteps.map((label, idx) => (
          <Step
            key={label}
            completed={idx < currentStep}
            sx={{ cursor: idx <= currentStep ? 'pointer' : 'unset' }}
            disabled={idx > currentStep}
            onClick={() => handleClickStep(idx)}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 8, mx: 'auto', maxWidth: '600px' }}>
        {step === CONTRACT_SELLER_STEP.SELLER_INFO && (
          <ContractDetailSellerInfo
            uid={uid}
            onNext={() => setStep(CONTRACT_SELLER_STEP.PROPERTY_DISCLOSURES)}
          />
        )}
        {step === CONTRACT_SELLER_STEP.PROPERTY_DISCLOSURES && (
          <ContractDetailSellerPropertyDisclosure
            uid={uid}
            onPrev={() => setStep(CONTRACT_SELLER_STEP.SELLER_INFO)}
            onNext={() => setStep(CONTRACT_SELLER_STEP.PROPERTY_INFO)}
          />
        )}
        {step === CONTRACT_SELLER_STEP.PROPERTY_INFO && (
          <ContractDetailSellerPropertyInfo
            uid={uid}
            onPrev={() => setStep(CONTRACT_SELLER_STEP.PROPERTY_DISCLOSURES)}
            onNext={() => setStep(CONTRACT_SELLER_STEP.REVIEW)}
          />
        )}
        {step >= CONTRACT_SELLER_STEP.REVIEW &&
          (currentStep === CONTRACT_SELLER_STEP.COMPLETED ? (
            <ContractDetailSellerSuccess />
          ) : (
            <ContractDetailSellerReview
              uid={uid}
              onPrev={() => setStep(CONTRACT_SELLER_STEP.PROPERTY_INFO)}
              onNext={() => {}}
            />
          ))}
      </Box>
    </Box>
  );
};
