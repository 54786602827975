import { useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material';

import {
  useGetContractBySellerQuery,
  useUpdateContractSellerMutation,
} from '@/apis/extended/contractApi';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';

const initialForm = {
  name: '',
  email: '',
  phone: '',
  name2: '',
  email2: '',
  phone2: '',
};

type ContractDetailSellerInfoProps = {
  uid: string;
  onNext: () => void;
};

export const ContractDetailSellerInfo = ({
  uid,
  onNext,
}: ContractDetailSellerInfoProps) => {
  const { data: contract } = useGetContractBySellerQuery(uid);
  const [updateContractSeller, { isLoading }] =
    useUpdateContractSellerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const sellerInfo = contract?.sellerInfo;
    if (!sellerInfo) return;
    setForm({
      name: sellerInfo.name || '',
      email: sellerInfo.email || '',
      phone: sellerInfo.phone || '',
      name2: sellerInfo.name2 || '',
      email2: sellerInfo.email2 || '',
      phone2: sellerInfo.phone2 || '',
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return form.name && form.email && form.phone;
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractSeller({
      uid,
      sellerInfo: {
        ...form,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Seller Information"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-user" />}
        />
        <CardContent>
          <CommonFormControl label="Legal Name:" labelWidth={150}>
            <TextField
              placeholder="Legal Name"
              name="name"
              value={form.name}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl sx={{ mt: 2 }} label="Email:" labelWidth={150}>
            <TextField
              placeholder="Email"
              name="email"
              value={form.email}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl sx={{ mt: 2 }} label="Phone:" labelWidth={150}>
            <TextField
              placeholder="Phone"
              name="phone"
              value={form.phone}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl sx={{ mt: 2 }} label="Name 2:" labelWidth={150}>
            <TextField
              placeholder="Name 2 (Optional)"
              name="name2"
              value={form.name2}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl sx={{ mt: 2 }} label="Email 2:" labelWidth={150}>
            <TextField
              placeholder="Email 2 (Optional)"
              name="email2"
              value={form.email2}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl sx={{ mt: 2 }} label="Phone 2:" labelWidth={150}>
            <TextField
              placeholder="Phone 2 (Optional)"
              name="phone2"
              value={form.phone2}
              onChange={handleInputChange}
            />
          </CommonFormControl>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onNext={handleSubmit}
      />
    </Box>
  );
};
