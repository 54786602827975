import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from '@mui/material';

import {
  useGetContractQuery,
  useUpdateContractSellerMutation,
} from '@/apis/extended/contractApi';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';

const initialForm = {
  name: '',
};

type ContractDetailSellerReviewProps = {
  uid: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailSellerReview = ({
  uid,
  onPrev,
  onNext,
}: ContractDetailSellerReviewProps) => {
  const { data: contract } = useGetContractQuery(uid);
  const [updateContractSeller, { isLoading }] =
    useUpdateContractSellerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const sellerSign = contract?.sellerSign;
    if (!sellerSign) return;
    setForm({
      name: sellerSign.name || '',
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return form.name;
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractSeller({
      uid,
      sellerSign: {
        ...form,
      },
    }).unwrap();
    onNext();
  };
  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Review Contract"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-sparkles" />}
        />
        <CardContent>
          <Typography>
            Please confirm that you have reviewed all the details. Please enter
            your name below to confirm and submit.
          </Typography>
          <CommonFormControl
            label="Legal Full Name:"
            labelWidth={200}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Legal Full Name"
              name="name"
              fullWidth
              value={form.name}
              onChange={handleInputChange}
            />
          </CommonFormControl>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
        nextText="Submit"
      />
    </Box>
  );
};
