import { Chip } from '@mui/material';

import { USER_APPROVE_STATUS, USER_QUALIFY_STATUS } from '@/config/constants';
import { useProductStatus } from '@/hooks/useProductStatus';
import {
  Product,
  PRODUCT_APPROVE_STATUS,
  PRODUCT_QUALIFY_STATUS,
} from '@/types/ProductType';

type ProductBadgesProps = {
  product: Product;
};

export const ProductBadges = ({ product }: ProductBadgesProps) => {
  const { qualifyStatus, approveStatus } = useProductStatus(product);

  if (!product.user) return null;
  const isUserApproved =
    product.user.approveStatus === USER_APPROVE_STATUS.APPROVED &&
    product.user.approvalAmount;
  const isUserQualified =
    product.user.qualifyStatus === USER_QUALIFY_STATUS.QUALIFY;

  return (
    <>
      {!isUserApproved &&
        isUserQualified &&
        (qualifyStatus === PRODUCT_QUALIFY_STATUS.QUALIFIED ? (
          <Chip
            label="Qualified"
            color="success"
            icon={<i className="fa-light fa-circle-o" />}
          />
        ) : (
          <Chip
            label="Unqualified"
            color="error"
            icon={<i className="fa-light fa-circle-x" />}
          />
        ))}
      {isUserApproved &&
        (approveStatus === PRODUCT_APPROVE_STATUS.APPROVED ? (
          <Chip
            label="Approved"
            color="success"
            icon={<i className="fa-light fa-circle-o" />}
          />
        ) : (
          <Chip
            label="Not Approved"
            color="error"
            icon={<i className="fa-light fa-circle-x" />}
          />
        ))}
    </>
  );
};
