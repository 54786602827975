import { SxProps } from '@mui/material';

import { USER_APPROVE_STATUS, USER_QUALIFY_STATUS } from '@/config/constants';
import { useGetCurrentUserQuery } from '@/apis/extended/userApi';
import { GetApproveButton } from './GetApproveButton';
import { SeeQualifyButton } from './SeeQualifyButton';

type UserActionButtonProps = {
  sx?: SxProps;
};

export const UserActionButton = ({ sx }: UserActionButtonProps) => {
  const { data: user } = useGetCurrentUserQuery();

  if (!user) return null;
  if (user.qualifyStatus !== USER_QUALIFY_STATUS.QUALIFY) {
    return <SeeQualifyButton sx={sx} />;
  }
  if (user.approveStatus !== USER_APPROVE_STATUS.APPROVED) {
    return <GetApproveButton sx={sx} />;
  }
  return null;
};
