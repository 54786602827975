import {
  Box,
  Button,
  ButtonProps,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

type MediaObjectProps = {
  img: string;
  direction?: 'row' | 'row-reverse';
  heading: string;
  headingSecondary: string;
  text: string;
  buttons: ButtonProps[];
};

export const MediaObject = ({
  img,
  direction,
  heading = 'row',
  headingSecondary,
  text,
  buttons,
}: MediaObjectProps) => {
  const theme = useTheme();
  return (
    <Grid container spacing={6} alignItems="center" direction={direction}>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            alt="house placeholder"
            src={img}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography
            variant="h3"
            sx={{
              color: theme.palette.mainColors.white,
              fontSize: { md: '36pt' },
            }}
          >
            {heading}{' '}
            <span style={{ color: theme.palette.mainColors.secondary }}>
              {headingSecondary}
            </span>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.greyShades.grey1,
              fontSize: { md: '16pt' },
            }}
          >
            {text}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            {buttons.map((button, idx) => (
              <Button
                key={idx}
                size="large"
                sx={{ width: { xs: '100%', md: 'auto' }, ...button.sx }}
                {...button}
              />
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
