import axios from 'axios';

export const uploadFile = async (signedUrl: string, file: File) => {
  await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  return signedUrl.split('?')[0];
};
