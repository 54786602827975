import { Card, CardContent, CardHeader } from '@mui/material';

import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailCashToCloseProps = {
  product: Product;
};

export const ProductDetailCashToClose = ({
  product,
}: ProductDetailCashToCloseProps) => {
  const { estimation } = useEstimation(product);

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Calculating Cash to Close"
        avatar={<i className="fa-light fa-sack-dollar" />}
      />
      <CardContent>
        <CommonFormControl
          label="Down Payment:"
          secondLabel={formatPrice(estimation.downPayment)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Cash to Close without Unbeatable:"
          secondLabel={formatPrice(estimation.cashToClose)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Unbeatable Offer for Cash to Close:"
          secondLabel={formatPrice(estimation.unbeatableDeal)}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
