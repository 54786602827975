import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';

import { useStartContractMutation } from '@/apis/extended/productApi';
import { Logo } from '../Logo/Logo';

type ContractNotStartedProps = {
  productId: string;
};

export const ContractNotStarted = ({ productId }: ContractNotStartedProps) => {
  const [startContract, { isLoading }] = useStartContractMutation();

  const handleStartContract = () => {
    startContract(productId);
  };

  return (
    <Box
      sx={{
        minHeight: '70vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Logo />
      <Typography sx={{ mt: 5 }} variant="h4" align="center">
        The contract has not started.
      </Typography>
      <LoadingButton
        variant="contained"
        loading={isLoading}
        onClick={handleStartContract}
      >
        Start Contract
      </LoadingButton>
    </Box>
  );
};
