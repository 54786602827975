import { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const contents = [
  {
    heading: 'Your Purchase Starts Here',
    text: 'AI-powered home shopping: Get mortgage approval all agent-free for big savings',
  },
  {
    heading: 'for First Time Homebuyers',
    text: 'Compare real mortgage rates, make an offer without an agent and save.',
  },
  {
    heading: 'for Cash Offers',
    text: 'Make an offer without an agent and save.',
  },
  {
    heading: 'for Investors',
    text: 'Compare loans and lenders, make an offer without an agent and save.',
  },
];

export const HeroText = () => {
  const theme = useTheme();

  const [index, setIndex] = useState(0);
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowText(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % contents.length);
        setShowText(true);
      }, 500); // Adjust the delay to match the transition duration
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
      <Typography
        sx={{
          color: theme.palette.mainColors.secondary,
          fontSize: { xs: '32pt', md: '48pt' },
        }}
        variant="h1"
      >
        Unbeatable Loans{' '}
        <span
          style={{
            opacity: showText ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            color: theme.palette.mainColors.white,
          }}
        >
          {contents[index].heading}
        </span>
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 2,
          opacity: showText ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
          fontSize: { xs: '16pt', md: '24pt' },
          color: theme.palette.greyShades.grey1,
        }}
      >
        {contents[index].text}
      </Typography>
    </Box>
  );
};
