import { useTheme } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

export const CommonDataGrid = (props: DataGridProps) => {
  const theme = useTheme();
  return (
    <DataGrid
      sx={{
        '--DataGrid-rowBorderColor': theme.palette.greyShades.border1,
        '--DataGrid-containerBackground': theme.palette.mainColors.background1,
        '--unstable_DataGrid-overlayBackground':
          theme.palette.mainColors.backgroundOverlay,
        '--unstable_DataGrid-radius': '8px',
        borderColor: theme.palette.greyShades.border1,
        backgroundColor: theme.palette.mainColors.backgroundOpacity1,
        minHeight: '300px',
        '& .MuiDataGrid-withBorderColor': {
          borderColor: theme.palette.greyShades.border1,
        },
        '& .MuiDataGrid-columnHeaders': {
          '& [role=row]': {
            background: theme.palette.mainColors.background1,
          },
        },
        '& .MuiTablePagination-root': {
          '& .MuiSelect-select': {
            paddingBottom: 0,
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.greyShades.grey1,
          },
        },
        '& .MuiDataGrid-cell': {
          display: 'flex',
          alignItems: 'center',
          '&.MuiDataGrid-cell--editing': {
            backgroundColor: 'transparent',
            alignItems: 'center',
          },
        },
      }}
      disableColumnMenu
      disableColumnSorting
      paginationMode="server"
      pageSizeOptions={[5, 15, 20, 50]}
      getRowId={(row) => row.id || row._id}
      {...props}
    />
  );
};
