import { useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';

import { USER_LOAN_PROGRAM } from '@/config/constants';
import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { ProductDefaultEsitmation } from '@/utils/calculator/default';
import { ProductFHAEsitmation } from '@/utils/calculator/FHA';
import { ProductUSDAEsitmation } from '@/utils/calculator/USDA';
import { ProductVAEsitmation } from '@/utils/calculator/VA';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailLoanCostsProps = {
  product: Product;
};

export const ProductDetailLoanCosts = ({
  product,
}: ProductDetailLoanCostsProps) => {
  const { estimation } = useEstimation(product);

  const rentalIncomeReportFee = useMemo(() => {
    if (!estimation) return;
    if (
      estimation.type === USER_LOAN_PROGRAM.DEFAULT ||
      USER_LOAN_PROGRAM.DSCR
    ) {
      return (estimation as ProductDefaultEsitmation).rentalIncomeReportFee;
    }
    return;
  }, [estimation]);

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Loan Costs"
        avatar={<i className="fa-light fa-land-mine-on" />}
      />
      <CardContent>
        <Typography variant="h5" fontWeight={700}>
          Origination Charges
        </Typography>
        <CommonFormControl
          label="Processing Fee:"
          secondLabel={formatPrice(estimation.processingFeeBase)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Underwriting Fee:"
          secondLabel={formatPrice(estimation.underwritingFee)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Application Fee:"
          secondLabel={formatPrice(estimation.applicationFeeBase)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Lender Origination:"
          secondLabel={formatPrice(estimation.lenderCredits)}
          inLine
          labelWidth={270}
        />
        <Divider />
        <Typography variant="h5" fontWeight={700}>
          Charges for Services customer cannot shop for
        </Typography>
        {estimation.type === USER_LOAN_PROGRAM.FHA && (
          <CommonFormControl
            label="Upfront FHA Mortgage Insurance(Financed):"
            secondLabel={formatPrice(
              (estimation as ProductFHAEsitmation).upfrontMIPfee
            )}
            inLine
            labelWidth={270}
          />
        )}
        {estimation.type === USER_LOAN_PROGRAM.USDA && (
          <CommonFormControl
            label="USDA Upfront Guarantee Fee:"
            secondLabel={formatPrice(
              (estimation as ProductUSDAEsitmation).upfrontgauranteeFee
            )}
            inLine
            labelWidth={270}
          />
        )}
        {estimation.type === USER_LOAN_PROGRAM.VA && (
          <CommonFormControl
            label="VA Funding Fee:"
            secondLabel={formatPrice(
              (estimation as unknown as ProductVAEsitmation).upfrontFundingFee
            )}
            inLine
            labelWidth={270}
          />
        )}
        <CommonFormControl
          label="Appraisal Fee:"
          secondLabel={formatPrice(estimation.appraisalFeeBase)}
          inLine
          labelWidth={270}
        />
        {rentalIncomeReportFee !== undefined && (
          <CommonFormControl
            label="Optional Rental Income Report Fee:"
            secondLabel={formatPrice(rentalIncomeReportFee)}
            inLine
            labelWidth={270}
          />
        )}
        <CommonFormControl
          label="Credit Report Fee:"
          secondLabel={formatPrice(estimation.creditReportFeeBase)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Flood Monitoring Fee:"
          secondLabel={formatPrice(estimation.floodMonitoringFee)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Flood Determination Fee:"
          secondLabel={formatPrice(estimation.floodDeterminationFee)}
          inLine
          labelWidth={270}
        />
        <Divider />
        <Typography variant="h5" fontWeight={700}>
          Charges for Services customer can shop for
        </Typography>
        <CommonFormControl
          label="Settlement Agent Fee:"
          secondLabel={formatPrice(estimation.titleSettlementAgentFee)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Survey Fee:"
          secondLabel={formatPrice(estimation.surveyFee)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Lenders Title Insurance:"
          secondLabel={formatPrice(estimation.titleLendersTitleInsuranceBase)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Title: Title Search Fee:"
          secondLabel={formatPrice(estimation.titleTitleSearchBase)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Title: Recording Mortgage Fee:"
          secondLabel={formatPrice(estimation.recordingMortgage)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Title: Insurance Binder:"
          secondLabel={formatPrice(estimation.insuranceBinder)}
          inLine
          labelWidth={270}
        />
        <Divider />
        <Typography variant="h5" fontWeight={700}>
          Totals
        </Typography>
        <CommonFormControl
          label="Origination, Services Totals:"
          secondLabel={formatPrice(estimation.costTotals)}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
