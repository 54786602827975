import { useMemo } from 'react';

import {
  PRODUCT_APPROVE_PRICE_RATIO,
  USER_APPROVE_STATUS,
} from '@/config/constants';
import { useGetInterestRateQuery } from '@/apis/extended/globalApi';
import { calculatePurchasingPower } from '@/utils/calculator';
import { Product } from '@/types/ProductType';

export const useQualifiedProducts = (products?: Product[]) => {
  const { data: interestRate } = useGetInterestRateQuery();

  const qualifiedProducts = useMemo(() => {
    if (!products?.length) return [];

    return products.filter((product) => {
      const price = product.loanInfo?.offerPrice || product.price;

      if (
        product?.user?.approvalAmount &&
        product?.user?.approveStatus === USER_APPROVE_STATUS.APPROVED
      ) {
        return (
          product.user.approvalAmount >=
          price * (1 - PRODUCT_APPROVE_PRICE_RATIO)
        );
      }

      const loanInfo = product?.user?.loanInfo;
      if (!loanInfo || !interestRate) return 0;
      const { monthlyDebt, monthlyIncome, creditScore, downPayment, loanTerm } =
        loanInfo;
      if (!monthlyIncome) return 0;

      const purchasingPower = calculatePurchasingPower({
        monthlyIncome,
        creditScore,
        downPayment,
        monthlyDebt,
        loanTerm,
        interestRate,
      });
      return purchasingPower >= price * (1 - PRODUCT_APPROVE_PRICE_RATIO);
    });
  }, [products, interestRate]);

  return qualifiedProducts;
};
