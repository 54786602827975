import { AdminLayout } from './components/Layout/Admin/AdminLayout';
import { AppLayout } from './components/Layout/App/AppLayout';
import { AdminAgent } from './pages/Admin/AdminAgent';
import { AdminEmail } from './pages/Admin/AdminEmail';
import { AdminFeedback } from './pages/Admin/AdminFeedback';
import { AdminProduct } from './pages/Admin/AdminProduct';
import { AdminProductDetail } from './pages/Admin/AdminProductDetail';
import { AdminUser } from './pages/Admin/AdminUser';
import { ContractCartPage } from './pages/App/ContractCartPage';
import { ContractDetailAgentPage } from './pages/App/ContractDetailAgentPage';
import { ContractDetailPage } from './pages/App/ContractDetailPage';
import { ContractDetailSellerPage } from './pages/App/ContractDetailSellerPage';
import { MainCartPage } from './pages/App/MainCartPage';
import { ProductDetailPage } from './pages/App/ProductDetailPage';
import { ProfilePage } from './pages/App/ProfilePage';
import { QualifiedCartPage } from './pages/App/QualifiedCartPage';
import { DataRequest } from './pages/Basic/DataRequest';
import { LandingPage } from './pages/Basic/LandingPage';
import { PrivacyPolicy } from './pages/Basic/PrivacyPolicy';
import { Support } from './pages/Basic/Support';
import { TermsOfService } from './pages/Basic/TermsOfService';
import { VerifyDeleteRequestPage } from './pages/Verify/VerifyDeleteRequestPage';
import { VerifySecurityPage } from './pages/Verify/VerifySecurityPage';
import { VerifySignInPage } from './pages/Verify/VerifySignInPage';

type RoutesType = {
  key: string;
  title: string;
  path: string;
  component: () => JSX.Element;
  children: {
    key: string;
    title: string;
    path: string;
    component: () => JSX.Element | null;
    requireAuth?: boolean;
    requireAdmin?: boolean;
  }[];
};

export const routes: RoutesType[] = [
  {
    key: 'basic-layout',
    title: 'Basic',
    path: '/',
    component: AppLayout,
    children: [
      {
        key: 'landing',
        title: 'Landing',
        path: '/',
        component: LandingPage,
      },
      {
        key: 'privacy-policy',
        title: 'Privacy Policy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
      },
      {
        key: 'support',
        title: 'Support',
        path: '/support',
        component: Support,
      },
      {
        key: 'terms-of-service',
        title: 'Terms of Service',
        path: '/terms-of-service',
        component: TermsOfService,
      },
      {
        key: 'data-request',
        title: 'Data Request',
        path: '/data-request',
        component: DataRequest,
      },
      {
        key: 'verify-secure',
        title: 'Verify Security',
        path: '/verify/security',
        component: VerifySecurityPage,
      },
      {
        key: 'verify-signin',
        title: 'Verify SignIn',
        path: '/verify/signin',
        component: VerifySignInPage,
      },
      {
        key: 'verify-delete',
        title: 'Verify Delete Request',
        path: '/verify/delete-request',
        component: VerifyDeleteRequestPage,
      },
      {
        key: 'main',
        title: 'Main',
        path: '/main',
        requireAuth: true,
        component: MainCartPage,
      },
      {
        key: 'qualified',
        title: 'Qualified',
        path: '/qualified',
        requireAuth: true,
        component: QualifiedCartPage,
      },
      {
        key: 'contract',
        title: 'Contract',
        path: '/contract',
        requireAuth: true,
        component: ContractCartPage,
      },
      {
        key: 'profile',
        title: 'Profile',
        path: '/profile',
        requireAuth: true,
        component: ProfilePage,
      },
      {
        key: 'product/:productId',
        title: 'Product Detail',
        path: '/product/:productId',
        requireAuth: true,
        component: ProductDetailPage,
      },
      {
        key: 'contract/:productId',
        title: 'Contract Detail',
        path: '/contract/:productId',
        requireAuth: true,
        component: ContractDetailPage,
      },
      {
        key: 'contract/agent/:uid',
        title: 'Contract Agent Detail',
        path: '/contract/agent/:uid',
        component: ContractDetailAgentPage,
      },
      {
        key: 'contract/seller/:uid',
        title: 'Contract Seller Detail',
        path: '/contract/seller/:uid',
        component: ContractDetailSellerPage,
      },
    ],
  },
  {
    key: 'admin-layout',
    title: 'Admin',
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        key: 'admin-user',
        title: 'Manage Users',
        path: '/admin/user',
        requireAdmin: true,
        component: AdminUser,
      },
      {
        key: 'admin-agent',
        title: 'Manage Agents',
        path: '/admin/agent',
        requireAdmin: true,
        component: AdminAgent,
      },
      {
        key: 'admin-email',
        title: 'Manage Emails',
        path: '/admin/email',
        requireAdmin: true,
        component: AdminEmail,
      },
      {
        key: 'admin-product',
        title: 'Manage Properties',
        path: '/admin/product',
        requireAdmin: true,
        component: AdminProduct,
      },
      {
        key: 'admin-product-detail',
        title: 'Manage Property Detail',
        path: '/admin/product/:productId',
        requireAdmin: true,
        component: AdminProductDetail,
      },
      {
        key: 'admin-feedback',
        title: 'Manage Feedback',
        path: '/admin/feedback',
        requireAdmin: true,
        component: AdminFeedback,
      },
    ],
  },
];
