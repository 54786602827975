import { useMemo } from 'react';

import {
  useGetCurrentUserQuery,
  useGetUserInterestRateQuery,
} from '@/apis/extended/userApi';
import { calculatePurchasingPower } from '@/utils/calculator';
import { UserLoanInfo } from '@/types/UserType';

export const usePurchasingPowerEstimation = (_loanInfo?: UserLoanInfo) => {
  const { data: interestRate } = useGetUserInterestRateQuery();
  const { data: currentUser } = useGetCurrentUserQuery();

  const purchasingPower = useMemo(() => {
    const loanInfo = _loanInfo || currentUser?.loanInfo;
    if (!loanInfo || !interestRate) return 0;
    const { monthlyDebt, monthlyIncome, creditScore, downPayment, loanTerm } =
      loanInfo;
    if (!monthlyIncome) return 0;

    return calculatePurchasingPower({
      monthlyIncome,
      creditScore,
      downPayment,
      monthlyDebt,
      loanTerm,
      interestRate,
    });
  }, [_loanInfo, currentUser, interestRate]);

  return purchasingPower;
};
