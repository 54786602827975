import commaNumber from 'comma-number';
import _ from 'lodash';
import moment from 'moment';

export const formatNumber = (price: number, position = 2) => {
  if (price === undefined || price === null) return '-';
  const priceNum = typeof price === 'number' ? price : parseFloat(price);
  return commaNumber(priceNum.toFixed(position));
};

export const formatPrice = (price?: number, position = 2) => {
  if (price === undefined || price === null) return '-';
  return '$ ' + formatNumber(price, position);
};

export const parseString = (value: any, type?: string) => {
  if (value instanceof Date || type === 'date')
    return moment(value).format('YYYY-MM-DD');
  if (typeof value === 'number') return value.toString();
  if (typeof value === 'string') return value;
  if (!value) return '';
  return String(value);
};

export const extractFileNameFromUrl = (url?: string) => {
  if (!url) return '';
  const fileName = url.split('/').pop();
  return fileName;
};

export const extractDomain = (url: string) => {
  try {
    return new URL(url).host || '';
  } catch (ex) {
    return '';
  }
};

export const extractSearchParams = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const paramsObj: { [key: string]: string } = {};

  for (const [key, value] of searchParams) {
    paramsObj[key] = value;
  }

  return paramsObj;
};

export const extractSpecialTags = (content: string) => {
  const commonTags = ['siteUrl', 'extension'];
  const tags = content.match(/\{\{(\w+)\}\}/g) || [];
  return (
    _.uniq(tags.map((tag: string) => tag.slice(2, -2)))
      .filter((tag) => !commonTags.includes(tag))
      .join(', ') || '-'
  );
};
