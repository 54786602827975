import { useMemo } from 'react';
import { Chip, ChipProps } from '@mui/material';

import { USER_APPROVE_STATUS } from '@/config/constants';

type UserApproveStatusBadgeProps = ChipProps & {
  status?: USER_APPROVE_STATUS;
};

export const UserApproveStatusBadge = ({
  status,
  ...props
}: UserApproveStatusBadgeProps) => {
  const label = useMemo(() => {
    if (status === USER_APPROVE_STATUS.APPROVED) return 'Approved';
    if (status === USER_APPROVE_STATUS.PENDING) return 'Pending';
    if (status === USER_APPROVE_STATUS.REJECTED) return 'Rejected';
    return 'Not Submitted';
  }, [status]);

  const color = useMemo(() => {
    if (status === USER_APPROVE_STATUS.APPROVED) return 'success';
    if (status === USER_APPROVE_STATUS.PENDING) return 'warning';
    if (status === USER_APPROVE_STATUS.REJECTED) return 'error';
    return 'primary';
  }, [status]);

  const iconClass = useMemo(() => {
    let iconClass = 'fa-light ';
    if (status === USER_APPROVE_STATUS.APPROVED) iconClass += 'fa-badge-check';
    else if (status === USER_APPROVE_STATUS.PENDING)
      iconClass += 'fa-hourglass-start';
    else if (status === USER_APPROVE_STATUS.REJECTED)
      iconClass += 'fa-xmark-to-slot';
    else iconClass += 'fa-circle-dot';
    return iconClass;
  }, [status]);

  return (
    <Chip
      color={color}
      label={label}
      icon={<i className={iconClass} />}
      {...props}
    />
  );
};
