import { useState } from 'react';
import { Box, TextField } from '@mui/material';

import { useCreateAgentByAdminMutation } from '@/apis/extended/admin/agentApi';
import { CommonDialog } from '@/components/Common/CommonDialog';
import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { CommonNumberInput } from '@/components/Common/CommonNumberInput';

const initialForm = {
  name: '',
  domain: '',
  email: '',
  interestRate: '7.5',
};

type AddAgentModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddAgentModal = ({ isOpen, onClose }: AddAgentModalProps) => {
  const [createAgent, { isLoading }] = useCreateAgentByAdminMutation();

  const [form, setForm] = useState({ ...initialForm });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleAddAgent = () => {
    if (!form.name || !form.domain || !form.email) return;

    createAgent({
      name: form.name,
      domain: form.domain,
      email: form.email,
      interestRate: parseFloat(form.interestRate),
    })
      .unwrap()
      .then(() => {
        setForm({ ...initialForm });
      });
    onClose();
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      title="Add Agent"
      icon="fa-light fa-hexagon-plus"
      confirmButtonText="Add Agent"
      closeButtonText="Close"
      isLoading={isLoading}
      onConfirm={handleAddAgent}
      onClose={onClose}
    >
      <Box>
        <CommonFormControl
          label="Name:"
          sx={{ mt: 1 }}
          error={form.name ? '' : 'This field is required!'}
        >
          <TextField
            placeholder="Name"
            name="name"
            value={form.name}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl
          label="Domain:"
          sx={{ mt: 1 }}
          error={form.domain ? '' : 'This field is required!'}
        >
          <TextField
            placeholder="Domain"
            name="domain"
            value={form.domain}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl
          label="Email:"
          sx={{ mt: 1 }}
          error={form.email ? '' : 'This field is required!'}
        >
          <TextField
            placeholder="Email"
            name="email"
            value={form.email}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl label="Interest Rate:" sx={{ mt: 1 }}>
          <CommonNumberInput
            placeholder="Interest Rate"
            name="interestRate"
            value={form.interestRate}
            onChange={handleInputChange}
          />
        </CommonFormControl>
      </Box>
    </CommonDialog>
  );
};
