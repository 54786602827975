import { useEffect, useRef, useState } from 'react';
import { Box, TextField } from '@mui/material';
import EmailEditor, { EditorRef } from 'react-email-editor';

import {
  useCreateEmailByAdminMutation,
  useUpdateEmailByAdminMutation,
} from '@/apis/extended/admin/emailApi';
import { CommonDialog } from '@/components/Common/CommonDialog';
import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { Email } from '@/types/EmailType';
import { DefaultEmailTemplate } from '../Admin/ManageEmail/DefaultEmailTemplate';

const initialForm = {
  key: '',
  title: '',
};

type AddEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  email?: Partial<Email>;
};

export const AddEmailModal = ({
  isOpen,
  onClose,
  email,
}: AddEmailModalProps) => {
  const [createEmail, { isLoading: isCreatingEmail }] =
    useCreateEmailByAdminMutation();
  const [updateEmail, { isLoading: isUpdatingEmail }] =
    useUpdateEmailByAdminMutation();
  const isLoading = isCreatingEmail || isUpdatingEmail;

  const [form, setForm] = useState({ ...initialForm });
  const editorRef = useRef<EditorRef>(null);

  useEffect(() => {
    if (!email) return;
    setForm({ key: email.key || '', title: email.title || '' });
  }, [email]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleOnReady = () => {
    let design = DefaultEmailTemplate;
    try {
      if (email?.design) design = JSON.parse(email.design);
    } catch (ex) {}
    if (!editorRef?.current?.editor) return;

    editorRef.current.editor.loadDesign(design);
    // let mergeTags = tags;
    // editorRef.current.editor.setMergeTags(mergeTags);
  };

  const handleAddEmail = () => {
    if (!form.key || !form.title) return;
    if (!editorRef?.current?.editor) return;

    editorRef.current.editor.exportHtml((data) => {
      if (email?._id) {
        updateEmail({
          ...form,
          id: email._id,
          design: JSON.stringify(data.design),
          content: data.html,
        })
          .unwrap()
          .then(() => setForm({ ...initialForm }));
      } else {
        createEmail({
          ...form,
          design: JSON.stringify(data.design),
          content: data.html,
        })
          .unwrap()
          .then(() => setForm({ ...initialForm }));
      }
      onClose();
    });
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      title={email?._id ? 'Edit Email' : 'Add Email'}
      icon="fa-light fa-hexagon-plus"
      confirmButtonText={email?._id ? 'Edit Email' : 'Add Email'}
      closeButtonText="Close"
      isLoading={isLoading}
      onConfirm={handleAddEmail}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '100%',
        },
      }}
    >
      <Box>
        <CommonFormControl
          label="Key:"
          sx={{ mt: 1 }}
          error={form.key ? '' : 'This field is required!'}
        >
          <TextField
            placeholder="Key"
            name="key"
            fullWidth
            value={form.key}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl
          label="Title:"
          sx={{ mt: 1 }}
          error={form.title ? '' : 'This field is required!'}
        >
          <TextField
            placeholder="Title"
            name="title"
            fullWidth
            value={form.title}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <Box sx={{ mt: 3, borderRadius: '12px', overflow: 'hidden' }}>
          <EmailEditor
            style={{ height: '550px' }}
            ref={editorRef}
            onReady={handleOnReady}
          />
        </Box>
      </Box>
    </CommonDialog>
  );
};
