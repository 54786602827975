import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ManageProductDetail } from '@/components/Admin/ManageProductDetail/ManageProductDetail';

export const AdminProductDetail = () => {
  const { productId } = useParams();

  if (!productId) return null;

  return (
    <Box>
      <Typography variant="h1">Manage Property Detail</Typography>
      <Box mt={3}>
        <ManageProductDetail productId={productId} />
      </Box>
    </Box>
  );
};
