import { Box, Typography, useTheme } from '@mui/material';

export const ContractDetailSellerSuccess = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        py: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <i
        className="fa-light fa-badge-check"
        style={{ color: theme.palette.success.main, fontSize: '96px' }}
      />
      <Typography variant="h2" sx={{ mt: 4 }}>
        Contract Submitted!
      </Typography>
      <Typography variant="body1" sx={{ mt: 4, lineHeight: '24px' }}>
        Thank you for submitting the contract.
        <br />
        Please wait while we process your submission.
        <br />
        We will notify you with any updates or further steps required.
      </Typography>
    </Box>
  );
};
