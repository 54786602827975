import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';

import { HEADER_HEIGHT, HEADER_HEIGHT_ADDITIONAL } from '@/config/constants';
import { useAuth } from './useAuth';

export const useAppLayout = () => {
  const { isAuthenticated } = useAuth();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const headerHeight = useMemo(() => {
    if (!isMobile || !isAuthenticated) return HEADER_HEIGHT;
    return HEADER_HEIGHT + HEADER_HEIGHT_ADDITIONAL;
  }, [isMobile, isAuthenticated]);

  return {
    headerHeight,
  };
};
