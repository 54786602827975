import { Box, Button, Typography, useTheme } from '@mui/material';

import { LogoSvg } from '@/assets/icons/LogoSvg';

const guides = [
  'Add homes to this page with the extension.',
  'Complete a simple budget to qualify your ability to purchase a home.',
  `Add as many homes as you'd like to compare precise numbers and data.`,
];

export const ProductCardNoItemsWithGuide = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',
        color: theme.palette.mainColors.white,
      }}
    >
      <LogoSvg size={40} />
      <Typography variant="h3">No properties added to this cart.</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: '400px',
        }}
      >
        {guides.map((guide, idx) => (
          <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <i className="fa-light fa-circle-small" />
            <Typography variant="body1">{guide}</Typography>
          </Box>
        ))}
      </Box>
      <Button
        size="large"
        startIcon={<i className="fa-brands fa-chrome" />}
        href={process.env.VITE_EXTENSION_URL || ''}
        target="_blank"
      >
        Chrome Extension
      </Button>
    </Box>
  );
};
