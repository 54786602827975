import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import {
  USER_APPROVE_STATUS,
  USER_LOAN_ESTIMATION_DICT,
  USER_LOAN_ESTIMATION_TYPE,
  USER_LOAN_PROGRAM,
  USER_OCCUPANCY,
  userLoanEstimationTypes,
  userLoanPrograms,
  userOccupancys,
} from '@/config/constants';
import {
  useGetCurrentUserQuery,
  useUpdateUserLoanInfoMutation,
  useUpdateUserMutation,
} from '@/apis/extended/userApi';
import { usePurchasingPowerEstimation } from '@/hooks/usePurchasingPowerEstimation';
import { formatPrice } from '@/utils/helper';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonNumberInput } from '../Common/CommonNumberInput';
import { ManageDocumentsModal } from '../ConfirmModals/ManageDocumentsModal';
import { UserApproveStatusBadge } from './components/UserApproveStatusBadge';

export const ProfileAdvancedSettings = () => {
  const { data, isFetching } = useGetCurrentUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const [updateUserLoanInfo] = useUpdateUserLoanInfoMutation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isManageDocumentsModalOpen, setIsManageDocumentsModalOpen] =
    useState(false);
  const [form, setForm] = useState({
    monthlyIncome: '',
    creditScore: '',
    downPayment: '',
    monthlyDebt: '',
    loanTerm: '',
    loanEstimationType: USER_LOAN_ESTIMATION_TYPE.COMMERCIAL,
    loanProgram: USER_LOAN_PROGRAM.DEFAULT,
    occupancy: USER_OCCUPANCY.DEFAULT,
  });

  const purchasingPower = usePurchasingPowerEstimation({
    monthlyIncome: Number(form.monthlyIncome),
    downPayment: Number(form.downPayment),
    creditScore: Number(form.creditScore),
    monthlyDebt: Number(form.monthlyDebt),
    loanTerm: Number(form.loanTerm),
  });

  const filteredUserLoanPrograms = useMemo(() => {
    const loanPrograms =
      USER_LOAN_ESTIMATION_DICT[form.loanEstimationType]?.LOAN_PROGRAMS || [];
    return userLoanPrograms.filter((p) => loanPrograms.includes(p.value));
  }, [form.loanEstimationType]);
  const filteredUserOccupancys = useMemo(() => {
    const occupancys =
      USER_LOAN_ESTIMATION_DICT[form.loanEstimationType]?.OCCUPANCY || [];
    return userOccupancys.filter((o) => occupancys.includes(o.value));
  }, [form.loanEstimationType]);

  useEffect(() => {
    setForm({
      monthlyIncome: (data?.loanInfo?.monthlyIncome || '0') + '',
      creditScore: (data?.loanInfo?.creditScore || '0') + '',
      downPayment: (data?.loanInfo?.downPayment || '0') + '',
      monthlyDebt: (data?.loanInfo?.monthlyDebt || '0') + '',
      loanTerm: (data?.loanInfo?.loanTerm || '') + '',
      loanEstimationType:
        data?.loanEstimationType || USER_LOAN_ESTIMATION_TYPE.COMMERCIAL,
      loanProgram: data?.loanProgram || USER_LOAN_PROGRAM.DEFAULT,
      occupancy: data?.occupancy || USER_OCCUPANCY.DEFAULT,
    });
  }, [data]);

  const isUpdated = useMemo(() => {
    return (
      form.monthlyIncome !== data?.loanInfo?.monthlyIncome?.toString() ||
      form.creditScore !== data?.loanInfo?.creditScore?.toString() ||
      form.downPayment !== data?.loanInfo?.downPayment?.toString() ||
      form.monthlyDebt !== data?.loanInfo?.monthlyDebt?.toString() ||
      form.loanTerm !== data?.loanInfo?.loanTerm?.toString() ||
      form.loanEstimationType !== data?.loanEstimationType ||
      form.loanProgram !== data?.loanProgram ||
      form.occupancy !== data?.occupancy
    );
  }, [form, data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    setIsUpdating(true);

    updateUser({
      occupancy: form.occupancy || undefined,
      loanProgram: form.loanProgram || undefined,
      loanEstimationType: form.loanEstimationType || undefined,
    });

    await updateUserLoanInfo({
      monthlyIncome: form.monthlyIncome
        ? parseFloat(form.monthlyIncome)
        : undefined,
      creditScore: form.creditScore ? parseInt(form.creditScore) : undefined,
      downPayment: form.creditScore ? parseFloat(form.downPayment) : undefined,
      monthlyDebt: form.creditScore ? parseFloat(form.monthlyDebt) : undefined,
      loanTerm: parseInt(form.loanTerm),
    }).unwrap();

    setIsUpdating(false);
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={
          <>
            Advanced Settings{' '}
            <UserApproveStatusBadge
              sx={{ ml: 2 }}
              status={data?.approveStatus}
            />
          </>
        }
        avatar={<i className="fa-light fa-screwdriver-wrench" />}
      />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CommonFormControl label="Monthly Income:" labelWidth={170}>
              <CommonNumberInput
                placeholder="Monthly Income"
                name="monthlyIncome"
                value={form.monthlyIncome}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              label="Credit Score:"
              sx={{ mt: 1 }}
              labelWidth={170}
            >
              <CommonNumberInput
                placeholder="Credit Score"
                name="creditScore"
                value={form.creditScore}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              label="Down Payment:"
              sx={{ mt: 1 }}
              labelWidth={170}
            >
              <CommonNumberInput
                placeholder="Down Payment"
                name="downPayment"
                value={form.downPayment}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              label="Monthly Debt:"
              sx={{ mt: 1 }}
              labelWidth={170}
            >
              <CommonNumberInput
                placeholder="Monthly Debt"
                name="monthlyDebt"
                value={form.monthlyDebt}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              label="Loan Term:"
              sx={{ mt: 1 }}
              labelWidth={170}
            >
              <Select
                placeholder="Loan Term"
                name="loanTerm"
                value={form.loanTerm}
                onChange={handleSelectChange}
              >
                <MenuItem value="15">15 Years</MenuItem>
                <MenuItem value="30">30 Years</MenuItem>
              </Select>
            </CommonFormControl>
            <CommonFormControl
              label="Purchasing Power:"
              labelWidth={170}
              secondLabel={purchasingPower ? formatPrice(purchasingPower) : '-'}
              sx={{ mt: 1 }}
            />
            {data?.approveStatus === USER_APPROVE_STATUS.APPROVED &&
              data?.approvalAmount && (
                <CommonFormControl
                  label="Approval Amount:"
                  labelWidth={170}
                  secondLabel={formatPrice(data.approvalAmount)}
                  sx={{ mt: 1 }}
                />
              )}
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonFormControl label="Loan Estimation Type:" labelWidth={170}>
              <Select
                placeholder="Loan Estimation Type"
                name="loanEstimationType"
                value={form.loanEstimationType}
                onChange={handleSelectChange}
              >
                {userLoanEstimationTypes.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </CommonFormControl>
            <CommonFormControl
              label="Loan Program:"
              sx={{ mt: 1 }}
              labelWidth={170}
            >
              <Select
                placeholder="Loan Program"
                name="loanProgram"
                value={form.loanProgram}
                onChange={handleSelectChange}
              >
                {filteredUserLoanPrograms.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </CommonFormControl>
            <CommonFormControl
              label="Occupancy:"
              sx={{ mt: 1 }}
              labelWidth={170}
            >
              <Select
                placeholder="Occupancy"
                name="occupancy"
                value={form.occupancy}
                onChange={handleSelectChange}
              >
                {filteredUserOccupancys.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </CommonFormControl>
            <CommonFormControl
              label="Document:"
              labelWidth={170}
              sx={{ mt: 1 }}
            >
              <Button onClick={() => setIsManageDocumentsModalOpen(true)}>
                Manage Documents
              </Button>
            </CommonFormControl>
          </Grid>
        </Grid>
        <CommonFormControl sx={{ mt: 1 }} labelWidth={170}>
          <LoadingButton
            loading={isFetching || isUpdating}
            disabled={!isUpdated}
            variant="contained"
            startIcon={<i className="fa-light fa-pencil" />}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </CommonFormControl>
      </CardContent>
      <ManageDocumentsModal
        isOpen={isManageDocumentsModalOpen}
        onClose={() => setIsManageDocumentsModalOpen(false)}
      />
    </Card>
  );
};
