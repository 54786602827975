import { useEffect, useMemo, useState } from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';

import { CONTRACT_AGENT_STEP, contractAgentSteps } from '@/config/constants';
import { useGetContractByAgentQuery } from '@/apis/extended/contractApi';
import { Loader } from '../Layout/Loader';
import { ContractDetailAgentInfo } from './ContractDetailAgentInfo';
import { ContractDetailAgentOfferInfo } from './ContractDetailAgentOfferInfo';
import { ContractDetailAgentReview } from './ContractDetailAgentReview';
import { ContractDetailAgentSellerInfo } from './ContractDetailAgentSellerInfo';
import { ContractDetailAgentSummary } from './ContractDetailAgentSummary';
import { ContractDetailAgentSuccess } from './ContractDetailBuyerSuccess';

type ContractDetailAgentProps = {
  uid: string;
};

export const ContractDetailAgent = ({ uid }: ContractDetailAgentProps) => {
  const { data: contract, isLoading } = useGetContractByAgentQuery(uid);

  const [step, setStep] = useState(-1);

  const currentStep = useMemo(() => {
    if (!contract) return 0;

    if (contract.agentSign?.isStored) return CONTRACT_AGENT_STEP.COMPLETED;
    if (contract.agentSellerInfo?.isStored) return CONTRACT_AGENT_STEP.REVIEW;
    if (contract.agentOfferInfo?.isStored)
      return CONTRACT_AGENT_STEP.SELLER_INFO;
    else if (contract.agentInfo?.isStored)
      return CONTRACT_AGENT_STEP.OFFER_INFO;
    return CONTRACT_AGENT_STEP.SUMMARY;
  }, [contract]);

  useEffect(() => {
    if (!contract || step !== -1) return;

    setStep(currentStep);
  }, [contract, currentStep]);

  if (isLoading) return <Loader isFull />;
  if (!contract) return null;

  const handleClickStep = (step: number) => {
    if (step > currentStep) return;
    setStep(step);
  };

  return (
    <Box>
      <Stepper activeStep={step} alternativeLabel>
        {contractAgentSteps.map((label, idx) => (
          <Step
            key={label}
            completed={idx < currentStep}
            sx={{ cursor: idx <= currentStep ? 'pointer' : 'unset' }}
            disabled={idx > currentStep}
            onClick={() => handleClickStep(idx)}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 8, mx: 'auto', maxWidth: '600px' }}>
        {step === CONTRACT_AGENT_STEP.SUMMARY && (
          <ContractDetailAgentSummary
            uid={uid}
            onNext={() => setStep(CONTRACT_AGENT_STEP.AGENT_INFO)}
          />
        )}
        {step === CONTRACT_AGENT_STEP.AGENT_INFO && (
          <ContractDetailAgentInfo
            uid={uid}
            onPrev={() => setStep(CONTRACT_AGENT_STEP.SUMMARY)}
            onNext={() => setStep(CONTRACT_AGENT_STEP.OFFER_INFO)}
          />
        )}
        {step === CONTRACT_AGENT_STEP.OFFER_INFO && (
          <ContractDetailAgentOfferInfo
            uid={uid}
            onPrev={() => setStep(CONTRACT_AGENT_STEP.AGENT_INFO)}
            onNext={() => setStep(CONTRACT_AGENT_STEP.SELLER_INFO)}
          />
        )}
        {step === CONTRACT_AGENT_STEP.SELLER_INFO && (
          <ContractDetailAgentSellerInfo
            uid={uid}
            onPrev={() => setStep(CONTRACT_AGENT_STEP.OFFER_INFO)}
            onNext={() => setStep(CONTRACT_AGENT_STEP.REVIEW)}
          />
        )}
        {step >= CONTRACT_AGENT_STEP.REVIEW &&
          (currentStep === CONTRACT_AGENT_STEP.COMPLETED ? (
            <ContractDetailAgentSuccess />
          ) : (
            <ContractDetailAgentReview
              uid={uid}
              onPrev={() => setStep(CONTRACT_AGENT_STEP.SELLER_INFO)}
              onNext={() => {}}
            />
          ))}
      </Box>
    </Box>
  );
};
