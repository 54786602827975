import { useState } from 'react';
import { Button, SxProps } from '@mui/material';

import { USER_QUALIFY_STATUS } from '@/config/constants';
import {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
} from '@/apis/extended/userApi';
import { IncomeInfoModal } from '../ConfirmModals/IncomeInfoModal';

type SeeQualifyButtonProps = {
  sx?: SxProps;
};

export const SeeQualifyButton = ({ sx }: SeeQualifyButtonProps) => {
  const { data: user } = useGetCurrentUserQuery();
  const [updateUser] = useUpdateUserMutation();

  const [isIncomeInfoModalOpen, setIsIncomeInfoModalOpen] = useState(false);

  if (!user) return;

  const handleSubmit = () => {
    if (!user?.loanInfo?.monthlyIncome) {
      setIsIncomeInfoModalOpen(true);
      return;
    }

    updateUser({ qualifyStatus: USER_QUALIFY_STATUS.QUALIFY });
  };

  return (
    <>
      <Button
        size="small"
        color="success"
        sx={sx}
        startIcon={<i className="fa-light fa-eye" />}
        onClick={handleSubmit}
      >
        See if I qualify
      </Button>
      <IncomeInfoModal
        isOpen={isIncomeInfoModalOpen}
        onClose={() => setIsIncomeInfoModalOpen(false)}
      />
    </>
  );
};
