import { Typography } from '@mui/material';

import { CommonDialog } from './CommonDialog';

type CommonConfirmDialogProps = {
  isOpen: boolean;
  icon?: string;
  title?: string;
  content: string;
  confirmButtonText?: string;
  closeButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const CommonConfirmDialog = ({
  isOpen,
  icon,
  title,
  content,
  confirmButtonText = 'Confirm',
  closeButtonText = 'Cancel',
  onConfirm,
  onClose,
}: CommonConfirmDialogProps) => {
  return (
    <CommonDialog
      isOpen={isOpen}
      title={title}
      icon={icon}
      confirmButtonText={confirmButtonText}
      confirmButtonColor="error"
      closeButtonText={closeButtonText}
      onClose={onClose}
      onConfirm={onConfirm}
      sx={{ '& .MuiDialog-paper': { maxWidth: '400px' } }}
    >
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </CommonDialog>
  );
};
