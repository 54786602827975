import { Box, Button, Typography } from '@mui/material';

import { LandingHeroSection } from '@/components/Landing/LandingHeroSection';
import { LandingTabsSection } from '@/components/Landing/LandingTabsSection';
import { LandingVideoSection } from '@/components/Landing/LandingVideoSection';

export const LandingPage = () => {
  return (
    <Box>
      <LandingHeroSection />
      <LandingTabsSection />
      <LandingVideoSection />
    </Box>
  );
};
