import { setToken } from '@/utils/auth';
import { SignInRequest, SignInResponse } from '@/types/AuthType';
import { rtkApi } from '../rtkApi';

export const authApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInRequest>({
      query: (request) => ({
        url: '/auth/signin',
        method: 'POST',
        data: request,
      }),
      transformResponse: (response: SignInResponse) => {
        if (response.token) {
          setToken(response.token);
          window.location.href = '/main';
        }
        return response;
      },
    }),
    verifySignIn: builder.mutation<SignInResponse, string>({
      query: (uid: string) => ({
        url: '/auth/signin/verify',
        method: 'post',
        data: { uid },
      }),
      transformResponse: (response: SignInResponse) => {
        if (response.token) {
          setToken(response.token);
        }
        return response;
      },
      invalidatesTags: ['User'],
    }),
    updateSecurity: builder.mutation<void, boolean>({
      query: (status: boolean) => ({
        url: '/auth/security',
        method: 'put',
        data: { status },
      }),
      invalidatesTags: ['User'],
    }),
    verifySecurity: builder.mutation<void, string>({
      query: (uid: string) => ({
        url: '/auth/security/verify',
        method: 'post',
        data: { uid },
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useSignInMutation,
  useVerifySecurityMutation,
  useUpdateSecurityMutation,
  useVerifySignInMutation,
} = authApi;
