import { Box, Container, Grid } from '@mui/material';

import { useGetProductQuery } from '@/apis/extended/productApi';
import { Loader } from '../Layout/Loader';
import { ProductDetailBasic } from './ProductDetailBasic';
import { ProductDetailEstimation } from './ProductDetailEstimation';
import { ProductDetailExploreOfferButton } from './ProductDetailExploreOfferButton';
import { ProductDetailLogos } from './ProductDetailLogos';
import { ProductDetailProgress } from './ProductDetailProgress';
import { ProductDetailProjectedPayment } from './ProductDetailProjectedPayment';
import { ProductDetailPropertySpecifics } from './ProductDetailPropertySpecifics';

type ProductDetailProps = {
  productId: string;
};

export const ProductDetail = ({ productId }: ProductDetailProps) => {
  const { data: product, isLoading } = useGetProductQuery(productId);

  if (isLoading || !product) return <Loader isFull />;

  return (
    <Container sx={{ py: '40px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ProductDetailLogos product={product} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductDetailBasic product={product} />
          <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
            <ProductDetailExploreOfferButton product={product} />
          </Box>
          <ProductDetailProgress product={product} />
        </Grid>
      </Grid>
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} md={6}>
          <ProductDetailPropertySpecifics product={product} />
          <Box
            sx={{
              position: { md: 'sticky' },
              top: '90px',
              mt: 2,
            }}
          >
            <ProductDetailProjectedPayment product={product} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductDetailEstimation product={product} />
        </Grid>
      </Grid>
    </Container>
  );
};
