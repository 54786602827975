import { Box, Container, styled, Typography, useTheme } from '@mui/material';

export const BodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyShades.grey1,
  lineHeight: '140%',
  strong: {
    // color: theme.palette.mainColors.white,
  },
  a: {
    color: theme.palette.mainColors.blue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  table: {
    width: '100%',
    '&, & td': {
      border: '1px solid',
      bordrColor: theme.palette.greyShades.grey1,
      borderCollapse: 'collapse',
      padding: '4px 8px',
    },
    td: {
      '&:nth-child(3)': {
        textAlign: 'center',
      },
    },
  },
}));

export const PrivacyPolicy = () => {
  const theme = useTheme();
  return (
    <Container sx={{ py: '40px', color: theme.palette.greyShades.grey1 }}>
      <Box>
        <Typography variant="h1">Privacy Policy</Typography>
        <BodyText variant="body1" sx={{ mt: 1 }}>
          Last updated June 13, 2024
        </BodyText>
        <BodyText sx={{ mt: 3 }}>
          This privacy notice for Unbeatable Loans LLC (doing business as
          Unbeatable Loans) (<strong>we</strong>," "<strong>us</strong>," or "
          <strong>our</strong>"), describes how and why we might collect, store,
          use, and/or share ("<strong>process</strong>") your information when
          you use our services ("<strong>Services</strong>"), such as when you:
          <ul>
            <li>
              Visit our website at{' '}
              <a href="https://www.unbeatableloans.com">
                https://www.unbeatableloans.com
              </a>
              , or any website of ours that links to this privacy notice
            </li>
            <li>
              Download and use our mobile application (Unbeatable Loans Chrome
              Extension & Mobile App), or any other application of ours that
              links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <strong>Questions or concerns?</strong> Reading this privacy notice
          will help you understand your privacy rights and choices. If you do
          not agree with our policies and practices, please do not use our
          Services. If you still have any questions or concerns, please contact
          us at support@unbeatableloans.com.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3">SUMMARY OF KEY POINTS</Typography>
        <BodyText sx={{ mt: 3 }}>
          <strong>
            <em>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our{' '}
              <a href="#toc">table of contents</a> below to find the section you
              are looking for.
            </em>
          </strong>
          <br />
          <br />
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about{' '}
          <a href="#personalinfo">personal information you disclose to us</a>.
          <br />
          <br />
          <strong>Do we process any sensitive personal information?</strong> We
          may process sensitive personal information when necessary with your
          consent or as otherwise permitted by applicable law. Learn more about{' '}
          <a href="#sensitiveinfo">sensitive information we process</a>.
          <br />
          <br />
          <strong>Do we collect any information from third parties?</strong> We
          may collect information from public databases, marketing partners,
          social media platforms, and other outside sources. Learn more about
          <a href="#othersources">information collected from other sources</a>.
          <br />
          <br />
          <strong>How do we process your information?</strong>We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about{' '}
          <a href="#infouse">how we process your information</a>.
          <br />
          <br />
          <strong>
            In what situations and with which parties do we share personal
            information?
          </strong>
          We may share information in specific situations and with specific
          third parties. Learn more about{' '}
          <a href="#whoshare">
            when and with whom we share your personal information
          </a>
          .
          <br />
          <br />
          <strong>How do we keep your information safe?</strong> We have
          organizational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission
          over the internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Learn more about{' '}
          <a href="#infosafe">how we keep your information safe</a>.
          <br />
          <br />
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more about
          <a href="#privacyrights">your privacy rights</a>.
          <br />
          <br />
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by visiting{' '}
          <a href="https://www.unbeatableloans.com/data-request">
            https://www.unbeatableloans.com/data-request
          </a>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
          <br />
          <br />
          Want to learn more about what we do with any information we collect?{' '}
          <a href="#toc">Review the privacy notice in full</a>.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }} id="toc">
        <Typography variant="h3">TABLE OF CONTENTS</Typography>
        <BodyText sx={{ mt: 3 }}>
          <a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
          <br />
          <a href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
          <br />
          <a href="#whoshare">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
          <br />
          <a href="#3pwebsites">
            4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
          </a>
          <br />
          <a href="#cookies">
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
          <br />
          <a href="#inforetain">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          <br />
          <a href="#infosafe">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
          <br />
          <a href="#infominors">8. DO WE COLLECT INFORMATION FROM MINORS?</a>
          <br />
          <a href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
          <br />
          <a href="#DNT">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          <br />
          <a href="#uslaws">
            11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
          <br />
          <a href="#clausea">12. NMLS LICENSE</a>
          <br />
          <a href="#policyupdates">13. DO WE MAKE UPDATES TO THIS NOTICE?</a>
          <br />
          <a href="#contact">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          <br />
          <a href="#request">
            15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
          <br />
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }} id="infocollect">
        <Typography variant="h3">1. WHAT INFORMATION DO WE COLLECT?</Typography>
        <BodyText sx={{ mt: 3 }}>
          <Typography variant="h4" id="personalinfo">
            Personal information you disclose to us
          </Typography>
          <br />
          <em>
            <strong>In Short:</strong> We collect personal information that you
            provide to us.
          </em>
          <br />
          <br />
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
          <br />
          <br />
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
          <ul>
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>contact preferences</li>
            <li>contact or authentication data</li>
            <li>monthly income</li>
            <li>credit score</li>
            <li>housing budget</li>
            <li>other lending related information</li>
          </ul>
          <br />
          <strong id="sensitiveinfo">Sensitive Information.</strong> When
          necessary, with your consent or as otherwise permitted by applicable
          law, we process the following categories of sensitive information:
          <ul>
            <li>credit worthiness data</li>
            <li>mortgage related data</li>
            <li>real estate related data</li>
          </ul>
          <br />
          <strong>Application Data.</strong> If you use our application(s), we
          also may collect the following information if you choose to provide us
          with access or permission:
          <ul>
            <li>
              <em>Push Notifications.</em> We may request to send you push
              notifications regarding your account or certain features of the
              application(s). If you wish to opt out from receiving these types
              of communications, you may turn them off in your device's
              settings.
            </li>
          </ul>
          <br />
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
          <br />
          <br />
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
          <Typography variant="h4" sx={{ mt: 5 }} id="othersources">
            Information collected from other sources
          </Typography>
          <br />
          <em>
            <strong>In Short:</strong> We may collect limited data from public
            databases, marketing partners, and other outside sources.
          </em>
          <br />
          <br />
          In order to enhance our ability to provide relevant marketing, offers,
          and services to you and update our records, we may obtain information
          about you from other sources, such as public databases, joint
          marketing partners, affiliate programs, data providers, and from other
          third parties. This information includes mailing addresses, job
          titles, email addresses, phone numbers, intent data (or user behavior
          data), Internet Protocol (IP) addresses, social media profiles, social
          media URLs, and custom profiles, for purposes of targeted advertising
          and event promotion.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="infouse">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> We process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consent.
          </em>
          <br />
          <br />
          <strong>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </strong>
          <ul>
            <li>
              <strong>
                To facilitate account creation and authentication and otherwise
                manage user accounts.
              </strong>{' '}
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </li>
            <li>
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>{' '}
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <strong>To send administrative information to you.</strong> We may
              process your information to send you details about our products
              and services, changes to our terms and policies, and other similar
              information.
            </li>
            <li>
              <strong>To fulfill and manage your orders.</strong> We may process
              your information to fulfill and manage your orders, payments,
              returns, and exchanges made through the Services.
            </li>
            <li>
              <strong>To request feedback.</strong> We may process your
              information when necessary to request feedback and to contact you
              about your use of our Services.
            </li>
            <li>
              <strong>To protect our Services.</strong> We may process your
              information as part of our efforts to keep our Services safe and
              secure, including fraud monitoring and prevention.
            </li>
            <li>
              <strong>
                To evaluate and improve our Services, products, marketing, and
                your experience.
              </strong>{' '}
              We may process your information when we believe it is necessary to
              identify usage trends, determine the effectiveness of our
              promotional campaigns, and to evaluate and improve our Services,
              products, marketing, and your experience.
            </li>
            <li>
              <strong>To comply with our legal obligations.</strong> We may
              process your information to comply with our legal obligations,
              respond to legal requests, and exercise, establish, or defend our
              legal rights.
            </li>
          </ul>
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="whoshare">
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> We may share information in specific
            situations described in this section and/or with the following third
            parties.
          </em>
          <br />
          <br />
          We may need to share your personal information in the following
          situations:
          <ul>
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <strong>When we use Google Maps Platform APIs.</strong> We may
              share your information with certain Google Maps Platform APIs
              (e.g., Google Maps API, Places API).
            </li>
            <li>
              <strong>Affiliates.</strong> We may share your information with
              our affiliates, in which case we will require those affiliates to
              honor this privacy notice. Affiliates include our parent company
              and any subsidiaries, joint venture partners, or other companies
              that we control or that are under common control with us.
            </li>
            <li>
              <strong>Business Partners.</strong> We may share your information
              with our business partners to offer you certain products,
              services, or promotions.
            </li>
          </ul>
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="3pwebsites">
          4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> We are not responsible for the safety of
            any information that you share with third parties that we may link
            to or who advertise on our Services, but are not affiliated with,
            our Services.
          </em>
          <br />
          <br />
          The Services may link to third-party websites, online services, or
          mobile applications and/or contain advertisements from third parties
          that are not affiliated with us and which may link to other websites,
          services, or applications. Accordingly, we do not make any guarantee
          regarding any such third parties, and we will not be liable for any
          loss or damage caused by the use of such third-party websites,
          services, or applications. The inclusion of a link towards a
          third-party website, service, or application does not imply an
          endorsement by us. We cannot guarantee the safety and privacy of data
          you provide to any third parties. Any data collected by third parties
          is not covered by this privacy notice. We are not responsible for the
          content or privacy and security practices and policies of any third
          parties, including other websites, services, or applications that may
          be linked to or from the Services. You should review the policies of
          such third parties and contact them directly to respond to your
          questions.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="cookies">
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> We may use cookies and other tracking
            technologies to collect and store your information.
          </em>
          <br /> <br />
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services and your account, prevent crashes, fix bugs, save your
          preferences, and assist with basic site functions.
          <br />
          <br />
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
          <br />
          <br />
          To the extent these online tracking technologies are deemed to be a
          "sale"/"sharing" (which includes targeted advertising, as defined
          under the applicable laws) under applicable US state laws, you can opt
          out of these online tracking technologies by submitting a request as
          described below under section "
          <a href="#uslaws">
            DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
          "
          <br /> <br />
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="inforetain">
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> We keep your information for as long as
            necessary to fulfill the purposes outlined in this privacy notice
            unless otherwise required by law.
          </em>
          <br />
          <br />
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
          <br />
          <br />
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="infosafe">
          7. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> We aim to protect your personal
            information through a system of organizational and technical
            security measures.
          </em>
          <br />
          <br />
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="infominors">
          8. DO WE COLLECT INFORMATION FROM MINORS?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> We do not knowingly collect data from or
            market to children under 18 years of age.
          </em>
          <br />
          <br />
          We do not knowingly collect, solicit data from, or market to children
          under 18 years of age, nor do we knowingly sell such personal
          information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at support@unbeatableloans.com.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="privacyrights">
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> You may review, change, or terminate your
            account at any time, depending on your country, province, or state
            of residence.
          </em>
          <br />
          <br />
          <span id="withdrawconsent" style={{ textDecoration: 'underline' }}>
            Withdrawing your consent:
          </span>{' '}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section "
          <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>"
          below.
          <br />
          <br />
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Opting out of marketing and promotional communications:
          </span>{' '}
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send, or by contacting us using the details provided in the section "
          <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE</a>?"
          below. You will then be removed from the marketing lists. However, we
          may still communicate with you — for example, to send you
          service-related messages that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes.
          <br />
          <br />
          <Typography variant="h4">Account Information</Typography>
          <br />
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
            <li>Contact us using the contact information provided.</li>
            <li>Go to account settings and select delete user.</li>
          </ul>
          <br />
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
          <br />
          <br />
          If you have questions or comments about your privacy rights, you may
          email us at support@unbeatableloans.com.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="DNT">
          10. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
          <br />
          <br />
          California law requires us to let you know how we respond to web
          browser DNT signals. Because there currently is not an industry or
          legal standard for recognizing or honoring DNT signals, we do not
          respond to them at this time.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="uslaws">
          11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> If you are a resident of California,
            Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky,
            Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah,
            or Virginia, you may have the right to request access to and receive
            details about the personal information we maintain about you and how
            we have processed it, correct inaccuracies, get a copy of, or delete
            your personal information. You may also have the right to withdraw
            your consent to our processing of your personal information. These
            rights may be limited in some circumstances by applicable law. More
            information is provided below.
          </em>
          <br />
          <br />
          <Typography variant="h4">
            Categories of Personal Information We Collect
          </Typography>
          <br />
          We have collected the following categories of personal information in
          the past twelve (12) months:
          <br />
          <br />
          <table>
            <tbody>
              <tr>
                <td style={{ width: '33%' }}>
                  <strong>Category</strong>
                </td>
                <td style={{ width: '51%' }}>
                  <strong>Examples</strong>
                </td>
                <td style={{ width: '16%' }}>
                  <strong>Collected</strong>
                </td>
              </tr>
              <tr>
                <td>A. Identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  B. Personal information as defined in the California Customer
                  Records statute
                </td>
                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under state or
                  federal law
                </td>
                <td>
                  Gender, age, date of birth, race and ethnicity, national
                  origin, marital status, and other demographic data
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>H. Audio, electronic, sensory, or similar information</td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>J. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from collected personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>L. Sensitive personal Information</td>
                <td>
                  Drivers' licenses and financial information including account
                  access details
                </td>
                <td>YES</td>
              </tr>
            </tbody>
          </table>
          <br />
          We only collect sensitive personal information, as defined by
          applicable privacy laws or the purposes allowed by law or with your
          consent. Sensitive personal information may be used, or disclosed to a
          service provider or contractor, for additional, specified purposes.
          You may have the right to limit the use or disclosure of your
          sensitive personal information. We do not collect or process sensitive
          personal information for the purpose of inferring characteristics
          about you.
          <br />
          <br />
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
          <ul>
            <li>
              Category A - According to financial reporting standards
              established by government for legal compliance.
            </li>
            <li>
              Category B - According to national financial reporting standards
              established by government agencies in order for legal compliance.
            </li>
            <li>
              Category C - According to government reporting standards
              established by government for legal compliance.
            </li>
            <li>Category F - As long as the user has an account with us</li>
            <li>Category H - As long as the user has an account with us</li>
            <li>
              Category I - According to financial reporting standards
              established by government for legal compliance.
            </li>
            <li>
              Category K - According to financial reporting standards
              established by government for legal compliance.
            </li>
            <li>
              Category L - According to financial reporting standards
              established by government for legal compliance.
            </li>
          </ul>
          <br />
          <Typography variant="h4">Sources of Personal Information</Typography>
          <br />
          Learn more about the sources of personal information we collect in "
          <a href="#infocollect">WHAT INFORMATION DO WE COLLECT?</a>"
          <br />
          <br />
          <Typography variant="h4">
            How We Use and Share Personal Information
          </Typography>
          <br />
          Learn about how we use your personal information in the section, "
          <a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a>"
          <br />
          <br />
          <strong>Will your information be shared with anyone else?</strong>
          <br />
          <br />
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Learn more about how we disclose personal information to in the
          section, "
          <a href="#whoshare">
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
          "
          <br />
          <br />
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information.
          <br />
          <br />
          We have not disclosed, sold, or shared any personal information to
          third parties for a business or commercial purpose in the preceding
          twelve (12) months. We will not sell or share personal information in
          the future belonging to website visitors, users, and other consumers.
          <br />
          <br />
          <Typography variant="h4">Your Rights</Typography>
          <br />
          You have rights under certain US state data protection laws. However,
          these rights are not absolute, and in certain cases, we may decline
          your request as permitted by law. These rights include:
          <ul>
            <li>
              <strong>Right to know</strong> whether or not we are processing
              your personal data
            </li>
            <li>
              <strong>Right to access</strong> your personal data
            </li>
            <li>
              <strong>Right to correct</strong> inaccuracies in your personal
              data
            </li>
            <li>
              <strong>Right to request</strong> the deletion of your personal
              data
            </li>
            <li>
              <strong>Right to obtain</strong> a copy of the personal data you
              previously shared with us
            </li>
            <li>
              <strong>Right to non-discrimination</strong> for exercising your
              rights
            </li>
            <li>
              <strong>Right to opt out</strong> of the processing of your
              personal data if it is used for targeted advertising (or sharing
              as defined under California’s privacy law), the sale of personal
              data, or profiling in furtherance of decisions that produce legal
              or similarly significant effects ("profiling")
            </li>
          </ul>
          Depending upon the state where you live, you may also have the
          following rights:
          <ul>
            <li>
              Right to obtain a list of the categories of third parties to which
              we have disclosed personal data (as permitted by applicable law,
              including California's and Delaware's privacy law)
            </li>
            <li>
              Right to obtain a list of specific third parties to which we have
              disclosed personal data (as permitted by applicable law, including
              Oregon’s privacy law)
            </li>
            <li>
              Right to limit use and disclosure of sensitive personal data (as
              permitted by applicable law, including California’s privacy law)
            </li>
            <li>
              Right to opt out of the collection of sensitive data and personal
              data collected through the operation of a voice or facial
              recognition feature (as permitted by applicable law, including
              Florida’s privacy law)
            </li>
          </ul>
          <Typography variant="h4">How to Exercise Your Rights</Typography>
          <br />
          To exercise these rights, you can contact us by visiting{' '}
          <a href="https://www.unbeatableloans.com/data-request">
            https://www.unbeatableloans.com/data-request
          </a>
          , by emailing us at support@unbeatableloans.com, or by referring to
          the contact details at the bottom of this document.
          <br />
          <br />
          Under certain US state data protection laws, you can designate an
          authorized agent to make a request on your behalf. We may deny a
          request from an authorized agent that does not submit proof that they
          have been validly authorized to act on your behalf in accordance with
          applicable laws.
          <br />
          <br />
          <Typography variant="h4">Request Verification</Typography>
          <br />
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. We will only use personal information provided in your
          request to verify your identity or authority to make the request.
          However, if we cannot verify your identity from the information
          already maintained by us, we may request that you provide additional
          information for the purposes of verifying your identity and for
          security or fraud-prevention purposes.
          <br />
          <br />
          If you submit the request through an authorized agent, we may need to
          collect additional information to verify your identity before
          processing your request and the agent will need to provide a written
          and signed permission from you to submit such request on your behalf.
          <br />
          <br />
          <Typography variant="h4">Appeals</Typography>
          <br />
          Under certain US state data protection laws, if we decline to take
          action regarding your request, you may appeal our decision by emailing
          us at support@unbeatableloans.com. We will inform you in writing of
          any action taken or not taken in response to the appeal, including a
          written explanation of the reasons for the decisions. If your appeal
          is denied, you may submit a complaint to your state attorney general.
          <br />
          <br />
          <Typography variant="h4">California "Shine The Light" Law</Typography>
          <br />
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us by using the contact details provided in the section "
          <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>"
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="clausea">
          12. NMLS LICENSE
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          This is a custom application designed and hosted on a private server
          for use explicitly by John Pastre NMLS #1827883
          <a href="http://www.nmlsconsumeraccess.com/">
            http://www.nmlsconsumeraccess.com
          </a>
          . Secure information is hosted on a separate application to which this
          custom application redirects in order to collect financial information
          and verify financial information for creditworthiness.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="policyupdates">
          13. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          <em>
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </em>
          <br />
          <br />
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date at the top of
          this privacy notice. If we make material changes to this privacy
          notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this privacy notice frequently to be informed of how we
          are protecting your information.
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="contact">
          14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          If you have questions or comments about this notice, you may contact
          our Data Protection Officer (DPO) by email at
          support@unbeatableloans.com, by phone at 7034058794, or contact us by
          post at:
          <br />
          <br />
          Unbeatable Loans LLC
          <br />
          Data Protection Officer
          <br />
          619 Orton Ave, Fort Lauderdale, FL 33304, USA
          <br />
          Fort Lauderdale, FL 33304
          <br />
          United States
        </BodyText>
      </Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3" id="request">
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          Based on the applicable laws of your country or state of residence in
          the US, you may have the right to request access to the personal
          information we collect from you, details about how we have processed
          it, correct inaccuracies, or delete your personal information. You may
          also have the right to withdraw your consent to our processing of your
          personal information. These rights may be limited in some
          circumstances by applicable law. To request to review, update, or
          delete your personal information, please visit:
          <a href="https://www.unbeatableloans.com/data-request">
            https://www.unbeatableloans.com/data-request
          </a>
          .
        </BodyText>
      </Box>
    </Container>
  );
};
