import { Box, Container, styled, Typography, useTheme } from '@mui/material';

import { BodyText } from './PrivacyPolicy';

export const TermsOfService = () => {
  const theme = useTheme();
  return (
    <Container sx={{ py: '40px', color: theme.palette.greyShades.grey1 }}>
      <Box>
        <Typography variant="h1">
          Terms of Service - Unbeatable Loans
        </Typography>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">1. Introduction</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            Welcome to Unbeatable Loans ("Company", "we", "our", "us"). These
            Terms of Service ("Terms") govern your use of our website and
            services. By accessing or using our services, you agree to be bound
            by these Terms.
          </p>
          <p>
            Please read these Terms carefully before using our services. If you
            do not agree to these Terms, you may not use our services.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">2. Eligibility</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            You must be at least 18 years old and reside in a region where our
            services are available to use Unbeatable Loans. By using our
            services, you represent and warrant that you meet these eligibility
            requirements.
          </p>
          <p>
            You must provide accurate, current, and complete information when
            using our services. You agree to update your information as
            necessary to maintain its accuracy.
          </p>
          <p>
            You must not use our services for any fraudulent or illegal
            activities. You agree to comply with all applicable laws and
            regulations when using our services.
          </p>
          <p>
            You must not use our services to harass, abuse, or harm others. You
            agree to treat others with respect and refrain from engaging in any
            harmful or abusive behavior.
          </p>
          <p>
            You must not use our services to violate the rights of others,
            including their intellectual property rights. You agree to respect
            the rights of others and refrain from engaging in any activities
            that infringe on those rights.
          </p>
          <p>
            You must not use our services to transmit any viruses, malware, or
            other harmful code. You agree to protect our services from any
            unauthorized access or use.
          </p>
          <p>
            You must not use our services to collect or store personal
            information about others without their consent. You agree to respect
            the privacy of others and refrain from engaging in any activities
            that violate their privacy rights.
          </p>
          <p>
            You must not use our services to impersonate others or misrepresent
            your identity. You agree to provide accurate information about
            yourself and refrain from engaging in any activities that mislead
            others about your identity.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">3. Services Provided</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            Unbeatable Loans provides various financial services, including
            providing information about mortgage loans, business loans, and
            other financial products. The terms and conditions for each product
            or service will be provided at the time of application.
          </p>
          <p>
            Unbeatable Loans is not a lender, and we do not provide loans
            directly. We are a platform that connects borrowers with lenders
            through a mortgage broker. We do not guarantee loan approval or
            specific loan terms.
          </p>
          <p>
            Unbeatable Loans may provide information about third-party products
            or services. We are not responsible for the accuracy, completeness,
            or reliability of this information.
          </p>
          <p>
            Unbeatable Loans may provide links to third-party websites or
            services. We are not responsible for the content or privacy
            practices of these third-party sites.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">4. Application Process</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            To apply for a loan, you must complete our online application form
            and provide accurate, current, and complete information. However,
            Unbeatable Loans does not process complete loan applications
            internally. You agree to update your information as necessary to
            maintain its accuracy.
          </p>
          <p>
            Unbeatable Loans may use your information to match you with lenders
            who may offer you a loan. By submitting your information, you
            authorize us to share your information with lenders for this
            purpose.
          </p>
          <p>
            Unbeatable Loans may perform a credit check to verify your identity
            and assess your creditworthiness. This check may or may not affect
            your credit score.
          </p>
          <p>
            Unbeatable Loans may require additional information or documentation
            to process your loan application. You agree to provide this
            information promptly upon request.
          </p>
          <p>
            Unbeatable Loans does not guarantee loan approval or specific loan
            terms. The terms of your loan will be determined by the lender based
            on your creditworthiness and other factors.
          </p>
          <p>
            Unbeatable Loans is not responsible for any errors or inaccuracies
            in the information provided by lenders. Please review your loan
            agreement carefully and contact your lender if you have any
            questions or concerns.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">5. Loan Approval and Disbursement</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            Loan approval is subject to external assessment and verification
            process. Unbeatable Loans reserve the right to approve, qualify, or
            deny use of our platforms or loan programs at our sole discretion.
            If approved, loan funds will be disbursed according to the terms
            agreed upon in the loan agreement.
          </p>
          <p>
            Unbeatable Loans does not guarantee loan approval or specific loan
            terms. The terms of your loan will be determined by the lender based
            on your creditworthiness and other factors.
          </p>
          <p>
            Unbeatable Loans is not responsible for any delays or issues with
            the disbursement of loan funds. Please contact your lender directly
            for information about the status of your loan.
          </p>
          <p>
            Unbeatable Loans is not responsible for any errors or inaccuracies
            in the information provided by lenders. Please review your loan
            agreement carefully and contact your lender if you have any
            questions or concerns.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">6. Fees and Charges</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            All fees and charges associated with your loan will be disclosed in
            the loan agreement. You agree to pay all fees and charges as
            specified.
          </p>
          <p>
            Unbeatable Loans may charge a fee for using our services. This fee
            will be disclosed to you at the time of application.
          </p>
          <p>
            Unbeatable Loans or its affiliates may receive compensation from
            lenders for referring you to their services. This compensation may
            affect the order in which lenders are displayed on our website.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">7. Repayment</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            Repayment terms, including the amount, frequency, and method of
            repayment, will be detailed in the loan agreement. You agree to
            repay the loan according to these terms.
          </p>
          <p>
            Unbeatable Loans does their due dilligence to confirm your ability
            to repay a loan. If circumstances change and you are unable to repay
            the loan, please contact your lender immediately to discuss your
            options.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">8. Late Payments and Default</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            Failure to make timely payments may result in late fees, additional
            charges, and potential legal action. Please refer to your loan
            agreement for specific terms regarding late payments and default.
          </p>
          <p>
            If you are unable to make a payment, please contact your lender
            immediately to discuss your options. Ignoring the issue may result
            in additional fees and charges.
          </p>
          <p>
            If you default on your loan, your lender may take legal action to
            recover the amount owed. This may include garnishing your wages,
            seizing your assets, or taking other legal action.
          </p>
          <p>
            If you are experiencing financial difficulties, please contact a
            financial advisor or credit counselor for assistance.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">9. Privacy</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            We are committed to protecting your privacy. Our Privacy Policy,
            which explains how we collect, use, and disclose your personal
            information, is incorporated by reference into these Terms. By using
            our services, you agree to our Privacy Policy.
          </p>
          <p>
            Unbeatable Loans may use your personal information to provide you
            with targeted advertisements. By using our services, you agree to
            our use of your personal information for this purpose.
          </p>
          <p>
            Unbeatable Loans may share your personal information with third
            parties for marketing purposes. By using our services, you agree to
            our sharing of your personal information with third parties for this
            purpose.
          </p>
          <p>
            Unbeatable Loans may use cookies and other tracking technologies to
            improve your experience on our website and services. By using our
            services, you agree to our use of cookies and tracking technologies.
          </p>
          <p>
            Unbeatable Loans may collect and store your personal information in
            the United States or other countries. By using our services, you
            agree to the transfer of your personal information to these
            countries.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">10. Intellectual Property</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            All content, trademarks, logos, and other intellectual property used
            on our website and services are owned by or licensed to Unbeatable
            Loans. You may not use, reproduce, or distribute any content without
            our express written permission.
          </p>
          <p>
            Unbeatable Loans grants you a limited, non-exclusive,
            non-transferable license to access and use our services for your
            personal use only.
          </p>
          <p>
            Any feedback, comments, or suggestions you provide to us may be used
            by Unbeatable Loans without any obligation to compensate you.
          </p>
          <p>
            Unbeatable Loans respects the intellectual property rights of
            others. If you believe that your intellectual property rights have
            been violated, please contact us.
          </p>
          <p>
            Unbeatable Loans may provide links to third-party websites or
            services. We are not responsible for the content or privacy
            practices of these third-party sites.
          </p>
          <p>
            Unbeatable Loans may display advertisements on our website or
            services. These advertisements are provided by third-party ad
            networks and advertisers. We are not responsible for the content or
            accuracy of these advertisements.
          </p>
          <p>
            Unbeatable Loans displays content from third party providers. We are
            not responsible for the accuracy, completeness, or reliability of
            this content.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">11. Prohibited Activities</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            You agree not to engage in any of the following prohibited
            activities:
          </p>
          <ul>
            <li>Violating any applicable laws or regulations.</li>
            <li>Providing false or misleading information.</li>
            <li>Interfering with the operation of our services.</li>
            <li>
              Using our services for any fraudulent or illegal activities.
            </li>
            <li>Harassing, abusing, or harming others.</li>
            <li>
              Violating the rights of others, including their intellectual
              property rights.
            </li>
            <li>Transmitting any viruses, malware, or other harmful code.</li>
            <li>
              Collecting or storing personal information about others without
              their consent.
            </li>
            <li>Impersonating others or misrepresenting your identity.</li>
            <li>
              Engaging in any activities to collect or harvest intellectual
              property of Unbeatable Loans, it's affiliates, or it's users.
            </li>
          </ul>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">12. Termination</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            We reserve the right to terminate or suspend your access to our
            services at any time, without notice, for any reason, including but
            not limited to a violation of these Terms.
          </p>
          <p>
            If you wish to terminate your account, please contact us or delete
            your account in the account page
          </p>
          <p>
            Upon termination, all provisions of these Terms which by their
            nature should survive termination will survive, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity,
            and limitations of liability.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">13. Release of Liability</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            By using our website or applications, you agree to release
            Unbeatable Loans from any liability, including any liability arising
            from the use Unbeatable Loans to create contracts to purchase real
            estate. All actions taken by you on this platform are your sole
            responsibility.
          </p>
          <p>
            Unbeatable Loans is not responsible for any errors or inaccuracies
            in the information provided by lenders. Please review your loan
            agreement carefully and contact your lender if you have any
            questions or concerns.
          </p>
          <p>
            Unbeatable Loans is not responsible for any defects of title or
            other issues that may arise from the purchase of real estate. You
            should consult with a real estate attorney or other professional
            before entering into any real estate transaction.
          </p>
          <p>
            Unbeatable Loans is not responsible for any disputes that may arise
            between you and other users of the platform. You agree to resolve
            any disputes with other users directly and release Unbeatable Loans
            from any liability in connection with such disputes.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">14. Dispute Resolution</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            Any disputes arising out of or relating to these Terms or our
            services will be resolved through binding arbitration in accordance
            with the rules of the American Arbitration Association. The
            arbitration will take place in Florida, in Broward County, and the
            arbitrator's decision will be final and binding.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">15. Changes to Terms</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            We may modify these Terms at any time. Any changes will be effective
            immediately upon posting on the websites. Your continued use of our
            services after any changes constitutes your acceptance of the
            revised Terms.
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">16. Contact Information</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at:
          </p>
          <p>
            <strong>Unbeatable Loans</strong>
            <br />
            <strong>Email:</strong> support@unbeatableloans.com
            <br />
            <strong>Phone:</strong> 703-405-8794
            <br />
            <strong>Address:</strong> 619 Orton Ave, Apt 202, Fort Lauderdale,
            FL 33301
          </p>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h3">17. Entire Agreement</Typography>
        <BodyText sx={{ mt: 3 }}>
          <p>
            These Terms, along with our Privacy Policy and any other agreements
            incorporated by reference, constitute the entire agreement between
            you and Unbeatable Loans regarding your use of our services.
          </p>
          <p>
            If any provision of these Terms is found to be invalid or
            unenforceable, the remaining provisions will remain in full force
            and effect.
          </p>
        </BodyText>
      </Box>
    </Container>
  );
};
