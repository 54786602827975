import { ProfileAdvancedSettings } from '@/components/Profile/ProfileAdvancedSettings';
import { ProfileDeleteAccount } from '@/components/Profile/ProfileDeleteAccount';
import { ProfilePersonalInformation } from '@/components/Profile/ProfilePersonalInformation';
import { ProfileSecurity } from '@/components/Profile/ProfileSecurity';
import { Container, Typography } from '@mui/material';

export const ProfilePage = () => {
  return (
    <Container sx={{ py: '40px' }}>
      <Typography variant="h1">Profile Settings</Typography>
      <ProfilePersonalInformation />
      <ProfileSecurity />
      <ProfileAdvancedSettings />
      <ProfileDeleteAccount />
    </Container>
  );
};
