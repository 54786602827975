import { logout } from '@/utils/auth';
import { CommonConfirmDialog } from '../Common/CommonConfirmDialog';

type SignOutModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SignOutModal = ({ isOpen, onClose }: SignOutModalProps) => {
  const handleSignOut = () => {
    logout();
    onClose();
  };
  return (
    <CommonConfirmDialog
      isOpen={isOpen}
      title="Sign Out"
      icon="fa-light fa-arrow-right-from-bracket"
      onClose={onClose}
      content="Are you sure you want to sign out?"
      confirmButtonText="Sign Out"
      onConfirm={handleSignOut}
    />
  );
};
