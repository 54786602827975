import { USER_LOAN_PROGRAM } from '@/config/constants';
import {
  CalculateClosingDisclosureProps,
  calculateTaxFactor,
  calculateTransferTaxFactor,
  chooseMarketInterestRate,
} from '.';

const upfrontMIPcalc = (downPayment: number, purchasePrice: number) => {
  const downPaymentPercentage = (downPayment / purchasePrice) * 100;
  let upfrontMIPRate;

  if (downPaymentPercentage < 5) {
    upfrontMIPRate = 1.75;
  } else if (downPaymentPercentage < 10) {
    upfrontMIPRate = 1.75;
  } else {
    upfrontMIPRate = 1.75;
  }

  const loanAmount = purchasePrice - downPayment;
  const upfrontMIP = (loanAmount * upfrontMIPRate) / 100;
  return upfrontMIP;
};

const calculateMIP = (baseLoanAmount: number, ltv: number) => {
  // Check for exceptions
  const loanTerm = 30;
  let annualMIPRate = 0;
  let duration = 0;

  // Determine the MIP rate and duration based on the provided rules
  if (loanTerm > 15) {
    if (baseLoanAmount <= 625500) {
      if (ltv <= 90) {
        annualMIPRate = 0.008; // 80 bps
        duration = 11;
      } else if (ltv <= 95) {
        annualMIPRate = 0.008; // 80 bps
      } else {
        annualMIPRate = 0.0085; // 85 bps
      }
    } else {
      if (ltv <= 90) {
        annualMIPRate = 0.01; // 100 bps
        duration = 11;
      } else if (ltv <= 95) {
        annualMIPRate = 0.01; // 100 bps
      } else {
        annualMIPRate = 0.0105; // 105 bps
      }
    }
  } else {
    // Loan term <= 15 years
    if (baseLoanAmount <= 625500) {
      if (ltv <= 90) {
        annualMIPRate = 0.0045; // 45 bps
        duration = 11;
      } else {
        annualMIPRate = 0.007; // 70 bps
      }
    } else {
      if (ltv <= 78) {
        annualMIPRate = 0.0045; // 45 bps
        duration = 11;
      } else if (ltv <= 90) {
        annualMIPRate = 0.007; // 70 bps
        duration = 11;
      } else {
        annualMIPRate = 0.0095; // 95 bps
      }
    }
  }

  const annualMIP = baseLoanAmount * annualMIPRate;
  const monthlyMIP = annualMIP / 12;
  return monthlyMIP;
};

export const calculateFHAClosingDisclosure = ({
  offerPrice,
  listPrice,
  downPayment = 0,
  zipCode = 0,
  hoaFees = 0,
  commission = 0,
  marketInterestRate = 6.875,
  creditScore = 0,
  loanTerm = 30,
}: CalculateClosingDisclosureProps) => {
  const purchasePrice = offerPrice || listPrice;

  const taxFactor = calculateTaxFactor(zipCode);
  const transferTaxFactor = calculateTransferTaxFactor(zipCode);

  //database Constants
  const interestRate = chooseMarketInterestRate(
    marketInterestRate,
    creditScore
  );

  const baseLoanAmount = purchasePrice - downPayment;
  const upfrontMIP = upfrontMIPcalc(downPayment, purchasePrice);
  const loanAmount = purchasePrice - downPayment + upfrontMIP;

  //change on user input on account page
  const numberOfPayments = loanTerm * 12;

  // constant fees
  const countyRecordingFees = 250;
  const stateRecordingFees = 150;
  const titleTitleSearchBase = 250;
  const recordingDeedBase = 75;
  const appraisalFeeBase = 550;
  const underwritingFee = 1295;
  const applicationFeeBase = 995;
  const recordingTitle = 75;
  const recordingMortgage = 75;
  const surveyFee = 250;
  const pestInspectionFee = 145;
  const floodMonitoringFee = 25;
  const floodDeterminationFee = 35;
  const creditReportFeeBase = 150;
  const processingFeeBase = 795;
  const titleSettlementAgentFee = 350;
  const earnestMoneyDeposit = 1000;
  const insuranceBinder = 150;
  const lenderCredits = 0;
  const initialEscrowMortgageInsurance = 0;
  const prepaidsPropertyTaxes = -100; //this is a credit from the buyer

  // factored calculations
  const annualPropertyTax = (purchasePrice * taxFactor) / 100;
  //UNFACTORED CALCULATIONS, NEED TO FIGURE OUT HOW TO INCLUDE CREDIT AND STATE FACTORS
  const optionalOwnersTitleInsuranceBase = purchasePrice * 0.00575; //State, Credit
  const recordingTransferTaxBase = purchasePrice * 0.01; //State dependent.
  const upfrontMIPfee = upfrontMIPcalc(downPayment, purchasePrice);
  const ltv = (loanAmount / purchasePrice) * 100;
  const monthlyMIP = calculateMIP(loanAmount, ltv);
  const monthlyHomeownersInsurance = (purchasePrice * 0.01035) / 12;
  const monthlyPropertyTaxes = annualPropertyTax / 12;
  const prepaidsHomeownersInsurance = purchasePrice * 0.01035;
  const titleLendersTitleInsuranceBase = purchasePrice * 0.0045;
  const homeInspectionFeeBase = purchasePrice * 0.0035;
  const initialEscrowTaxes = monthlyPropertyTaxes * 6;
  const initialEscrowHOI = ((purchasePrice * 0.01) / 12) * 3;

  //these aren't going to change, they are determined
  const dailyInterestRate = interestRate / 365 / 100; // Convert annual rate to daily decimal
  const prepaidInterest = loanAmount * dailyInterestRate * 15; // Return the result rounded to 2 decimal places
  const monthlyInterestRate = interestRate / 12 / 100; // Convert annual rate to monthly decimal
  const principalAndInterest =
    (loanAmount *
      (monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments))) /
    (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  const transferTaxes = purchasePrice * transferTaxFactor;
  const estimatedEscrow =
    monthlyHomeownersInsurance + monthlyPropertyTaxes + monthlyMIP;
  const piti = principalAndInterest + estimatedEscrow;
  const cleanPayment = piti;
  const otherCosts =
    optionalOwnersTitleInsuranceBase +
    pestInspectionFee +
    initialEscrowTaxes +
    prepaidsPropertyTaxes +
    prepaidInterest +
    prepaidsHomeownersInsurance +
    stateRecordingFees +
    countyRecordingFees +
    recordingMortgage +
    recordingTitle +
    recordingDeedBase +
    transferTaxes;
  const costTotals =
    insuranceBinder +
    titleTitleSearchBase +
    titleLendersTitleInsuranceBase +
    surveyFee +
    titleSettlementAgentFee +
    floodDeterminationFee +
    floodMonitoringFee +
    creditReportFeeBase +
    appraisalFeeBase +
    applicationFeeBase +
    underwritingFee +
    processingFeeBase +
    creditReportFeeBase;
  const closingCosts = otherCosts + costTotals;
  const cashToClose = closingCosts + downPayment + earnestMoneyDeposit;
  const cashOutsideClosing =
    appraisalFeeBase + earnestMoneyDeposit + homeInspectionFeeBase;
  const unbeatableCredit = (purchasePrice * commission) / 100; //this is where we put the buyers agent commission
  const unbeatableDeal = cashToClose - unbeatableCredit;

  // Perform calculations using the provided purchase price and constants
  // Prepare the closing disclosure object
  const closingDisclosure = {
    type: USER_LOAN_PROGRAM.FHA,
    offerPrice,
    listPrice,
    purchasePrice,
    commission,
    marketInterestRate,
    creditScore,
    downPayment,
    loanAmount,
    interestRate,

    cleanPayment,
    cashOutsideClosing,
    monthlyMIP,
    processingFeeBase,
    principalAndInterest,
    homeInspectionFeeBase,
    loanTerm,
    optionalOwnersTitleInsuranceBase,
    hoaFees,
    earnestMoneyDeposit,
    piti,
    estimatedEscrow,
    monthlyPropertyTaxes,
    monthlyHomeownersInsurance,
    prepaidsHomeownersInsurance,
    prepaidsPropertyTaxes,
    upfrontMIPfee,
    prepaidInterest,
    titleSettlementAgentFee,
    titleLendersTitleInsuranceBase,
    titleTitleSearchBase,
    insuranceBinder,
    recordingDeedBase,
    appraisalFeeBase,
    underwritingFee,
    applicationFeeBase,
    pestInspectionFee,
    floodMonitoringFee,
    floodDeterminationFee,
    creditReportFeeBase,
    recordingTitle,
    recordingMortgage,
    countyRecordingFees,
    stateRecordingFees,
    surveyFee,
    initialEscrowTaxes,
    initialEscrowMortgageInsurance,
    initialEscrowHOI,
    lenderCredits,
    costTotals,
    otherCosts,
    closingCosts,
    cashToClose,
    unbeatableDeal,
    transferTaxes,
  };
  return closingDisclosure;
};

export type ProductFHAEsitmation = ReturnType<
  typeof calculateFHAClosingDisclosure
>;
