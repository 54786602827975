export const DefaultEmailTemplate = {
  counters: {
    u_column: 2,
    u_row: 2,
    u_content_heading: 1,
    u_content_image: 2,
    u_content_text: 1,
  },
  body: {
    id: 'xG1SO02t14',
    rows: [
      {
        id: 'w2FDiew2-G',
        cells: [1],
        columns: [
          {
            id: 'YVT1FuKjZW',
            contents: [
              {
                id: 'utnUzunW6l',
                type: 'image',
                values: {
                  containerPadding: '40px',
                  anchor: '',
                  src: {
                    url: 'https://assets.unlayer.com/projects/0/1720369596977-Asset%202-8.png',
                    width: 2560,
                    height: 442,
                    autoWidth: false,
                    maxWidth: '50%',
                  },
                  textAlign: 'center',
                  altText: '',
                  action: {
                    name: 'web',
                    values: { href: '{{siteUrl}}', target: '_blank' },
                    attrs: { href: '{{href}}', target: '{{target}}' },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_image_1',
                    htmlClassNames: 'u_content_image',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: {
                    mobile: {
                      containerPadding: '16px',
                    },
                  },
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
            customPosition: ['50%', '50%'],
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: 'CRQV8DexID',
        cells: [1],
        columns: [
          {
            id: 'DWaE7bJMcL',
            contents: [],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              borderRadius: '0px',
              _meta: { htmlID: 'u_column_2', htmlClassNames: 'u_column' },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          padding: '30px',
          anchor: '',
          hideDesktop: false,
          _meta: { htmlID: 'u_row_2', htmlClassNames: 'u_row' },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    headers: [],
    footers: [],
    values: {
      popupPosition: 'center',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
      textColor: '#000000',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'cover',
        position: 'center',
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: '#f1f3f5',
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: 'no-repeat',
        size: 'custom',
        position: 'center',
      },
      _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
    },
  },
  schemaVersion: 16,
};
