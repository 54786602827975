import { IconButton, IconButtonProps, useTheme } from '@mui/material';

export const CommonRemoveIconButton = (props: IconButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton size="small" {...props}>
      <i
        className="fa-light fa-trash-can"
        style={{ color: theme.palette.error.main }}
      />
    </IconButton>
  );
};
