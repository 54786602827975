import { useRef } from 'react';
import { Box, TextField } from '@mui/material';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';

import { CommonNumberInput } from '../CommonNumberInput';

type DataGridTextFieldProps = GridRenderCellParams<any, string> & {
  type?: string;
};

export const DataGridTextField = (props: DataGridTextFieldProps) => {
  const { id, value, field, type, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = useRef<HTMLElement>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    apiRef.current.setEditCellValue({ id, field, value: e.target.value });
  };

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(
        `input[value="${value}"]`
      );
      input?.focus();
    }
  }, [hasFocus, value]);

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%', px: 1 }}
    >
      {type === 'number' ? (
        <CommonNumberInput
          inputRef={ref}
          value={value || ''}
          onChange={handleChange}
        />
      ) : (
        <TextField inputRef={ref} value={value || ''} onChange={handleChange} />
      )}
    </Box>
  );
};
