import { useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRemoveProductMutation } from '@/apis/extended/productApi';
import { useGetCurrentUserQuery } from '@/apis/extended/userApi';
import { formatNumber } from '@/utils/helper';
import { Product } from '@/types/ProductType';
import { CommonEditIconButton } from '../Common/CommonButton/CommonEditIconButton';
import { CommonRemoveIconButton } from '../Common/CommonButton/CommonRemoveIconButton';
import { EditProductDetailModal } from '../ConfirmModals/EditProductDetailModal';
import { RemoveProductModal } from '../ConfirmModals/RemoveProductModal';
import { ProductBadges } from './ProductBadges';

type ProductDetailBasicProps = {
  product: Product;
};

export const ProductDetailBasic = ({ product }: ProductDetailBasicProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: user } = useGetCurrentUserQuery();
  const [removeProduct] = useRemoveProductMutation();

  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isRemoveProductModalOpen, setIsRemoveProductModalOpen] =
    useState(false);

  if (!product || !user) return null;

  const handleRemoveProduct = () => {
    removeProduct(product._id);
    if (location.pathname.includes('admin')) navigate('/admin/product');
    else navigate('/main');
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">$ {formatNumber(product.price)}</Typography>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <CommonEditIconButton
            onClick={() => setIsEditProductModalOpen(true)}
          />
          <CommonRemoveIconButton
            onClick={() => setIsRemoveProductModalOpen(true)}
          />
        </Box>
      </Box>
      <Typography variant="h3" sx={{ mt: 2 }}>
        {product.name}
      </Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 1 }}
      >
        <ProductBadges product={product} />
      </Box>
      {product.note && (
        <Chip
          sx={{ mt: 1 }}
          variant="outlined"
          color="info"
          label={`Size Note: ${product.note}`}
        />
      )}
      <Typography variant="body1" sx={{ mt: 3 }}>
        {product.description}
      </Typography>
      <EditProductDetailModal
        isOpen={isEditProductModalOpen}
        product={product}
        onClose={() => setIsEditProductModalOpen(false)}
      />
      <RemoveProductModal
        isOpen={isRemoveProductModalOpen}
        onClose={() => setIsRemoveProductModalOpen(false)}
        onConfirm={handleRemoveProduct}
      />
    </Box>
  );
};
