import {
  UpdateUserLoanInfoRequest,
  UpdateUserRequest,
  User,
} from '@/types/UserType';
import { rtkApi } from '../rtkApi';

export const userApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({ url: `/user` }),
      providesTags: ['User'],
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: (request) => ({
        url: `/user`,
        method: 'PUT',
        data: request,
      }),
      invalidatesTags: ['User'],
    }),
    updateUserLoanInfo: builder.mutation<void, UpdateUserLoanInfoRequest>({
      query: (request) => ({
        url: `/user/loaninfo`,
        method: 'PUT',
        data: request,
      }),
      invalidatesTags: ['User'],
    }),
    sendApproveRequest: builder.mutation<void, void>({
      query: () => ({
        url: `/user/approve`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    getUserInterestRate: builder.query<number, void>({
      query: () => ({ url: `/user/interest-rate` }),
    }),
    addDocument: builder.mutation<void, string>({
      query: (document) => ({
        url: `/user/document`,
        method: 'POST',
        data: { document },
      }),
      invalidatesTags: ['User'],
    }),
    removeDocument: builder.mutation<void, string>({
      query: (document) => ({
        url: `/user/document`,
        method: 'DELETE',
        params: { document },
      }),
      invalidatesTags: ['User'],
    }),
    removeUser: builder.mutation<void, void>({
      query: () => ({
        url: `/user`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    deleteUserData: builder.mutation<void, string>({
      query: (email) => ({
        url: `/user/data`,
        params: { email },
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    verifyDeleteRequest: builder.mutation<void, string>({
      query: (uid: string) => ({
        url: '/user/data/verify',
        method: 'post',
        data: { uid },
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
  useUpdateUserLoanInfoMutation,
  useSendApproveRequestMutation,
  useGetUserInterestRateQuery,
  useAddDocumentMutation,
  useRemoveDocumentMutation,
  useRemoveUserMutation,
  useDeleteUserDataMutation,
  useVerifyDeleteRequestMutation,
} = userApi;
