import { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowModel,
} from '@mui/x-data-grid';
import _ from 'lodash';

import { DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE } from '@/config/constants';
import {
  useGetAgentsByAdminQuery,
  useRemoveAgentByAdminMutation,
  useUpdateAgentByAdminMutation,
} from '@/apis/extended/admin/agentApi';
import { CommonRemoveIconButton } from '@/components/Common/CommonButton/CommonRemoveIconButton';
import { CommonDataGrid } from '@/components/Common/CommonDataGrid';
import { DataGridTextField } from '@/components/Common/CommonDataGrid/DataGridTextField';
import { RemoveAgentModal } from '@/components/ConfirmModals/RemoveAgentModal';
import { Loader } from '@/components/Layout/Loader';
import { Agent } from '@/types/AgentType';

export const ManageAgentTable = () => {
  const theme = useTheme();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE);
  const [removingAgent, setRemovingAgent] = useState<Agent | null>(null);
  const isRemoveAgentModalOpen = Boolean(removingAgent);

  const { data, isLoading, isFetching } = useGetAgentsByAdminQuery({
    offset,
    limit,
  });
  const [updateAgentByAdmin] = useUpdateAgentByAdminMutation();
  const [removeAgentByAdmin] = useRemoveAgentByAdminMutation();

  const columns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          minWidth: 150,
          flex: 0.5,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        {
          field: 'domain',
          headerName: 'Domain',
          minWidth: 230,
          flex: 1,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        {
          field: 'email',
          headerName: 'Email',
          minWidth: 200,
          flex: 1,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        {
          field: 'interestRate',
          headerName: 'Interest Rate',
          minWidth: 200,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} type="number" />;
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          type: 'actions',
          getActions: (params) => [
            <CommonRemoveIconButton
              size="small"
              onClick={() => setRemovingAgent(params.row)}
            />,
          ],
        },
      ] as GridColDef<Agent>[],
    []
  );

  const onPaginationChanged = ({ page, pageSize }: GridPaginationModel) => {
    setOffset(page * pageSize);
    setLimit(pageSize);
  };

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const isUpdated = !_.isEqual(newRow, oldRow);
        if (isUpdated) {
          updateAgentByAdmin({
            id: newRow._id,
            ..._.pick(newRow, ['name', 'email', 'domain', 'interestRate']),
          })
            .unwrap()
            .then(() => resolve(newRow))
            .catch(() => resolve(oldRow));
        } else {
          resolve(oldRow);
        }
      }),
    []
  );

  const handleRemoveAgent = () => {
    if (!removingAgent) return;
    removeAgentByAdmin(removingAgent._id);
    setRemovingAgent(null);
  };

  if (isLoading || !data) return <Loader isFull />;

  return (
    <>
      <CommonDataGrid
        columns={columns}
        rows={data.agents}
        rowCount={data.count}
        paginationModel={{ page: offset / limit, pageSize: limit }}
        onPaginationModelChange={onPaginationChanged}
        loading={isFetching}
        processRowUpdate={processRowUpdate}
      />
      <RemoveAgentModal
        isOpen={isRemoveAgentModalOpen}
        onClose={() => setRemovingAgent(null)}
        onConfirm={handleRemoveAgent}
      />
    </>
  );
};
