import { ThemeProvider } from '@mui/material';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { setAuthInterceptors } from './apis/rawApi';
import { useAuth } from './hooks/useAuth';
import NotFound from './pages/Basic/NotFound';
import { routes as appRoutes } from './routes';
import theme from './utils/theme';

setAuthInterceptors();

type AuthenticateGuardProps = {
  component: React.FC;
  requireAdmin?: boolean;
  requireAuth?: boolean;
};

const AuthenticateGuard = ({
  component,
  requireAdmin,
  requireAuth,
}: AuthenticateGuardProps) => {
  const { isLoading, isAuthenticated, isAdmin } = useAuth();

  if (isLoading) {
    return null;
  }

  if (requireAuth && !isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (requireAdmin && !isAdmin) {
    return <Navigate to="/" />;
  }

  const Component = component;
  return <Component />;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {appRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          >
            {route.children.map((childRoute) => (
              <Route
                key={childRoute.key}
                path={childRoute.path}
                element={
                  childRoute.requireAuth ? (
                    <AuthenticateGuard
                      component={childRoute.component}
                      requireAdmin={childRoute.requireAdmin}
                      requireAuth={childRoute.requireAuth}
                    />
                  ) : (
                    <childRoute.component />
                  )
                }
              />
            ))}
          </Route>
        ))}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </Router>
  );
};

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
};
