import { useGetProductQuery } from '@/apis/extended/productApi';
import { ContractDetailBuyer } from './ContractDetailBuyer';
import { ContractNotStarted } from './ContractNotStarted';

type ContractDetailProps = {
  productId: string;
};

export const ContractDetail = ({ productId }: ContractDetailProps) => {
  const { data: product } = useGetProductQuery(productId);

  if (!product) return null;
  if (!product.contract) return <ContractNotStarted productId={productId} />;
  return <ContractDetailBuyer contractId={product.contract} />;
};
