import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ContractDetailAgent } from '@/components/ContractDetailAgent/ContractDetailAgent';

export const ContractDetailAgentPage = () => {
  const { uid } = useParams();

  if (!uid) return null;

  return (
    <Container sx={{ py: '40px' }}>
      <ContractDetailAgent uid={uid} />
    </Container>
  );
};
