import { Box, Button, Typography } from '@mui/material';

function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h6">
        The page you're looking for doesn't exist.
      </Typography>
      <Button variant="contained" sx={{ mt: 2 }} href="/">
        Back Home
      </Button>
    </Box>
  );
}

export default NotFound;
