import {
  Contract,
  UpdateContractAgentRequest,
  UpdateContractBuyerRequest,
  UpdateContractSellerRequest,
} from '@/types/ContractType';
import { rtkApi } from '../rtkApi';

export const contractApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getContract: builder.query<Contract, string>({
      query: (contractId) => ({ url: `/contract/${contractId}` }),
      providesTags: (_response, _result, contractId) => [
        { type: 'Contract', id: contractId },
      ],
    }),
    getContractByAgent: builder.query<Contract, string>({
      query: (uid) => ({ url: `/contract/agent/${uid}` }),
      providesTags: (_response, _result, uid) => [
        { type: 'Contract Agent', id: uid },
      ],
    }),
    getContractBySeller: builder.query<Contract, string>({
      query: (uid) => ({ url: `/contract/seller/${uid}` }),
      providesTags: (_response, _result, uid) => [
        { type: 'Contract Seller', id: uid },
      ],
    }),

    updateContractBuyer: builder.mutation<void, UpdateContractBuyerRequest>({
      query: ({ contractId, ...props }) => ({
        url: `/contract/${contractId}/buyer`,
        method: 'PUT',
        data: props,
      }),
      invalidatesTags: (_result, _error, { contractId }) => [
        { type: 'Contract', id: contractId },
      ],
    }),
    updateContractAgent: builder.mutation<void, UpdateContractAgentRequest>({
      query: ({ uid, ...props }) => ({
        url: `/contract/agent/${uid}`,
        method: 'PUT',
        data: props,
      }),
      invalidatesTags: (_result, _error, { uid }) => [
        { type: 'Contract Agent', id: uid },
      ],
    }),
    updateContractSeller: builder.mutation<void, UpdateContractSellerRequest>({
      query: ({ uid, ...props }) => ({
        url: `/contract/seller/${uid}`,
        method: 'PUT',
        data: props,
      }),
      invalidatesTags: (_result, _error, { uid }) => [
        { type: 'Contract Seller', id: uid },
      ],
    }),
  }),
});

export const {
  useGetContractQuery,
  useGetContractByAgentQuery,
  useGetContractBySellerQuery,

  useUpdateContractBuyerMutation,
  useUpdateContractAgentMutation,
  useUpdateContractSellerMutation,
} = contractApi;
