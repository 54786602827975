import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { USER_APPROVE_STATUS } from '@/config/constants';
import { useStartContractMutation } from '@/apis/extended/productApi';
import { useProductStatus } from '@/hooks/useProductStatus';
import { Product, PRODUCT_APPROVE_STATUS } from '@/types/ProductType';

type ProductDetailExploreOfferButtonProps = {
  product: Product;
};

export const ProductDetailExploreOfferButton = ({
  product,
}: ProductDetailExploreOfferButtonProps) => {
  const user = product.user;
  const navigate = useNavigate();
  const { approveStatus } = useProductStatus(product);

  const [startContract, { isLoading }] = useStartContractMutation();

  if (
    user?.approveStatus !== USER_APPROVE_STATUS.APPROVED ||
    !user?.approvalAmount
  ) {
    return null;
  }
  if (approveStatus !== PRODUCT_APPROVE_STATUS.APPROVED) return null;

  const handleStartContract = async () => {
    await startContract(product._id).unwrap();
    navigate(`/contract/${product._id}`);
  };

  return (
    <>
      {product.contractStarted && product.contract ? (
        <Button
          href={`/contract/${product._id}`}
          startIcon={<i className="fa-light fa-binoculars" />}
        >
          View Contract
        </Button>
      ) : (
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={isLoading}
          onClick={handleStartContract}
          startIcon={<i className="fa-light fa-play" />}
        >
          Explore an Offer
        </LoadingButton>
      )}
    </>
  );
};
