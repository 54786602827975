import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CardHeader, TextField } from '@mui/material';

import {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
} from '@/apis/extended/userApi';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonPhoneInput } from '../Common/CommonPhoneInput';

const initialForm = {
  name: '',
  phone: '',
};

export const ProfilePersonalInformation = () => {
  const { data, isFetching } = useGetCurrentUserQuery();
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();

  const [form, setForm] = useState({ ...initialForm });

  useEffect(() => {
    setForm({
      name: data?.name || '',
      phone: data?.phone || '',
    });
  }, [data]);

  const isUpdated = useMemo(() => {
    return form.name !== data?.name || form.phone !== data?.phone;
  }, [form, data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    updateUser(form);
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title="Personal Information"
        avatar={<i className="fa-light fa-gear" />}
      />
      <CardContent>
        <CommonFormControl label="Name:">
          <TextField
            placeholder="Name"
            name="name"
            value={form.name}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl label="Username:" sx={{ mt: 1 }}>
          <TextField
            placeholder="Username"
            name="username"
            disabled
            value={data?.username || ''}
          />
        </CommonFormControl>
        <CommonFormControl label="Email:" sx={{ mt: 1 }}>
          <TextField
            placeholder="Email"
            name="email"
            disabled
            value={data?.email || ''}
          />
        </CommonFormControl>
        <CommonFormControl label="Phone Number:" sx={{ mt: 1 }}>
          <CommonPhoneInput
            value={form.phone}
            onChange={(value) => {
              setForm({ ...form, phone: value });
            }}
          />
        </CommonFormControl>
        <CommonFormControl sx={{ mt: 1 }}>
          <LoadingButton
            loading={isFetching || isUpdatingUser}
            disabled={!isUpdated}
            variant="contained"
            startIcon={<i className="fa-light fa-pencil" />}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </CommonFormControl>
      </CardContent>
    </Card>
  );
};
