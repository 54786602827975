import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from '@mui/material';

import {
  useGetContractBySellerQuery,
  useUpdateContractSellerMutation,
} from '@/apis/extended/contractApi';
import { parseString } from '@/utils/helper';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonNumberInput } from '../Common/CommonNumberInput';

const initialForm = {
  taxDate: '',
  taxAmount: '',
  mortgageAmount: '',
  mortgageCompany: '',
};

type ContractDetailSellerPropertyInfoProps = {
  uid: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailSellerPropertyInfo = ({
  uid,
  onPrev,
  onNext,
}: ContractDetailSellerPropertyInfoProps) => {
  const { data: contract } = useGetContractBySellerQuery(uid);
  const [updateContractSeller, { isLoading }] =
    useUpdateContractSellerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const sellerPropertyInfo = contract?.sellerPropertyInfo;
    if (!sellerPropertyInfo) return;
    setForm({
      taxDate: sellerPropertyInfo.taxDate || '',
      taxAmount: parseString(sellerPropertyInfo.taxAmount),
      mortgageAmount: parseString(sellerPropertyInfo.mortgageAmount),
      mortgageCompany: sellerPropertyInfo.mortgageCompany || '',
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return (
      form.taxDate &&
      form.taxAmount &&
      form.mortgageAmount &&
      form.mortgageCompany
    );
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractSeller({
      uid,
      sellerPropertyInfo: {
        ...form,
        taxAmount: parseFloat(form.taxAmount),
        mortgageAmount: parseFloat(form.mortgageAmount),
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Property Disclosure"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-user" />}
        />
        <CardContent>
          <Box>
            <Typography variant="h5">
              The most recent property tax payment:
            </Typography>
            <CommonFormControl
              sx={{ mt: 2 }}
              label="Tax Date:"
              labelWidth={150}
            >
              <TextField
                placeholder="Tax Date"
                name="taxDate"
                type="date"
                value={form.taxDate}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              sx={{ mt: 2 }}
              label="Tax Amount:"
              labelWidth={150}
            >
              <CommonNumberInput
                placeholder="Tax Amount"
                name="taxAmount"
                value={form.taxAmount}
                onChange={handleInputChange}
              />
            </CommonFormControl>
          </Box>
          <Divider />
          <Box>
            <Typography variant="h5">Current Mortgage Company:</Typography>
            <CommonFormControl
              sx={{ mt: 2 }}
              label="Mortgage Amount:"
              labelWidth={150}
            >
              <CommonNumberInput
                placeholder="Mortgage Amount"
                name="mortgageAmount"
                value={form.mortgageAmount}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              sx={{ mt: 2 }}
              label="Mortgage Company:"
              labelWidth={150}
            >
              <TextField
                placeholder="Mortgage Company"
                name="mortgageCompany"
                value={form.mortgageCompany}
                onChange={handleInputChange}
              />
            </CommonFormControl>
          </Box>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
