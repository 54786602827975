import { useCallback, useMemo, useState } from 'react';
import { Chip, useTheme } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowModel,
} from '@mui/x-data-grid';
import _ from 'lodash';

import {
  DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE,
  USER_LOAN_ESTIMATION_DICT,
  USER_LOAN_ESTIMATION_TYPE,
  USER_LOAN_PROGRAM,
  USER_OCCUPANCY,
  USER_QUALIFY_STATUS,
  USER_ROLE,
  userApproveStatuses,
  userLoanEstimationTypeMap,
  userLoanEstimationTypes,
  userLoanProgramMap,
  userLoanPrograms,
  userOccupancyMap,
  userOccupancys,
  userRoles,
} from '@/config/constants';
import {
  useGetUsersByAdminQuery,
  useRemoveUserByAdminMutation,
  useUpdateUserByAdminMutation,
} from '@/apis/extended/admin/userApi';
import { CommonRemoveIconButton } from '@/components/Common/CommonButton/CommonRemoveIconButton';
import { CommonSeeIconButton } from '@/components/Common/CommonButton/CommonSeeIconButton';
import { CommonDataGrid } from '@/components/Common/CommonDataGrid';
import { DataGridIconCell } from '@/components/Common/CommonDataGrid/DataGridIconCell';
import { DataGridSelect } from '@/components/Common/CommonDataGrid/DataGridSelect';
import { DataGridTextField } from '@/components/Common/CommonDataGrid/DataGridTextField';
import { RemoveUserModal } from '@/components/ConfirmModals/RemoveUserModal';
import { ViewUserModal } from '@/components/ConfirmModals/ViewUserModal';
import { Loader } from '@/components/Layout/Loader';
import { UserApproveStatusBadge } from '@/components/Profile/components/UserApproveStatusBadge';
import { formatPrice } from '@/utils/helper';
import { User } from '@/types/UserType';

export const ManageUserTable = () => {
  const theme = useTheme();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE);
  const [removingUser, setRemovingUser] = useState<User | null>(null);
  const isRemoveUserModalOpen = Boolean(removingUser);

  const [openedUser, setOpenedUser] = useState<User | null>(null);
  const isViewUserModalOpen = Boolean(openedUser);

  const { data, isLoading, isFetching } = useGetUsersByAdminQuery({
    offset,
    limit,
  });
  const [updateUserByAdmin] = useUpdateUserByAdminMutation();
  const [removeUserByAdmin] = useRemoveUserByAdminMutation();

  const columns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          minWidth: 150,
          flex: 1,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        { field: 'email', headerName: 'Email', width: 220 },
        {
          field: 'phone',
          headerName: 'Phone',
          minWidth: 200,
          flex: 1,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        {
          field: 'role',
          headerName: 'Role',
          width: 130,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridSelect {...params} items={userRoles} />;
          },
          renderCell: (params) => {
            const role = params.row.role;
            return (
              <Chip
                color={role === USER_ROLE.ADMIN ? 'error' : 'primary'}
                label={role === USER_ROLE.ADMIN ? 'Admin' : 'User'}
              />
            );
          },
        },
        {
          field: 'securityEnabled',
          headerName: 'Security',
          width: 80,
          align: 'center',
          renderCell: (params) => <DataGridIconCell status={params.value} />,
        },
        {
          field: 'qualifyStatus',
          headerName: 'Qualified',
          width: 80,
          renderCell: (params) => (
            <DataGridIconCell
              status={params.value === USER_QUALIFY_STATUS.QUALIFY}
              icon="fa-badge-check"
            />
          ),
        },
        {
          field: 'approvalAmount',
          headerName: 'Approval Amount',
          width: 180,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} type="number" />;
          },
          renderCell: (params) => formatPrice(params.value),
        },
        {
          field: 'approveStatus',
          headerName: 'Approved',
          width: 180,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridSelect {...params} items={userApproveStatuses} />;
          },
          renderCell: (params) => {
            return <UserApproveStatusBadge status={params.row.approveStatus} />;
          },
        },
        {
          field: 'loanEstimationType',
          headerName: 'Loan Estimation',
          width: 150,
          editable: true,
          renderEditCell: (params) => {
            return (
              <DataGridSelect {...params} items={userLoanEstimationTypes} />
            );
          },
          renderCell: (params) => {
            return (
              userLoanEstimationTypeMap[
                params.value as USER_LOAN_ESTIMATION_TYPE
              ] || '-'
            );
          },
        },
        {
          field: 'loanProgram',
          headerName: 'Loan Program',
          width: 150,
          editable: true,
          renderEditCell: (params) => {
            const loanEstimationDict =
              USER_LOAN_ESTIMATION_DICT[params.row.loanEstimationType!];
            const loanPrograms = loanEstimationDict?.LOAN_PROGRAMS || [];
            const filteredUserLoanPrograms = userLoanPrograms.filter((p) =>
              loanPrograms.includes(p.value)
            );
            return (
              <DataGridSelect {...params} items={filteredUserLoanPrograms} />
            );
          },
          renderCell: (params) => {
            return userLoanProgramMap[params.value as USER_LOAN_PROGRAM] || '-';
          },
        },
        {
          field: 'occupancy',
          headerName: 'Occupancy',
          width: 150,
          editable: true,
          renderEditCell: (params) => {
            const loanEstimationDict =
              USER_LOAN_ESTIMATION_DICT[params.row.loanEstimationType!];
            const occupancys = loanEstimationDict?.OCCUPANCY || [];
            const filteredUserOccupancys = userOccupancys.filter((o) =>
              occupancys.includes(o.value)
            );
            return (
              <DataGridSelect {...params} items={filteredUserOccupancys} />
            );
          },
          renderCell: (params) => {
            return userOccupancyMap[params.value as USER_OCCUPANCY] || '-';
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          type: 'actions',
          getActions: (params) => [
            <CommonSeeIconButton
              size="small"
              onClick={() => setOpenedUser(params.row)}
            />,
            <CommonRemoveIconButton
              size="small"
              onClick={() => setRemovingUser(params.row)}
            />,
          ],
        },
      ] as GridColDef<User>[],
    []
  );

  const onPaginationChanged = ({ page, pageSize }: GridPaginationModel) => {
    setOffset(page * pageSize);
    setLimit(pageSize);
  };

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const isUpdated = !_.isEqual(newRow, oldRow);
        if (isUpdated) {
          updateUserByAdmin({
            id: newRow._id,
            ..._.pick(newRow, [
              'approveStatus',
              'approvalAmount',
              'name',
              'phone',
              'role',
              'loanEstimationType',
              'loanProgram',
              'occupancy',
            ]),
          })
            .unwrap()
            .then(() => resolve(newRow))
            .catch(() => resolve(oldRow));
        } else {
          resolve(oldRow);
        }
      }),
    []
  );

  const handleRemoveUser = () => {
    if (!removingUser) return;
    removeUserByAdmin(removingUser._id);
    setRemovingUser(null);
  };

  if (isLoading || !data) return <Loader isFull />;

  return (
    <>
      <CommonDataGrid
        columns={columns}
        rows={data.users}
        rowCount={data.count}
        paginationModel={{ page: offset / limit, pageSize: limit }}
        onPaginationModelChange={onPaginationChanged}
        loading={isFetching}
        processRowUpdate={processRowUpdate}
      />
      <ViewUserModal
        isOpen={isViewUserModalOpen}
        onClose={() => setOpenedUser(null)}
        user={openedUser!}
      />
      <RemoveUserModal
        isOpen={isRemoveUserModalOpen}
        onClose={() => setRemovingUser(null)}
        onConfirm={handleRemoveUser}
      />
    </>
  );
};
