import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from '@mui/material';

import {
  useGetContractQuery,
  useUpdateContractAgentMutation,
} from '@/apis/extended/contractApi';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';

const initialForm = {
  name: '',
};

type ContractDetailAgentReviewProps = {
  uid: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailAgentReview = ({
  uid,
  onPrev,
  onNext,
}: ContractDetailAgentReviewProps) => {
  const { data: contract } = useGetContractQuery(uid);
  const [updateContractAgent, { isLoading }] = useUpdateContractAgentMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const agentSign = contract?.agentSign;
    if (!agentSign) return;
    setForm({
      name: agentSign.name || '',
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return form.name;
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractAgent({
      uid,
      agentSign: {
        ...form,
      },
    }).unwrap();
    onNext();
  };
  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Review Your Offer"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-sparkles" />}
        />
        <CardContent>
          <Typography>
            Please confirm that you have reviewed all the details. Please enter
            your name below to confirm and submit.
          </Typography>
          <CommonFormControl
            label="Legal Full Name:"
            labelWidth={200}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Legal Full Name"
              name="name"
              fullWidth
              value={form.name}
              onChange={handleInputChange}
            />
          </CommonFormControl>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
        nextText="Submit"
      />
    </Box>
  );
};
