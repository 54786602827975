import { useMemo } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import { USER_LOAN_PROGRAM } from '@/config/constants';
import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { ProductFHAEsitmation } from '@/utils/calculator/FHA';
import { ProductUSDAEsitmation } from '@/utils/calculator/USDA';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailLoanTermsProps = {
  product: Product;
};

export const ProductDetailLoanTerms = ({
  product,
}: ProductDetailLoanTermsProps) => {
  const { estimation } = useEstimation(product);

  const loanAmount = useMemo(() => {
    if (!estimation) return 0;
    let loanAmount = estimation.loanAmount;
    if (estimation.type === USER_LOAN_PROGRAM.FHA) {
      loanAmount += (estimation as ProductFHAEsitmation).upfrontMIPfee;
    } else if (estimation.type === USER_LOAN_PROGRAM.USDA) {
      loanAmount += (estimation as ProductUSDAEsitmation).upfrontgauranteeFee;
    }
    return loanAmount;
  }, [estimation]);

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Loan Terms"
        avatar={<i className="fa-light fa-synagogue" />}
      />
      <CardContent>
        <CommonFormControl
          label="Loan Amount:"
          secondLabel={formatPrice(loanAmount)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Interest Rate:"
          secondLabel={estimation.interestRate + '%'}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Purchase Price:"
          secondLabel={formatPrice(estimation.purchasePrice)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Monthly Payment (PITI):"
          secondLabel={formatPrice(estimation.piti)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Prepayment Penalty:"
          secondLabel={'No'}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Balloon Payment:"
          secondLabel={
            estimation.type === USER_LOAN_PROGRAM.FIX_AND_FLIP ? 'Yes' : 'No'
          }
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Loan Term:"
          secondLabel={estimation.loanTerm + ' years'}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
