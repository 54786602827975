import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CardHeader, TextField } from '@mui/material';

import { USER_LOAN_PROGRAM } from '@/config/constants';
import { useUpdateProductMutation } from '@/apis/extended/productApi';
import { parseString } from '@/utils/helper';
import { Product } from '@/types/ProductType';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonNumberInput } from '../Common/CommonNumberInput';

type ProductDetailPropertySpecificsProps = {
  product: Product;
};

export const ProductDetailPropertySpecifics = ({
  product,
}: ProductDetailPropertySpecificsProps) => {
  const owner = product.user;

  const [updateProduct, { isLoading: isUpdatingProduct }] =
    useUpdateProductMutation();

  const [form, setForm] = useState({
    zipCode: '',
    offerPrice: '',
    hoaFees: '',
    commission: 0,
    rent: '',
    experience: '',
    rehab: '',
    arv: '',
  });

  useEffect(() => {
    setForm({
      zipCode: parseString(product?.loanInfo?.zipCode),
      offerPrice: parseString(product?.loanInfo?.offerPrice),
      hoaFees: parseString(product?.loanInfo?.hoaFees),
      commission: product?.loanInfo?.commission || 0,
      rent: parseString(product?.loanInfo?.rent),
      experience: parseString(product?.loanInfo?.experience),
      rehab: parseString(product?.loanInfo?.rehab),
      arv: parseString(product?.loanInfo?.arv),
    });
  }, [product]);

  const isUpdated = useMemo(() => {
    return (
      form.zipCode !== product?.loanInfo?.zipCode?.toString() ||
      form.offerPrice !== product?.loanInfo?.offerPrice?.toString() ||
      form.hoaFees !== product?.loanInfo?.hoaFees?.toString() ||
      form.commission !== product?.loanInfo?.commission ||
      (owner?.loanProgram === USER_LOAN_PROGRAM.DSCR &&
        form.rent !== product?.loanInfo?.rent?.toString()) ||
      (owner?.loanProgram === USER_LOAN_PROGRAM.FIX_AND_FLIP &&
        (form.experience !== product?.loanInfo?.experience?.toString() ||
          form.rehab !== product?.loanInfo?.rehab?.toString() ||
          form.arv !== product?.loanInfo?.arv?.toString()))
    );
  }, [form, product]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    if (name === 'commission') {
      if (Number(value) > 100) value = '100';
      if (Number(value) < 0) value = '0';
    }
    setForm({ ...form, [name]: value });
  };

  const handleSave = () => {
    updateProduct({
      id: product._id,
      zipCode: form.zipCode ? parseFloat(form.zipCode) : undefined,
      offerPrice: form.offerPrice ? parseInt(form.offerPrice) : undefined,
      hoaFees: form.hoaFees ? parseFloat(form.hoaFees) : undefined,
      commission: form.commission || undefined,
      rent: form.rent ? parseFloat(form.rent) : undefined,
      experience: form.experience ? parseFloat(form.experience) : undefined,
      rehab: form.rehab ? parseFloat(form.rehab) : undefined,
      arv: form.arv ? parseFloat(form.arv) : undefined,
    });
  };

  return (
    <Card>
      <CardHeader
        title="Property Specifics"
        avatar={<i className="fa-light fa-landmark" />}
      />
      <CardContent>
        <CommonFormControl label="Zip Code:" labelWidth={230}>
          <TextField
            placeholder="Zip Code"
            name="zipCode"
            value={form.zipCode}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl label="Offer Price:" sx={{ mt: 1 }} labelWidth={230}>
          <CommonNumberInput
            placeholder="Offer Price"
            name="offerPrice"
            value={form.offerPrice}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl
          label="Monthly HOA Fees:"
          sx={{ mt: 1 }}
          labelWidth={230}
        >
          <CommonNumberInput
            placeholder="Monthly HOA Fees"
            name="hoaFees"
            value={form.hoaFees}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl
          label="Buyers Agent Commission (%):"
          sx={{ mt: 1 }}
          labelWidth={230}
        >
          <TextField
            placeholder="Buyers Agent Commission"
            name="commission"
            InputProps={{
              endAdornment: '%',
            }}
            value={form.commission}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        {owner?.loanProgram === USER_LOAN_PROGRAM.DSCR && (
          <CommonFormControl
            label="Projected Rent:"
            sx={{ mt: 1 }}
            labelWidth={230}
          >
            <CommonNumberInput
              placeholder="Projected Rent"
              name="rent"
              value={form.rent}
              onChange={handleInputChange}
            />
          </CommonFormControl>
        )}
        {owner?.loanProgram === USER_LOAN_PROGRAM.FIX_AND_FLIP && (
          <>
            <CommonFormControl label="Rehab:" sx={{ mt: 1 }} labelWidth={230}>
              <CommonNumberInput
                placeholder="Rehab"
                name="rehab"
                value={form.rehab}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              label="After Repair Value:"
              sx={{ mt: 1 }}
              labelWidth={230}
            >
              <CommonNumberInput
                placeholder="After Repair Value"
                name="arv"
                value={form.arv}
                onChange={handleInputChange}
              />
            </CommonFormControl>
            <CommonFormControl
              label="Sold Flips (experience):"
              sx={{ mt: 1 }}
              labelWidth={230}
            >
              <CommonNumberInput
                placeholder="Sold Flips (experience)"
                name="experience"
                value={form.experience}
                onChange={handleInputChange}
              />
            </CommonFormControl>
          </>
        )}
        <CommonFormControl sx={{ mt: 1 }} labelWidth={230}>
          <LoadingButton
            loading={isUpdatingProduct}
            disabled={!isUpdated}
            variant="contained"
            startIcon={<i className="fa-light fa-pencil" />}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </CommonFormControl>
      </CardContent>
    </Card>
  );
};
