import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import {
  CONTRACT_APPRAISAL_CONTINGENCY,
  CONTRACT_DEPOSIT_OPTION,
  CONTRACT_FINANCING_CONTINGENCY,
  CONTRACT_INSPECTION_CONTINGENCY,
  CONTRACT_POST_CLOSE_OCCUPANCY,
  CONTRACT_UNBEATABLE_CONTINGENCY,
  contractAppraisalContingencies,
  contractDepositOptions,
  contractFinancingContingencies,
  contractInspectionContingencies,
  contractPostCloseOccupancies,
  contractUnbeatableContingencies,
} from '@/config/constants';
import {
  useGetContractByAgentQuery,
  useUpdateContractAgentMutation,
} from '@/apis/extended/contractApi';
import { parseString } from '@/utils/helper';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonGapInput } from '../Common/CommonGapInput';
import { CommonNumberInput } from '../Common/CommonNumberInput';

const initialForm = {
  offerPrice: '',
  closingDate: '',
  depositOption: CONTRACT_DEPOSIT_OPTION.NOT_SELECTED,
  deposit1Days: '',
  deposit2Days: '',
  appraisalContingency: CONTRACT_APPRAISAL_CONTINGENCY.NOT_SELECTED,
  financingContingency: CONTRACT_FINANCING_CONTINGENCY.NOT_SELECTED,
  inspectionContingency: CONTRACT_INSPECTION_CONTINGENCY.NOT_SELECTED,
  postCloseOccupancy: CONTRACT_POST_CLOSE_OCCUPANCY.NOT_SELECTED,
  unbeatableContingency: CONTRACT_UNBEATABLE_CONTINGENCY.NOT_SELECTED,
};

type ContractDetailAgentOfferInfoProps = {
  uid: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailAgentOfferInfo = ({
  uid,
  onPrev,
  onNext,
}: ContractDetailAgentOfferInfoProps) => {
  const { data: contract } = useGetContractByAgentQuery(uid);
  const [updateContractAgent, { isLoading }] = useUpdateContractAgentMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const agentContingency = contract?.agentContingency;
    const agentOfferInfo = contract?.agentOfferInfo;
    if (!agentContingency || !agentOfferInfo) return;
    setForm({
      offerPrice: parseString(agentOfferInfo.offerPrice),
      closingDate: agentOfferInfo.closingDate,
      depositOption: agentOfferInfo.depositOption,
      deposit1Days: parseString(agentOfferInfo.deposit1Days),
      deposit2Days: parseString(agentOfferInfo.deposit2Days),
      appraisalContingency: agentContingency.appraisalContingency,
      financingContingency: agentContingency.financingContingency,
      inspectionContingency: agentContingency.inspectionContingency,
      postCloseOccupancy: agentContingency.postCloseOccupancy,
      unbeatableContingency: agentContingency.unbeatableContingency,
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return (
      form.offerPrice &&
      form.closingDate &&
      form.depositOption &&
      form.appraisalContingency &&
      form.financingContingency &&
      form.inspectionContingency &&
      form.postCloseOccupancy &&
      form.unbeatableContingency
    );
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractAgent({
      uid,
      agentOfferInfo: {
        ...form,
        offerPrice: parseFloat(form.offerPrice),
        deposit1Days: parseInt(form.deposit1Days),
        deposit2Days: parseInt(form.deposit2Days),
      },
      agentContingency: {
        ...form,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Agent Offer Information"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-flux-capacitor" />}
        />
        <CardContent>
          <CommonFormControl label="Offer Price:" labelWidth={150}>
            <CommonNumberInput
              fullWidth
              placeholder="Offer Price"
              name="offerPrice"
              value={form.offerPrice}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Closing Date:"
            labelWidth={150}
          >
            <TextField
              placeholder="Closing Date"
              name="closingDate"
              fullWidth
              type="date"
              value={form.closingDate}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <Box sx={{ mt: 2 }}>
            <RadioGroup
              sx={{ mt: 1 }}
              name="depositOption"
              value={form.depositOption}
              onChange={handleInputChange}
            >
              {contractDepositOptions.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={
                    <>
                      {item.label1}
                      {item.name && (
                        <CommonGapInput
                          type="number"
                          disabled={form.depositOption !== item.value}
                          value={form[item.name]}
                          name={item.name}
                          onChange={handleInputChange}
                        />
                      )}
                      {item.label2}
                    </>
                  }
                />
              ))}
            </RadioGroup>
          </Box>

          <Divider />
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Appraisal Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="appraisalContingency"
              value={form.appraisalContingency}
              onChange={handleInputChange}
            >
              {contractAppraisalContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Financing Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="financingContingency"
              value={form.financingContingency}
              onChange={handleInputChange}
            >
              {contractFinancingContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Inspection Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="inspectionContingency"
              value={form.inspectionContingency}
              onChange={handleInputChange}
            >
              {contractInspectionContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Post-Close Occupancy:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="postCloseOccupancy"
              value={form.postCloseOccupancy}
              onChange={handleInputChange}
            >
              {contractPostCloseOccupancies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Unbeatable Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="unbeatableContingency"
              value={form.unbeatableContingency}
              onChange={handleInputChange}
            >
              {contractUnbeatableContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
