import { rtkApi } from '@/apis/rtkApi';
import {
  CreateAgentByAdminRequest,
  GetAgentsByAdminRequest,
  GetAgentsByAdminResponse,
  UpdateAgentByAdminRequest,
} from '@/types/AgentType';

export const agentApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getAgentsByAdmin: builder.query<
      GetAgentsByAdminResponse,
      GetAgentsByAdminRequest
    >({
      query: (payload) => ({ url: `/admin/agent`, params: payload }),
      providesTags: ['Admin Agent'],
    }),
    createAgentByAdmin: builder.mutation<void, CreateAgentByAdminRequest>({
      query: (request) => ({
        url: `/admin/agent`,
        method: 'POST',
        data: request,
      }),
      invalidatesTags: ['Admin Agent'],
    }),
    updateAgentByAdmin: builder.mutation<void, UpdateAgentByAdminRequest>({
      query: (request) => ({
        url: `/admin/agent/${request.id}`,
        method: 'PUT',
        data: request,
      }),
      invalidatesTags: (_response, _result, request) => [
        'Admin Agent',
        { type: 'Admin Agent', id: request.id },
      ],
    }),
    removeAgentByAdmin: builder.mutation<void, string>({
      query: (agentId) => ({
        url: `/admin/agent/${agentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Admin Agent'],
    }),
  }),
});

export const {
  useGetAgentsByAdminQuery,
  useCreateAgentByAdminMutation,
  useUpdateAgentByAdminMutation,
  useRemoveAgentByAdminMutation,
} = agentApi;
