import { useMemo } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Product } from '@/types/ProductType';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';

import { CommonImage } from '../Common/CommonImage';

type ProductCardImageProps = {
  product: Product;
  sx?: SxProps;
};

export const ProductCardImage = ({ product, sx }: ProductCardImageProps) => {
  const theme = useTheme();

  const photos = useMemo(() => {
    return [product.photo, ...product.photos];
  }, [product]);

  return (
    <Box
      sx={{
        width: '100%',
        aspectRatio: `4 / 3`,
        borderRadius: '16px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: theme.palette.primary.main,
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Swiper pagination loop modules={[Pagination]}>
        {photos.map((photo, idx) => (
          <SwiperSlide key={idx}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CommonImage photo={photo} />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
