import { useMemo } from 'react';

import { PRODUCT_APPROVE_PRICE_RATIO } from '@/config/constants';
import {
  Product,
  PRODUCT_APPROVE_STATUS,
  PRODUCT_QUALIFY_STATUS,
} from '@/types/ProductType';
import { usePurchasingPowerEstimation } from './usePurchasingPowerEstimation';

export const useProductStatus = (product: Product) => {
  const purchasingPower = usePurchasingPowerEstimation(product.user?.loanInfo);

  const qualifyStatus = useMemo(() => {
    if (!purchasingPower) return PRODUCT_QUALIFY_STATUS.UNQUALIFIED;
    const price = product.loanInfo?.offerPrice || product.price;

    if (purchasingPower >= price * (1 - PRODUCT_APPROVE_PRICE_RATIO))
      return PRODUCT_QUALIFY_STATUS.QUALIFIED;
    return PRODUCT_QUALIFY_STATUS.UNQUALIFIED;
  }, [purchasingPower, product]);

  const approveStatus = useMemo(() => {
    if (!product?.user?.approvalAmount)
      return PRODUCT_APPROVE_STATUS.NOT_APPROVED;

    const price = product.loanInfo?.offerPrice || product.price;
    if (
      product.user.approvalAmount >=
      price * (1 - PRODUCT_APPROVE_PRICE_RATIO)
    )
      return PRODUCT_APPROVE_STATUS.APPROVED;
    return PRODUCT_APPROVE_STATUS.NOT_APPROVED;
  }, [product]);

  return { qualifyStatus, approveStatus };
};
