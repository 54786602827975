import { useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material';

import {
  useGetContractQuery,
  useUpdateContractBuyerMutation,
} from '@/apis/extended/contractApi';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';

const initialForm = {
  name: '',
  name2: '',
  email: '',
  phone: '',
};

type ContractDetailBuyerInfoProps = {
  contractId: string;
  onNext: () => void;
};

export const ContractDetailBuyerInfo = ({
  contractId,
  onNext,
}: ContractDetailBuyerInfoProps) => {
  const { data: contract } = useGetContractQuery(contractId);
  const [updateContractBuyer, { isLoading }] = useUpdateContractBuyerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const buyerInfo = contract?.buyerInfo;
    if (!buyerInfo) return;
    setForm({
      name: buyerInfo.name || '',
      name2: buyerInfo.name2 || '',
      email: buyerInfo.email || '',
      phone: buyerInfo.phone || '',
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return form.name && form.email && form.phone;
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractBuyer({
      contractId,
      buyerInfo: form,
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Buyer Information"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-user" />}
        />
        <CardContent>
          <CommonFormControl label="Buyer Legal Name:" labelWidth={250}>
            <TextField
              placeholder="Buyer Legal Name"
              name="name"
              value={form.name}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Buyer 2 Name (Optional):"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Buyer 2 Name (Optional)"
              name="name2"
              value={form.name2}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Buyer Mailing Address:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Buyer Mailing Address"
              name="email"
              value={form.email}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Buyer Daytime Phone Number:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Buyer Daytime Phone Number"
              name="phone"
              value={form.phone}
              onChange={handleInputChange}
            />
          </CommonFormControl>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onNext={handleSubmit}
      />
    </Box>
  );
};
