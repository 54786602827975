import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import {
  useGetContractBySellerQuery,
  useUpdateContractSellerMutation,
} from '@/apis/extended/contractApi';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';

const initialForm = {
  publicEasements: false,
  privateEasements: false,
  waterRights: false,
  sewerRights: false,
  historicPreservation: false,
  landUse: false,
  agriculturalOperations: false,
  conservationEasements: false,
  easement: false,
  iotDevices: false,
};

type ContractDetailSellerPropertyDisclosureProps = {
  uid: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailSellerPropertyDisclosure = ({
  uid,
  onPrev,
  onNext,
}: ContractDetailSellerPropertyDisclosureProps) => {
  const { data: contract } = useGetContractBySellerQuery(uid);
  const [updateContractSeller, { isLoading }] =
    useUpdateContractSellerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const sellerPropertyDisclosure = contract?.sellerPropertyDisclosure;
    if (!sellerPropertyDisclosure) return;
    setForm({
      publicEasements: sellerPropertyDisclosure.publicEasements || false,
      privateEasements: sellerPropertyDisclosure.privateEasements || false,
      waterRights: sellerPropertyDisclosure.waterRights || false,
      sewerRights: sellerPropertyDisclosure.sewerRights || false,
      historicPreservation:
        sellerPropertyDisclosure.historicPreservation || false,
      landUse: sellerPropertyDisclosure.landUse || false,
      agriculturalOperations:
        sellerPropertyDisclosure.agriculturalOperations || false,
      conservationEasements:
        sellerPropertyDisclosure.conservationEasements || false,
      easement: sellerPropertyDisclosure.easement || false,
      iotDevices: sellerPropertyDisclosure.iotDevices || false,
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return true;
  }, [form]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractSeller({
      uid,
      sellerPropertyDisclosure: {
        ...form,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Property Disclosure"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-user" />}
        />
        <CardContent>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.publicEasements}
                  name="publicEasements"
                  onChange={handleCheckboxChange}
                />
              }
              label="Public Easements"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.privateEasements}
                  name="privateEasements"
                  onChange={handleCheckboxChange}
                />
              }
              label="Private Easements"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.waterRights}
                  name="waterRights"
                  onChange={handleCheckboxChange}
                />
              }
              label="Water"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.sewerRights}
                  name="sewerRights"
                  onChange={handleCheckboxChange}
                />
              }
              label="Sewer"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.historicPreservation}
                  name="historicPreservation"
                  onChange={handleCheckboxChange}
                />
              }
              label="Historic Preservation"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.landUse}
                  name="landUse"
                  onChange={handleCheckboxChange}
                />
              }
              label="Land Use"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.agriculturalOperations}
                  name="agriculturalOperations"
                  onChange={handleCheckboxChange}
                />
              }
              label="Agricultural Operations"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.conservationEasements}
                  name="conservationEasements"
                  onChange={handleCheckboxChange}
                />
              }
              label="Conservation Easements"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.easement}
                  name="easement"
                  onChange={handleCheckboxChange}
                />
              }
              label="Easement"
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.iotDevices}
                  name="iotDevices"
                  onChange={handleCheckboxChange}
                />
              }
              label="IoT Devices"
            />
          </Box>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
