import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';

import {
  contractAppraisalContingencies,
  contractFinancingContingencies,
  contractInspectionContingencies,
  contractPostCloseOccupancies,
  contractUnbeatableContingencies,
} from '@/config/constants';
import { useGetContractByAgentQuery } from '@/apis/extended/contractApi';
import { formatPrice } from '@/utils/helper';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';

type ContractDetailAgentSummaryProps = {
  uid: string;
  onNext: () => void;
};

export const ContractDetailAgentSummary = ({
  uid,
  onNext,
}: ContractDetailAgentSummaryProps) => {
  const theme = useTheme();

  const { data: contract } = useGetContractByAgentQuery(uid);
  if (!contract) return;

  return (
    <Box>
      <Card sx={{ mt: 4 }}>
        <CardHeader
          title="Transaction Summary"
          action={<CommonActions onNext={onNext} />}
          avatar={<i className="fa-light fa-money-from-bracket" />}
        />
        <CardContent>
          <CommonFormControl
            label="Offer Price:"
            secondLabel={formatPrice(contract.offerSpecifics?.offerPrice)}
            labelWidth={150}
          />
          <Divider />
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Appraisal Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="appraisalContingency"
              value={contract.contingency?.appraisalContingency}
            >
              {contractAppraisalContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  disabled
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Financing Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="financingContingency"
              value={contract.contingency?.financingContingency}
            >
              {contractFinancingContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  disabled
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Inspection Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="inspectionContingency"
              value={contract.contingency?.inspectionContingency}
            >
              {contractInspectionContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  disabled
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Post-Close Occupancy:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="postCloseOccupancy"
              value={contract.contingency?.postCloseOccupancy}
            >
              {contractPostCloseOccupancies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  disabled
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Unbeatable Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="unbeatableContingency"
              value={contract.contingency?.unbeatableContingency}
            >
              {contractUnbeatableContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  disabled
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Divider />
          <CommonFormControl
            sx={{ mt: 2 }}
            label="Closing Date:"
            secondLabel={contract.offerSpecifics?.closingDate}
            labelWidth={150}
          />
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        onNext={onNext}
        nextText="Confirm"
      />
    </Box>
  );
};
