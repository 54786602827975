import { rtkApi } from '@/apis/rtkApi';
import {
  CreateEmailByAdminRequest,
  GetEmailsByAdminRequest,
  GetEmailsByAdminResponse,
  UpdateEmailByAdminRequest,
} from '@/types/EmailType';

export const emailApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmailsByAdmin: builder.query<
      GetEmailsByAdminResponse,
      GetEmailsByAdminRequest
    >({
      query: (payload) => ({ url: `/admin/email`, params: payload }),
      providesTags: ['Admin Email'],
    }),
    createEmailByAdmin: builder.mutation<void, CreateEmailByAdminRequest>({
      query: (request) => ({
        url: `/admin/email`,
        method: 'POST',
        data: request,
      }),
      invalidatesTags: ['Admin Email'],
    }),
    updateEmailByAdmin: builder.mutation<void, UpdateEmailByAdminRequest>({
      query: (request) => ({
        url: `/admin/email/${request.id}`,
        method: 'PUT',
        data: request,
      }),
      invalidatesTags: (_response, _result, request) => [
        'Admin Email',
        { type: 'Admin Email', id: request.id },
      ],
    }),
    removeEmailByAdmin: builder.mutation<void, string>({
      query: (emailId) => ({
        url: `/admin/email/${emailId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Admin Email'],
    }),
  }),
});

export const {
  useGetEmailsByAdminQuery,
  useCreateEmailByAdminMutation,
  useUpdateEmailByAdminMutation,
  useRemoveEmailByAdminMutation,
} = emailApi;
