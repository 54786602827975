import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    mainColors: {
      primary: string;
      secondary: string;
      black: string;
      white: string;
      green: string;
      blue: string;
      background: string;
      background1: string;
      backgroundOpacity1: string;
      backgroundOverlay: string;
    };
    greyShades: {
      grey1: string;
      grey2: string;
      grey3: string;
      border1: string;
      border2: string;
    };
  }
  interface Palette {
    mainColors: {
      primary: string;
      secondary: string;
      black: string;
      white: string;
      green: string;
      blue: string;
      background: string;
      background1: string;
      backgroundOpacity1: string;
      backgroundOverlay: string;
    };
    greyShades: {
      grey1: string;
      grey2: string;
      grey3: string;
      border1: string;
      border2: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#5632e8',
    },
    error: {
      main: '#f50057',
    },
    text: {
      primary: '#f1f5f9',
    },
    mainColors: {
      // primary: '#043a93',
      primary: '#5632e8',
      secondary: '#7d66d9',
      black: '#010101',
      white: '#f1f5f9',
      green: '#0ED145',
      blue: '#4285F5',
      background: '#020512',
      background1: '#091345',
      backgroundOpacity1: '#09134550',
      backgroundOverlay: '#fff1',
    },
    greyShades: {
      grey1: '#b0b0b0',
      grey2: '#898989',
      grey3: '#454545',
      border1: '#334155',
      border2: '#142f5b',
    },
  },
  typography: {
    h1: {
      fontSize: '32px',
      lineHeight: '120%',
      fontWeight: 600,
    },
    h2: {
      fontSize: '28px',
      lineHeight: '120%',
      fontWeight: 600,
    },
    h3: {
      fontSize: '24px',
      lineHeight: '120%',
      fontWeight: 600,
    },
    h4: {
      fontSize: '20px',
      lineHeight: '120%',
      fontWeight: 500,
    },
    h5: {
      fontSize: '16px',
      lineHeight: '120%',
      fontWeight: 500,
    },
    h6: {
      fontSize: '12px',
      lineHeight: '120%',
      fontWeight: 500,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '120%',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '120%',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '120%',
    },
    overline: {
      fontSize: '10px',
      lineHeight: '120%',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '6px',
          textTransform: 'none',
          color: theme.palette.mainColors.white,
          '&.Mui-disabled': {
            backgroundColor: theme.palette.mainColors.primary,
            color: theme.palette.greyShades.grey1,
            opacity: 0.5,
          },
          '& .MuiLoadingButton-loadingIndicator': {
            color: theme.palette.mainColors.white,
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.greyShades.grey1,
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
        variant: 'filled',
        color: 'primary',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          gap: '4px',
          '& .MuiChip-icon': {
            fontSize: '14px',
            paddingLeft: '4px',
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mainColors.background1,
          borderRadius: '12px',
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiCardHeader-root': {
            color: theme.palette.mainColors.white,
            borderBottom: '1px solid',
            borderColor: theme.palette.greyShades.border2,
            '& .MuiCardHeader-avatar': {
              i: {
                fontSize: '20px',
              },
            },
            '& .MuiTypography-root': {
              fontSize: '20px',
              fontWeight: 500,
            },
          },
          '& .MuiCardContent-root': {
            paddingTop: '16px',
            paddingBottom: '16px',
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          width: '240px',
          maxWidth: '100%',
          borderRadius: '6px',
          '&.MuiFormControl-fullWidth': {
            width: '100%',
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.greyShades.grey1,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.greyShades.border1} !important`,
            borderRadius: '6px',
          },
          '& .MuiInputBase-input': {
            '&.Mui-disabled': {
              WebkitTextFillColor: theme.palette.greyShades.grey1,
            },
            '&::-webkit-calendar-picker-indicator': {
              filter: 'invert(1)',
            },
          },
          '@media screen and (max-width: 768px)': {
            width: '100%',
          },
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          width: '240px',
          maxWidth: '100%',
          borderRadius: '6px',
          '& .MuiSelect-icon': {
            color: theme.palette.greyShades.grey1,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.greyShades.border1} !important`,
            borderRadius: '6px',
          },
          '@media screen and (max-width: 768px)': {
            width: '100%',
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ _theme }) => ({
          '& .MuiDialog-paper': {
            minWidth: '360px',
          },
        }),
      },
    },
    MuiDivider: {
      defaultProps: {
        orientation: 'horizontal',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.greyShades.border1,
          marginTop: '16px',
          marginBottom: '16px',
          width: '100%',
        }),
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiStepLabel-iconContainer': {
            '& .MuiSvgIcon-root': {
              color: theme.palette.mainColors.background1,
              '&.Mui-completed': {
                color: theme.palette.mainColors.secondary,
              },
              '&.Mui-active': {
                color: theme.palette.mainColors.primary,
              },
            },
            '&.Mui-disabled': {
              '& .MuiSvgIcon-root': {
                color: theme.palette.mainColors.background1,
              },
              '&.Mui-active': {
                color: theme.palette.mainColors.primary,
              },
            },
          },
          '& .MuiStepLabel-label': {
            fontWeight: 700,
            '&.Mui-disabled': {
              color: theme.palette.greyShades.grey1,
            },
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTab-root': {
            color: theme.palette.greyShades.grey1,
            textTransform: 'none',
            '&.Mui-selected': {
              color: theme.palette.mainColors.white,
            },
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.mainColors.white,
          },
        }),
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: '8px',
          '& .MuiFormControlLabel-label': {
            color: theme.palette.greyShades.grey1,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiCheckbox-root': {
            '&.Mui-disabled': {
              color: theme.palette.greyShades.grey3,
            },
          },
          '& .MuiFormControlLabel-label': {
            color: theme.palette.greyShades.grey1,
            '&.Mui-disabled': {
              color: theme.palette.greyShades.grey3,
            },
          },
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.greyShades.grey1,
          '&.Mui-disabled': {
            color: theme.palette.greyShades.grey3,
          },
          '&.Mui-checked': {
            color: theme.palette.mainColors.primary,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.greyShades.grey1,
          '&.Mui-checked': {
            color: theme.palette.mainColors.primary,
          },
        }),
      },
    },
  },
});

export default theme;
