import { LoadingButton } from '@mui/lab';
import { Box, IconButton, styled, Typography, useTheme } from '@mui/material';

import { extractFileNameFromUrl } from '@/utils/helper';
import { RemoveAttachmentModal } from '../ConfirmModals/RemoveAttachmentModal';
import { useState } from 'react';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type CommonMultipleFileInputProps = {
  onFileChange: (file: File | null) => void;
  urls?: string[];
  label?: string;
  isLoading?: boolean;
  onRemove: (url: string) => void;
};

export const CommonMultipleFileInput = ({
  onFileChange,
  urls,
  label = 'Add',
  isLoading,
  onRemove,
}: CommonMultipleFileInputProps) => {
  const theme = useTheme();
  const [removeAttachmentUrl, setRemoveAttachmentUrl] = useState('');
  const isRemoveAttachmentModalOpen = Boolean(removeAttachmentUrl);

  const handleRemoveAttachment = () => {
    if (!removeAttachmentUrl) return;
    onRemove(removeAttachmentUrl)
    setRemoveAttachmentUrl('');
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}
    >
      <LoadingButton
        sx={{
          background: 'transparent !important',
          border: `1px dashed`,
          borderColor: theme.palette.greyShades.grey1,
          flexGrow: 1,
        }}
        loading={isLoading}
        component="label"
      >
        {label}
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => onFileChange(e.target.files?.[0] || null)}
        />
      </LoadingButton>
      {urls?.map((url, idx) => (
        <Box
          key={idx}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
            width: '100%',
            border: '1px solid',
            borderColor: theme.palette.greyShades.border1,
            borderRadius: '6px',
            padding: '4px 12px',
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.greyShades.grey1 }}
          >
            {extractFileNameFromUrl(url)}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton href={url} target="_blank" size="small">
              <i className="fa-light fa-cloud-arrow-down" />
            </IconButton>
            <IconButton
              onClick={() => setRemoveAttachmentUrl(url)}
              size="small"
              sx={{ color: theme.palette.error.main }}
            >
              <i className="fa-light fa-trash" />
            </IconButton>
          </Box>
        </Box>
      ))}
      <RemoveAttachmentModal
        isOpen={isRemoveAttachmentModalOpen}
        onConfirm={handleRemoveAttachment}
        onClose={() => setRemoveAttachmentUrl('')}
      />
    </Box>
  );
};
