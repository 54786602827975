import { useState } from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';

import HouseGridImg from '@/assets/images/Landing/house-grid.png';
import HousePlaceholderImg from '@/assets/images/Landing/house-placeholder.png';
import { SigninModal } from '../Auth/SigninModal';
import { MediaObject } from './HeroSection/MediaObject';

const tabs = [
  {
    icon: 'fa-light fa-chart-simple',
    tab: 'Unbeatable Interest Rates',
    part1: {
      heading: 'Unbeatable',
      headingSecondary: 'Interest Rates',
      text: 'Unbeatable Loans shops your rate with artificial intelligence, wholesale mortgage rates, and no mark up.',
    },
    part2: {
      heading: 'Select Your Property With',
      headingSecondary: 'App Extensions',
      text: 'Monitor specific rates for your purchase, refinance, or investment like never before without a formal application or sales funnel.',
    },
  },
  {
    icon: 'fa-light fa-seedling',
    tab: 'Investor Program',
    part1: {
      heading: 'Unbeatable',
      headingSecondary: 'Investor Program',
      text: 'Use Unbeatable Loans to submit cash offers as well as secure fix-and-flip, DSCR, construction and portfolio loans.',
    },
    part2: {
      heading: 'Select Your Investment With our',
      headingSecondary: 'App Extensions',
      text: 'Find your investment on any listing service you use and add it to Unbeatable Loans with our Chrome Extension. Use free and paid market insights from numerous data sources to vet your investment alongside precise loan estimates.',
    },
  },
  {
    icon: 'fa-light fa-house',
    tab: 'Residential Purchase',
    part1: {
      heading: 'Buy a home entirely with',
      headingSecondary: 'Unbeatable Loans',
      text: 'Keep 100% of the buyers agent commission for yourself.',
    },
    part2: {
      heading: 'Select Your Property With',
      headingSecondary: 'App Extensions',
      text: `After adding a home to Unbeatable Loans, make a budget, get approved, and submit an offer. Use the buyers agent's commission to save on closing costs or reduce the purchase price.`,
    },
  },
  {
    icon: 'fa-light fa-hammer',
    tab: 'Refinance',
    part1: {
      heading: 'Unbeatable',
      headingSecondary: 'Refinance',
      text: 'Analyze a refinance with precise loan estimates, weighing the benefits of a lower rate, debt consolidation, or property upgrades.',
    },
    part2: {
      heading: 'Select Your Property With',
      headingSecondary: 'App Extensions',
      text: 'Unbeatables Loans generates precise and actionable loan estimates for your property with live-updates to interest rates. Add your property and pictures with the app or extension and choose refinance as your loan program.',
    },
  },
];

export const LandingTabsSection = () => {
  const theme = useTheme();

  const [tab, setTab] = useState(0);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '-83px',
          height: '80px',
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'flex-end',
          backgroundColor: theme.palette.mainColors.backgroundOpacity1,
        }}
      >
        <Container
          sx={{
            display: 'flex',
            gap: '22px',
          }}
        >
          {tabs.map((item, idx) => (
            <Box
              key={idx}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '12px',
                flex: '1 1',
                maxWidth: '300px',
                height: '64px',
                border: '2px solid',
                borderBottom: 'none',
                borderColor: theme.palette.greyShades.border1,
                borderRadius: '0 20px 0 0',
                cursor: 'pointer',
                backgroundColor:
                  tab === idx
                    ? theme.palette.mainColors.backgroundOpacity1
                    : 'transparent',
              }}
              onClick={() => setTab(idx)}
            >
              <i
                className={`color-secondary ${item.icon}`}
                style={{ fontSize: '16pt' }}
              />
              <Typography
                variant="h5"
                sx={{
                  display: {
                    xs: 'none',
                    md: 'inline',
                    color:
                      tab === idx
                        ? theme.palette.mainColors.secondary
                        : theme.palette.mainColors.white,
                  },
                }}
              >
                {item.tab}
              </Typography>
            </Box>
          ))}
        </Container>
      </Box>
      <Container sx={{ py: '40px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
          <MediaObject
            heading={tabs[tab].part1.heading}
            headingSecondary={tabs[tab].part1.headingSecondary}
            text={tabs[tab].part1.text}
            img={HousePlaceholderImg}
            buttons={[
              {
                children: 'Get Started',
                onClick: () => setIsSignInModalOpen(true),
              },
            ]}
          />
          <MediaObject
            heading={tabs[tab].part2.heading}
            headingSecondary={tabs[tab].part2.headingSecondary}
            direction="row-reverse"
            text={tabs[tab].part2.text}
            img={HouseGridImg}
            buttons={[
              {
                children: 'Get Started',
                onClick: () => setIsSignInModalOpen(true),
              },
              {
                children: 'Download the chrome extension',
                startIcon: <i className="fa-light fa-store" />,
                onClick: () => {
                  window.open(process.env.VITE_EXTENSION_URL, '_blank');
                },
              },
            ]}
          />
        </Box>
      </Container>
      <SigninModal
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
    </Box>
  );
};
