import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';

import { useUpdateProductMutation } from '@/apis/extended/productApi';
import { Product } from '@/types/ProductType';
import { CommonDialog } from '../Common/CommonDialog';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonNumberInput } from '../Common/CommonNumberInput';

const initialForm = {
  name: '',
  price: '',
  note: '',
  description: '',
};

type EditProductDetailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  product: Product;
};

export const EditProductDetailModal = ({
  isOpen,
  onClose,
  product,
}: EditProductDetailModalProps) => {
  const [updateProduct, { isLoading }] = useUpdateProductMutation();

  const [form, setForm] = useState({ ...initialForm });

  useEffect(() => {
    if (!product) return;
    setForm({
      name: product.name,
      price: product.price + '',
      description: product.description,
      note: product.note,
    });
  }, [product]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleUpdateProduct = () => {
    if (!form.name || !form.price || !form.description) return;

    updateProduct({
      ...form,
      price: parseFloat(form.price),
      id: product._id,
    })
      .unwrap()
      .then(() => {
        setForm({ ...initialForm });
      });
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      title="Edit Property"
      icon="fa-light fa-file-pen"
      confirmButtonText="Edit Property"
      closeButtonText="Close"
      isLoading={isLoading}
      onConfirm={handleUpdateProduct}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
        },
      }}
    >
      <Box>
        <CommonFormControl
          label="Address:"
          sx={{ mt: 1 }}
          error={form.name ? '' : 'This field is required!'}
        >
          <TextField
            placeholder="Address"
            name="name"
            fullWidth
            value={form.name}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl
          label="Price:"
          sx={{ mt: 1 }}
          error={form.price ? '' : 'This field is required!'}
        >
          <CommonNumberInput
            placeholder="Price"
            name="price"
            fullWidth
            value={form.price}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl label="Offer Note:" sx={{ mt: 1 }}>
          <TextField
            placeholder="Offer Note"
            name="note"
            fullWidth
            value={form.note}
            onChange={handleInputChange}
          />
        </CommonFormControl>
        <CommonFormControl
          label="Description:"
          sx={{ mt: 1 }}
          error={form.description ? '' : 'This field is required!'}
        >
          <TextField
            placeholder="Description"
            name="description"
            fullWidth
            multiline
            rows={6}
            value={form.description}
            onChange={handleInputChange}
          />
        </CommonFormControl>
      </Box>
    </CommonDialog>
  );
};
