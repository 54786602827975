import { Box, Typography } from '@mui/material';

import { ManageFeedback } from '@/components/Admin/ManageFeedback/ManageFeedback';

export const AdminFeedback = () => {
  return (
    <Box>
      <Typography variant="h1">User Feedback</Typography>
      <Box mt={3}>
        <ManageFeedback />
      </Box>
    </Box>
  );
};
