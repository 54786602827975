import {
  USER_LOAN_ESTIMATION_TYPE,
  USER_LOAN_PROGRAM,
  USER_OCCUPANCY,
} from '@/config/constants';
import { User } from '@/types/UserType';

const requiredDocuments = {
  [USER_LOAN_ESTIMATION_TYPE.INVESTOR]: {
    [USER_LOAN_PROGRAM.CONVENTIONAL]: {
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'Paystubs for past full month',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
        'Most Recent 2 Years Tax Return Transcripts',
      ],
    },
    [USER_LOAN_PROGRAM.CASH]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Asset statements showing sufficient assets to close',
      ],
      [USER_OCCUPANCY.SECONDARY_RESIDENCE]: [
        'Asset statements showing sufficient assets to close',
      ],
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Asset statements showing sufficient assets to close',
      ],
    },
    [USER_LOAN_PROGRAM.DSCR]: {
      [USER_OCCUPANCY.SECONDARY_RESIDENCE]: [
        'Credit report authorization',
        'Personal financial statement',
        'Background report authorization',
        'Asset atatements showing sufficient cash-to-close',
        'Real estate schedule of rental experience',
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit report authorization',
        'Personal financial statement',
        'Background report authorization',
        'Asset atatements showing sufficient cash-to-close',
        'Real estate schedule of rental experience',
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
    },
    [USER_LOAN_PROGRAM.FIX_AND_FLIP]: {
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit Report Authorization',
        'Background Report Authorization',
        'Construction Budget',
        'Real Estate Schedule of Fix and Flip Experience',
        'Asset Statements showing sufficient Assets to Close',
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
    },
    [USER_LOAN_PROGRAM.COMMERCIAL]: {
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit Report Authorization',
        'Personal Financial Statement',
        'Asset Statements showing sufficient Assets to Close',
        'Real Estate Schedule of Commercial Experience',
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
    },
  },
  [USER_LOAN_ESTIMATION_TYPE.HOME_BUYER]: {
    [USER_LOAN_PROGRAM.CONVENTIONAL]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'Paystubs for past full month',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
        'Most Recent 2 Years Tax Return Transcripts',
      ],
      [USER_OCCUPANCY.SECONDARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'Paystubs for past full month',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Real estate owned schedule',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
        'Most Recent 2 Years Tax Return Transcripts',
      ],
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'Paystubs for past full month',
        'Real estate owned schedule',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
        'Most Recent 2 Years Tax Return Transcripts',
      ],
    },
    [USER_LOAN_PROGRAM.FHA]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
      [USER_OCCUPANCY.SECONDARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
    },
    [USER_LOAN_PROGRAM.VA]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
      [USER_OCCUPANCY.SECONDARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
    },
    [USER_LOAN_PROGRAM.USDA]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
    },
  },
  [USER_LOAN_ESTIMATION_TYPE.REFINANCE]: {
    [USER_LOAN_PROGRAM.CONVENTIONAL]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'Paystubs for past full month',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        'Most Recent 2 Years Tax Return Transcripts',
      ],
      [USER_OCCUPANCY.SECONDARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'Paystubs for past full month',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        'Most Recent 2 Years Tax Return Transcripts',
      ],
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'Paystubs for past full month',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        'Most Recent 2 Years Tax Return Transcripts',
      ],
    },
    [USER_LOAN_PROGRAM.FHA]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
    },
    [USER_LOAN_PROGRAM.VA]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
    },
    [USER_LOAN_PROGRAM.USDA]: {
      [USER_OCCUPANCY.PRIMARY_RESIDENCE]: [
        'Credit Report Authorization',
        'Past two years Forms W-2 from all employers',
        'End of Year Paystubs if you earn Commission, Overtime or Bonus',
        'Paystubs for past full month',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        '2 Bank Statements for All Assets (Checking, Savings, IRA, 401(k) etc.',
      ],
    },
    [USER_LOAN_PROGRAM.DSCR]: {
      [USER_OCCUPANCY.SECONDARY_RESIDENCE]: [
        'Credit report authorization',
        'Personal financial statement',
        'Background report authorization',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        'Asset atatements showing sufficient cash-to-close',
      ],
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit report authorization',
        'Personal financial statement',
        'Background report authorization',
        'Current Mortgage Statement',
        'Homeowners Insurance Declaration Page',
        'Asset atatements showing sufficient cash-to-close',
        'Real estate schedule of rental experience',
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
    },
    [USER_LOAN_PROGRAM.COMMERCIAL]: {
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit Report Authorization',
        'Personal Financial Statement',
        'Current Mortgage Statement',
        'Insurance Declaration Page',
        'Asset Statements showing sufficient Assets to Close',
        'Real Estate Schedule of Commercial Experience',
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
    },
  },
  [USER_LOAN_ESTIMATION_TYPE.COMMERCIAL]: {
    [USER_LOAN_PROGRAM.CASH]: {
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
    },
    [USER_LOAN_PROGRAM.COMMERCIAL]: {
      [USER_OCCUPANCY.INVESTMENT_PROPERTY]: [
        'Credit Report Authorization',
        'Personal Financial Statement',
        'Asset Statements showing sufficient Assets to Close',
        'Real Estate Schedule of Commercial Experience',
        'Entity documentation (Certificate of Good Standing, Formation Documentation, IRS EIN Letter)',
        'Executed Entity Operating Agreement',
      ],
    },
  },
};

export const getRequiredDocuments = (user: User): string[] => {
  let documents = [];
  if (user.loanEstimationType && user.loanProgram && user.occupancy) {
    documents =
      // @ts-ignore
      requiredDocuments[user.loanEstimationType]?.[user.loanProgram]?.[
        user.occupancy
      ] || [];
  }
  return [`Driver's license or government issued ID`, ...documents];
};
