import { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';

import { useGetContractQuery } from '@/apis/extended/contractApi';
import { Product } from '@/types/ProductType';

type ProductContractSummaryProps = {
  product: Product;
};

export const ProductContractSummary = ({
  product,
}: ProductContractSummaryProps) => {
  const theme = useTheme();
  const { data: contract } = product.contract
    ? useGetContractQuery(product.contract)
    : { data: undefined };

  const checkItems = useMemo(() => {
    const items = [
      {
        label: 'Contract started',
        value: Boolean(contract),
      },
      {
        label: 'Buyer submitted the contract',
        value: Boolean(contract?.buyerSign?.isStored),
      },
      {
        label: 'Contract email sent to agent',
        value: Boolean(contract?.agentEmailSent),
      },
      {
        label: 'Agent received the contract',
        value: Boolean(contract?.agentReceived),
      },
      {
        label: 'Agent submitted the contract',
        value: Boolean(contract?.agentSign?.isStored),
      },
      {
        label: 'Contract email sent to seller',
        value: Boolean(contract?.sellerEmailSent),
      },
      {
        label: 'Seller received the contract',
        value: Boolean(contract?.sellerReceived),
      },
      {
        label: 'Seller submitted the contract',
        value: Boolean(contract?.sellerSign?.isStored),
      },
    ];
    return items;
  }, [contract]);

  return (
    <Card>
      <CardHeader
        title="Contract Status"
        avatar={<i className="fa-light fa-clipboard-list-check" />}
      />
      <CardContent>
        <Box>
          <Typography variant="body1">
            Track the progress of your contract here.
          </Typography>
          {checkItems.map((item, idx) => (
            <Box
              key={idx}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                ml: 1,
                mt: 2,
              }}
            >
              {item.value ? (
                <i className="fa-light fa-check color-success" />
              ) : (
                <i className="fa-light fa-close color-error" />
              )}
              <Typography
                sx={{ color: theme.palette.greyShades.grey1 }}
                variant="body2"
              >
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
