import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useVerifySecurityMutation } from '@/apis/extended/authApi';
import { Loader } from '@/components/Layout/Loader';
import { VerifyFailed } from '@/components/Verify/VerifyFailed';
import { VerifySuccess } from '@/components/Verify/VerifySuccess';
import { STATUS } from '@/types/CommonType';

export const VerifySecurityPage = () => {
  const [searchParams] = useSearchParams();
  const [verifySecurity] = useVerifySecurityMutation();

  const [status, setStatus] = useState<STATUS>(STATUS.LOADING);

  useEffect(() => {
    const uid = searchParams.get('uid');
    if (uid) {
      verifySecurity(uid)
        .unwrap()
        .then(() => setStatus(STATUS.SUCCESS))
        .catch(() => setStatus(STATUS.FAILED));
    }
  }, [searchParams]);

  if (status === STATUS.LOADING) return <Loader isFull />;
  if (status === STATUS.FAILED) return <VerifyFailed />;
  return <VerifySuccess />;
};
