import { Box, Typography, useTheme } from '@mui/material';

import { LogoSvg } from '@/assets/icons/LogoSvg';

export const ProductCardNoItems = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh',
        color: theme.palette.mainColors.white,
      }}
    >
      <LogoSvg size={40} />
      <Typography variant="h3">No properties added to this cart.</Typography>
    </Box>
  );
};
