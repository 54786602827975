import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import {
  useGetCurrentUserQuery,
  useUpdateUserLoanInfoMutation,
} from '@/apis/extended/userApi';
import { usePurchasingPowerEstimation } from '@/hooks/usePurchasingPowerEstimation';
import { formatPrice } from '@/utils/helper';
import { CommonDialog } from '../Common/CommonDialog';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonNumberInput } from '../Common/CommonNumberInput';

type IncomeInfoModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const IncomeInfoModal = ({ isOpen, onClose }: IncomeInfoModalProps) => {
  const { data, isFetching } = useGetCurrentUserQuery();
  const [updateUserLoanInfo, { isLoading: isUpdatingUser }] =
    useUpdateUserLoanInfoMutation();

  const [form, setForm] = useState({
    monthlyIncome: '',
    creditScore: '',
    downPayment: '',
    monthlyDebt: '',
    loanTerm: '',
  });

  const purchasingPower = usePurchasingPowerEstimation({
    monthlyIncome: Number(form.monthlyIncome),
    downPayment: Number(form.downPayment),
    creditScore: Number(form.creditScore),
    monthlyDebt: Number(form.monthlyDebt),
    loanTerm: Number(form.loanTerm),
  });

  useEffect(() => {
    setForm({
      monthlyIncome: (data?.loanInfo?.monthlyIncome || '0') + '',
      creditScore: (data?.loanInfo?.creditScore || '0') + '',
      downPayment: (data?.loanInfo?.downPayment || '0') + '',
      monthlyDebt: (data?.loanInfo?.monthlyDebt || '0') + '',
      loanTerm: (data?.loanInfo?.loanTerm || '') + '',
    });
  }, [data]);

  const isUpdated = useMemo(() => {
    return (
      form.monthlyIncome !== data?.loanInfo?.monthlyIncome?.toString() ||
      form.creditScore !== data?.loanInfo?.creditScore?.toString() ||
      form.downPayment !== data?.loanInfo?.downPayment?.toString() ||
      form.monthlyDebt !== data?.loanInfo?.monthlyDebt?.toString() ||
      form.loanTerm !== data?.loanInfo?.loanTerm?.toString()
    );
  }, [form, data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    updateUserLoanInfo({
      monthlyIncome: form.monthlyIncome ? parseFloat(form.monthlyIncome) : 0,
      creditScore: form.creditScore ? parseInt(form.creditScore) : 0,
      downPayment: form.creditScore ? parseFloat(form.downPayment) : 0,
      monthlyDebt: form.creditScore ? parseFloat(form.monthlyDebt) : undefined,
      loanTerm: parseInt(form.loanTerm),
    });

    if (onClose) {
      onClose();
    }
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      title="Income & Credit Info"
      icon="fa-light fa-screwdriver-wrench"
      customActions={
        <>
          <LoadingButton
            loading={isFetching || isUpdatingUser}
            disabled={!isUpdated}
            variant="contained"
            startIcon={<i className="fa-light fa-pencil" />}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </>
      }
      onClose={onClose}
    >
      <CommonFormControl label="Monthly Income:" labelWidth={150}>
        <CommonNumberInput
          placeholder="Monthly Income"
          name="monthlyIncome"
          value={form.monthlyIncome}
          onChange={handleInputChange}
        />
      </CommonFormControl>
      <CommonFormControl label="Credit Score:" sx={{ mt: 1 }} labelWidth={150}>
        <CommonNumberInput
          placeholder="Credit Score"
          name="creditScore"
          value={form.creditScore}
          onChange={handleInputChange}
        />
      </CommonFormControl>
      <CommonFormControl label="Down Payment:" sx={{ mt: 1 }} labelWidth={150}>
        <CommonNumberInput
          placeholder="Down Payment"
          name="downPayment"
          value={form.downPayment}
          onChange={handleInputChange}
        />
      </CommonFormControl>
      <CommonFormControl label="Monthly Debt:" sx={{ mt: 1 }} labelWidth={150}>
        <CommonNumberInput
          placeholder="Monthly Debt"
          name="monthlyDebt"
          value={form.monthlyDebt}
          onChange={handleInputChange}
        />
      </CommonFormControl>
      <CommonFormControl label="Loan Term:" sx={{ mt: 1 }} labelWidth={150}>
        <Select
          placeholder="Loan Term"
          name="loanTerm"
          value={form.loanTerm}
          onChange={handleSelectChange}
        >
          <MenuItem value="15">15 Years</MenuItem>
          <MenuItem value="30">30 Years</MenuItem>
        </Select>
      </CommonFormControl>
      <CommonFormControl
        label="Purchasing Power:"
        labelWidth={150}
        secondLabel={purchasingPower ? formatPrice(purchasingPower) : '-'}
        sx={{ mt: 1 }}
      />
    </CommonDialog>
  );
};
