import { useEffect, useState } from 'react';
import Placeholder from '@/assets/images/placeholder.png';

type CommonImageProps = {
  photo: string;
};

export const CommonImage = ({ photo }: CommonImageProps) => {
  const urls = [photo].filter((photo) => photo && typeof photo === 'string');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentUrl, setCurrentUrl] = useState(urls[0]);

  useEffect(() => {
    setCurrentIndex(0);
    setCurrentUrl(urls[0] || Placeholder);
  }, [photo]);

  const handleImageError = () => {
    if (currentIndex < urls.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentUrl(urls[currentIndex + 1]);
    } else {
      setCurrentUrl(Placeholder);
    }
  };

  return (
    <img
      src={currentUrl}
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      onError={handleImageError}
    />
  );
};
