import { useMemo } from 'react';
import { Chip, ChipProps } from '@mui/material';

type SecurityStatusBadgeProps = ChipProps & {
  enabled?: boolean;
  isLoading?: boolean;
};

export const SecurityStatusBadge = ({
  enabled,
  isLoading,
  ...props
}: SecurityStatusBadgeProps) => {
  const label = useMemo(() => {
    if (enabled) return 'Enabled';
    if (isLoading) return 'Loading';
    return 'Disabled';
  }, [enabled, isLoading]);

  const color = useMemo(() => {
    if (enabled) return 'success';
    if (isLoading) return 'primary';
    return 'error';
  }, [enabled, isLoading]);

  const iconClass = useMemo(() => {
    let iconClass = 'fa-light ';
    if (enabled) return (iconClass += 'fa-badge-check');
    else if (isLoading) return (iconClass += 'fa-spinner-third fa-spin');
    else iconClass += 'fa-shield-slash';
    return iconClass;
  }, [enabled, isLoading]);

  return (
    <Chip
      color={color}
      label={label}
      icon={<i className={iconClass} />}
      {...props}
    />
  );
};
