import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import { userOccupancys } from '@/config/constants';
import {
  useGetContractQuery,
  useUpdateContractBuyerMutation,
} from '@/apis/extended/contractApi';
import { parseString } from '@/utils/helper';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonNumberInput } from '../Common/CommonNumberInput';

const initialForm = {
  offerPrice: '',
  deposit1: '',
  deposit1Due: '',
  deposit2: '',
  deposit2Due: '',
  closingDate: '',
  downPayment: '',
  loanProgram: '',
  loanTerm: '',
};

type ContractDetailBuyerOfferSpecificsProps = {
  contractId: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailBuyerOfferSpecifics = ({
  contractId,
  onPrev,
  onNext,
}: ContractDetailBuyerOfferSpecificsProps) => {
  const { data: contract } = useGetContractQuery(contractId);
  const [updateContractBuyer, { isLoading }] = useUpdateContractBuyerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const offerSpecifics = contract?.offerSpecifics;
    if (!offerSpecifics) return;
    setForm({
      offerPrice: parseString(offerSpecifics.offerPrice),
      deposit1: parseString(offerSpecifics.deposit1),
      deposit1Due: parseString(offerSpecifics.deposit1Due),
      deposit2: parseString(offerSpecifics.deposit2),
      deposit2Due: parseString(offerSpecifics.deposit2Due),
      closingDate: parseString(offerSpecifics.closingDate),
      downPayment: parseString(offerSpecifics.downPayment),
      loanProgram: parseString(offerSpecifics.loanProgram),
      loanTerm: parseString(offerSpecifics.loanTerm),
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return (
      form.offerPrice &&
      form.deposit1 &&
      form.deposit1Due &&
      form.closingDate &&
      form.downPayment &&
      form.loanProgram &&
      form.loanTerm
    );
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractBuyer({
      contractId,
      offerSpecifics: {
        ...form,
        offerPrice: Number(form.offerPrice),
        deposit1: Number(form.deposit1),
        deposit2: form.deposit2 ? Number(form.deposit2) : undefined,
        downPayment: Number(form.downPayment),
        loanTerm: Number(form.loanTerm),
        deposit1Due: form.deposit1Due,
        deposit2Due: form.deposit2Due,
        closingDate: form.closingDate,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Offer Specifics"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-sparkles" />}
        />
        <CardContent>
          <CommonFormControl label="Offer Price:" labelWidth={250}>
            <CommonNumberInput
              placeholder="Offer Price"
              name="offerPrice"
              value={form.offerPrice}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl label="Deposit 1:" labelWidth={250} sx={{ mt: 2 }}>
            <TextField
              placeholder="Deposit 1"
              name="deposit1"
              value={form.deposit1}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Deposit 1 Due:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Deposit 1 Due"
              name="deposit1Due"
              type="date"
              value={form.deposit1Due}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Deposit 2 (Optional):"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Deposit 2"
              name="deposit2"
              value={form.deposit2}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Deposit 2 Due (Optional):"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Deposit 2 Due"
              name="deposit2Due"
              type="date"
              value={form.deposit2Due}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Closing Date:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Closing Date"
              name="closingDate"
              type="date"
              value={form.closingDate}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <Divider />
          <Typography sx={{ mt: 2 }} variant="h5">
            Loan Type:
          </Typography>
          <CommonFormControl
            label="Down Payment:"
            sx={{ mt: 2 }}
            labelWidth={250}
          >
            <CommonNumberInput
              placeholder="Down Payment"
              name="downPayment"
              value={form.downPayment}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Loan Program:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <Select
              placeholder="Loan Program"
              name="loanProgram"
              value={form.loanProgram}
              onChange={handleSelectChange}
            >
              {userOccupancys.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </CommonFormControl>
          <CommonFormControl label="Loan Term:" sx={{ mt: 2 }} labelWidth={250}>
            <Select
              placeholder="Loan Term"
              name="loanTerm"
              value={form.loanTerm}
              onChange={handleSelectChange}
            >
              <MenuItem value="15">15 Years</MenuItem>
              <MenuItem value="30">30 Years</MenuItem>
            </Select>
          </CommonFormControl>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
