import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';

import { AddFeedbackModal } from '@/components/ConfirmModals/AddFeedbackModal';
import { LogoTextSvg } from '@/assets/icons/LogoTextSvg';

export const Footer = () => {
  const theme = useTheme();
  const [isAddFeedbackModalOpen, setIsAddFeedbackModalOpen] = useState(false);

  return (
    <Box
      sx={{
        py: { xs: '32px', md: '80px' },
        backgroundColor: theme.palette.mainColors.background1,
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1rem',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'center', md: 'flex-start' },
          }}
        >
          <LogoTextSvg />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignIems: 'flex-start',
            }}
          >
            <Typography variant="h4">Talk to a real human being.</Typography>
            <Button
              sx={{ justifyContent: 'flex-start', mt: 2 }}
              href="tel:+17034058794"
              variant="text"
              startIcon={<i className="fa-light fa-phone" />}
            >
              +1 (703) 405-8794
            </Button>
            <Button
              sx={{ justifyContent: 'flex-start' }}
              href="mailto:support@unbeatableloans.com"
              variant="text"
              startIcon={<i className="fa-light fa-envelope" />}
            >
              support@unbeatableloans.com
            </Button>
          </Box>
        </Box>
        <Divider
          sx={{ my: '1rem', borderColor: theme.palette.greyShades.border1 }}
          orientation="horizontal"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: '4px 8px',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: '4px 8px',
            }}
          >
            {/* <Button variant="text">Terms of Service</Button> */}
            <Button variant="text" href="/support">
              Support
            </Button>
            <Button variant="text" href="/privacy-policy">
              Privacy Policy
            </Button>
          </Box>
          <Button
            variant="text"
            onClick={() => setIsAddFeedbackModalOpen(true)}
          >
            Feedback
          </Button>
        </Box>
      </Container>
      <AddFeedbackModal
        isOpen={isAddFeedbackModalOpen}
        onClose={() => setIsAddFeedbackModalOpen(false)}
      />
    </Box>
  );
};
