import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

type CommonNumberInputProps = TextFieldProps;

export const CommonNumberInput = (props: CommonNumberInputProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        ...props?.InputProps,
      }}
    />
  );
};
