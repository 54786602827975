import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';

import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailOtherCostsProps = {
  product: Product;
};

export const ProductDetailOtherCosts = ({
  product,
}: ProductDetailOtherCostsProps) => {
  const { estimation } = useEstimation(product);

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Other Costs"
        avatar={<i className="fa-light fa-money-bill" />}
      />
      <CardContent>
        <Typography variant="h5" fontWeight={700}>
          Taxes and other Government Fees
        </Typography>
        <CommonFormControl
          label="Title: Recording Deed Fee:"
          secondLabel={formatPrice(estimation.recordingDeedBase)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Title: Recording Title Fee:"
          secondLabel={formatPrice(estimation.recordingTitle)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="County Recording Fees:"
          secondLabel={formatPrice(estimation.countyRecordingFees)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="State Recording Fees:"
          secondLabel={formatPrice(estimation.stateRecordingFees)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Transfer Taxes:"
          secondLabel={formatPrice(estimation.transferTaxes)}
          inLine
          labelWidth={270}
        />
        <Divider />
        <Typography variant="h5" fontWeight={700}>
          Prepaids
        </Typography>
        <CommonFormControl
          label="Homeowners Insurance Premium:"
          secondLabel={formatPrice(estimation.prepaidsHomeownersInsurance)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Prepaid Interest:"
          secondLabel={formatPrice(estimation.prepaidInterest)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Prepaid Property Taxes:"
          secondLabel={formatPrice(estimation.prepaidsPropertyTaxes)}
          inLine
          labelWidth={270}
        />
        <Divider />
        <Typography variant="h5" fontWeight={700}>
          Initial Escrow Payments at Closing
        </Typography>
        <CommonFormControl
          label="for Homeowners Insurance (3 months):"
          secondLabel={formatPrice(estimation.initialEscrowHOI)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="for Mortgage Insurance (0 Months):"
          secondLabel={formatPrice(estimation.initialEscrowMortgageInsurance)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="for Property Taxes (6 months):"
          secondLabel={formatPrice(estimation.initialEscrowTaxes)}
          inLine
          labelWidth={270}
        />
        <Divider />
        <Typography variant="h5" fontWeight={700}>
          Discretionary
        </Typography>
        <CommonFormControl
          label="Home Inspection Fee:"
          secondLabel={formatPrice(estimation.homeInspectionFeeBase)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Pest Inspection Fee:"
          secondLabel={formatPrice(estimation.pestInspectionFee)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Unbeatable Earnest Deposit:"
          secondLabel={formatPrice(estimation.earnestMoneyDeposit)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="(Optional)Owners Title Insurance:"
          secondLabel={formatPrice(estimation.optionalOwnersTitleInsuranceBase)}
          inLine
          labelWidth={270}
        />
        <Divider />
        <Typography variant="h5" fontWeight={700}>
          Total Other Costs
        </Typography>
        <CommonFormControl
          label="Total Discretionary, Initial Escrow, Prepaid, and Government Fees:"
          secondLabel={formatPrice(estimation.otherCosts)}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
