import { ReactNode } from 'react';
import {
  Box,
  Container,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { HEADER_HEIGHT, Z_INDEX } from '@/config/constants';

const NavIconButton = ({
  url,
  children,
}: {
  url: string;
  children: ReactNode;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === url;

  return (
    <IconButton
      onClick={() => navigate(url)}
      sx={{
        color: isActive
          ? theme.palette.mainColors.primary
          : theme.palette.greyShades.grey1,
        position: 'relative',
        '&::after': {
          content: isActive ? '""' : 'none',
          position: 'absolute',
          left: 0,
          bottom: '0px',
          width: '100%',
          height: '2px',
          backgroundColor: theme.palette.mainColors.primary,
        },
      }}
    >
      {children}
    </IconButton>
  );
};

export const AdminHeader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.mainColors.background1,
        boxShadow: `0 0 4px 1px ${theme.palette.mainColors.background}`,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: Z_INDEX.HEADER,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          height: `${HEADER_HEIGHT}px`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: '12px', md: '36px' },
          }}
        >
          <Typography variant="h3">ADMIN</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <NavIconButton url="/admin/user">
              <i className="fa-light fa-users" />
            </NavIconButton>
            <NavIconButton url="/admin/product">
              <i className="fa-light fa-house-building" />
            </NavIconButton>
            <NavIconButton url="/admin/agent">
              <i className="fa-light fa-people-arrows" />
            </NavIconButton>
            <NavIconButton url="/admin/email">
              <i className="fa-light fa-envelopes" />
            </NavIconButton>
            <NavIconButton url="/admin/feedback">
              <i className="fa-light fa-comments" />
            </NavIconButton>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <NavIconButton url="/main">
            <i className="fa-light fa-turn-right" />
          </NavIconButton>
        </Box>
      </Container>
    </Box>
  );
};
