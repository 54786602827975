import { Box, Typography } from '@mui/material';

import { ManageProductTable } from '@/components/Admin/ManageProduct/ManageProductTable';

export const AdminProduct = () => {
  return (
    <Box>
      <Typography variant="h1">Manage Properties</Typography>
      <Box mt={3}>
        <ManageProductTable />
      </Box>
    </Box>
  );
};
