import { useRef } from 'react';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';

type DataGridSelectProps = GridRenderCellParams<any, string> & {
  items: {
    label: string;
    value: string;
  }[];
};

export const DataGridSelect = (props: DataGridSelectProps) => {
  const { id, value, field, items } = props;
  const apiRef = useGridApiContext();
  const ref = useRef<HTMLElement>(null);

  const handleChange = (e: SelectChangeEvent<string>) => {
    apiRef.current.setEditCellValue({ id, field, value: e.target.value });
  };

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%', px: 1 }}
    >
      <Select ref={ref} value={value || ''} onChange={handleChange}>
        {items.map((item, idx) => (
          <MenuItem key={idx} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
