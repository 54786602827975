import { Box, Divider } from '@mui/material';

import {
  USER_APPROVE_STATUS,
  USER_QUALIFY_STATUS,
  userLoanEstimationTypeMap,
  userLoanProgramMap,
  userOccupancyMap,
} from '@/config/constants';
import { formatNumber, formatPrice } from '@/utils/helper';
import { User } from '@/types/UserType';
import { CommonDialog } from '../Common/CommonDialog';
import { CommonFormControl } from '../Common/CommonFormControl';

type ViewUserModalProps = {
  user: User;
  isOpen: boolean;
  onClose: () => void;
};

export const ViewUserModal = ({
  user,
  isOpen,
  onClose,
}: ViewUserModalProps) => {
  if (!user) return null;

  return (
    <CommonDialog
      isOpen={isOpen}
      title={'User Detail'}
      icon="fa-light fa-eye"
      closeButtonText="Close"
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '100%',
        },
      }}
    >
      <Box sx={{ width: '450px' }}>
        <CommonFormControl
          label="Name:"
          secondLabel={user.name || '-'}
          labelWidth={180}
        />
        <CommonFormControl
          label="Email:"
          secondLabel={user.email || '-'}
          labelWidth={180}
        />
        <CommonFormControl
          label="Phone:"
          secondLabel={user.phone || '-'}
          labelWidth={180}
        />
        <CommonFormControl
          label="Role:"
          secondLabel={user.role || '-'}
          labelWidth={180}
        />
        <CommonFormControl
          label="Security:"
          secondLabel={user.securityEnabled ? 'Enabled' : 'Disabled'}
          labelWidth={180}
        />
        <CommonFormControl
          label="Qualifid Status:"
          secondLabel={
            user.qualifyStatus === USER_QUALIFY_STATUS.QUALIFY ? 'Yes' : 'No'
          }
          labelWidth={180}
        />
        <CommonFormControl
          label="Approved Status:"
          secondLabel={
            user.approveStatus === USER_APPROVE_STATUS.APPROVED ? 'Yes' : 'No'
          }
          labelWidth={180}
        />
        <CommonFormControl
          label="Approval Amount:"
          secondLabel={formatPrice(user.approvalAmount)}
          labelWidth={180}
        />
        <Divider />
        <CommonFormControl
          label="Monthly Income:"
          secondLabel={formatPrice(user.loanInfo?.monthlyIncome)}
          labelWidth={180}
        />
        <CommonFormControl
          label="Credit Score:"
          secondLabel={formatNumber(user.loanInfo?.creditScore)}
          labelWidth={180}
        />
        <CommonFormControl
          label="Down Payment:"
          secondLabel={formatPrice(user.loanInfo?.downPayment)}
          labelWidth={180}
        />
        <CommonFormControl
          label="Monthly Debt:"
          secondLabel={formatPrice(user.loanInfo?.monthlyDebt)}
          labelWidth={180}
        />
        <CommonFormControl
          label="Loan Term:"
          secondLabel={
            user.loanInfo?.loanTerm ? user.loanInfo?.loanTerm + ' Years' : '-'
          }
          labelWidth={180}
        />
        <CommonFormControl
          label="Occupancy:"
          secondLabel={userOccupancyMap[user.occupancy!] || '-'}
          labelWidth={180}
        />
        <CommonFormControl
          label="Loan Estimation:"
          secondLabel={
            userLoanEstimationTypeMap[user.loanEstimationType!] || '-'
          }
          labelWidth={180}
        />
        <CommonFormControl
          label="Loan Program:"
          secondLabel={userLoanProgramMap[user.loanProgram!] || '-'}
          labelWidth={180}
        />
      </Box>
    </CommonDialog>
  );
};
