import { AddFeedbackRequest, GetSignedUrlRequest } from '@/types/GlobalType';
import { rtkApi } from '../rtkApi';

export const globalApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getInterestRate: builder.query<number, void>({
      query: () => ({ url: `/global/interest-rate` }),
    }),
    getSignedUrl: builder.mutation<string, GetSignedUrlRequest>({
      query: (payload) => ({
        url: `/global/storage/signed-url`,
        params: payload,
      }),
    }),
    addFeedback: builder.mutation<void, AddFeedbackRequest>({
      query: (payload) => ({
        url: `/global/feedback`,
        method: 'POST',
        data: payload,
      }),
    }),
  }),
});

export const {
  useGetInterestRateQuery,
  useGetSignedUrlMutation,
  useAddFeedbackMutation,
} = globalApi;
