import { Card, CardContent, CardHeader } from '@mui/material';

import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailSubtotalClosingCostsProps = {
  product: Product;
};

export const ProductDetailSubtotalClosingCosts = ({
  product,
}: ProductDetailSubtotalClosingCostsProps) => {
  const { estimation } = useEstimation(product);

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Subtotal Closing Costs"
        avatar={<i className="fa-light fa-list" />}
      />
      <CardContent>
        <CommonFormControl
          label="Closing Costs:"
          secondLabel={formatPrice(estimation.closingCosts)}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
