import { rtkApi } from '@/apis/rtkApi';
import {
  GetProductsByAdminRequest,
  GetProductsByAdminResponse,
} from '@/types/ProductType';

export const productApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductsByAdmin: builder.query<
      GetProductsByAdminResponse,
      GetProductsByAdminRequest
    >({
      query: (payload) => ({ url: `/admin/product`, params: payload }),
      providesTags: ['Admin Product'],
    }),
  }),
});

export const { useGetProductsByAdminQuery } = productApi;
