import { useEffect, useState } from 'react';

import { useGetInterestRateQuery } from '@/apis/extended/globalApi';
import { calculateClosingDisclosure } from '@/utils/calculator';
import { Product, ProductEsitmation } from '@/types/ProductType';

export const useEstimation = (product: Product) => {
  const { data: interestRate } = useGetInterestRateQuery();

  const [estimation, setEstimation] = useState<ProductEsitmation | null>(null);

  useEffect(() => {
    if (!product?.user) {
      setEstimation(null);
      return;
    }

    const estimation = calculateClosingDisclosure({
      type: product.user?.loanProgram,
      listPrice: product.price,
      offerPrice: product?.loanInfo?.offerPrice,
      hoaFees: product?.loanInfo?.hoaFees,
      commission: product?.loanInfo?.commission,
      zipCode: product?.loanInfo?.zipCode,
      creditScore: product.user.loanInfo?.creditScore,
      downPayment: product.user.loanInfo?.downPayment,
      loanTerm: product.user.loanInfo?.loanTerm,
      marketInterestRate: interestRate,
      rent: product.loanInfo?.rent,
      experience: product.loanInfo?.experience,
      rehab: product.loanInfo?.rehab,
      arv: product.loanInfo?.arv,
    });

    setEstimation(estimation);
  }, [product, interestRate]);

  return { estimation };
};
