import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useEstimation } from '@/hooks/useEstimation';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';
import { CommonFormControl } from '../Common/CommonFormControl';
import { ProductDetailExploreOfferButton } from '../ProductDetail/ProductDetailExploreOfferButton';

type ProductQualifiedSummaryProps = {
  product: Product;
};

export const ProductQualifiedSummary = ({
  product,
}: ProductQualifiedSummaryProps) => {
  const navigate = useNavigate();

  const { estimation } = useEstimation(product);

  if (!estimation) return null;

  const getPriceOrDefault = (price?: number) => {
    if (price === undefined || price === null) return '-';
    return formatPrice(price);
  };

  return (
    <Card>
      <CardHeader
        title="Estimation Summary"
        avatar={<i className="fa-light fa-synagogue" />}
      />
      <CardContent>
        <CommonFormControl
          label="List Price:"
          secondLabel={formatPrice(estimation.listPrice)}
          inLine
          labelWidth={160}
        />
        <CommonFormControl
          label="Offer Price:"
          secondLabel={getPriceOrDefault(estimation.offerPrice)}
          inLine
          labelWidth={160}
        />
        <CommonFormControl
          label="Down Payment:"
          secondLabel={getPriceOrDefault(estimation.downPayment)}
          inLine
          labelWidth={160}
        />
        <CommonFormControl
          label="Loan Amount:"
          secondLabel={getPriceOrDefault(estimation.loanAmount)}
          inLine
          labelWidth={160}
        />
        <CommonFormControl
          label="Cash To Close:"
          secondLabel={getPriceOrDefault(estimation.cashToClose)}
          inLine
          labelWidth={160}
        />
        <Box sx={{ mt: 1, display: 'flex', gap: 2 }}>
          <Button
            startIcon={<i className="fa-light fa-eye" />}
            onClick={() => navigate(`/product/${product._id}`)}
          >
            View Detail
          </Button>
          <ProductDetailExploreOfferButton product={product} />
        </Box>
      </CardContent>
    </Card>
  );
};
