import { useEffect, useMemo, useState } from 'react';
import { Box, Step, StepLabel, Stepper, Tab, Tabs } from '@mui/material';

import { CONTRACT_BUYER_STEP, contractBuyerSteps } from '@/config/constants';
import { useGetContractQuery } from '@/apis/extended/contractApi';
import { Loader } from '../Layout/Loader';
import { ContractDetailBuyerContingency } from './ContractDetailBuyerContingency';
import { ContractDetailBuyerInfo } from './ContractDetailBuyerInfo';
import { ContractDetailBuyerInspection } from './ContractDetailBuyerInspection';
import { ContractDetailBuyerOfferSpecifics } from './ContractDetailBuyerOfferSpecifics';
import { ContractDetailBuyerReview } from './ContractDetailBuyerReview';
import { ContractDetailBuyerSuccess } from './ContractDetailBuyerSuccess';
import { ContractDetailBuyerTimeline } from './ContractDetailBuyerTimeline';
import { ContractDetailBuyerTitleCompany } from './ContractDetailBuyerTitleCompany';

type ContractDetailBuyerProps = {
  contractId: string;
};

export const ContractDetailBuyer = ({
  contractId,
}: ContractDetailBuyerProps) => {
  const { data: contract, isLoading } = useGetContractQuery(contractId);

  const [step, setStep] = useState(-1);
  const [offerTab, setOfferTab] = useState(0);
  const [thirdPartyTab, setThirdPartyTab] = useState(0);

  const currentStep = useMemo(() => {
    if (!contract) return 0;

    if (contract.buyerSign?.isStored) return CONTRACT_BUYER_STEP.COMPLETED;
    if (contract.timeline?.isStored) return CONTRACT_BUYER_STEP.REVIEW;
    if (contract.thirdPartyInspection?.isStored)
      return CONTRACT_BUYER_STEP.TIMELINE;
    if (
      contract.thirdPartyTitleSettlement?.isStored ||
      contract.contingency?.isStored
    )
      return CONTRACT_BUYER_STEP.THIRD_PARTY;
    if (contract.offerSpecifics?.isStored || contract.buyerInfo?.isStored) {
      return CONTRACT_BUYER_STEP.OFFER_INFO;
    }
    return CONTRACT_BUYER_STEP.BUYER_INFO;
  }, [contract]);

  const currentThirdPartyTab = useMemo(() => {
    if (contract?.thirdPartyTitleSettlement?.isStored) return 1;
    return 0;
  }, [contract]);
  const currentOfferTab = useMemo(() => {
    if (contract?.offerSpecifics.isStored) return 1;
    return 0;
  }, [contract]);

  useEffect(() => {
    if (!contract || step !== -1) return;

    setStep(currentStep);
    setThirdPartyTab(currentThirdPartyTab);
    setOfferTab(currentOfferTab);
  }, [contract, currentStep, currentThirdPartyTab, currentOfferTab]);

  if (isLoading) return <Loader isFull />;
  if (!contract) return null;

  const handleClickStep = (step: number) => {
    if (step > currentStep) return;
    setStep(step);
  };

  return (
    <Box>
      <Stepper activeStep={step} alternativeLabel>
        {contractBuyerSteps.map((label, idx) => (
          <Step
            key={label}
            completed={idx < currentStep}
            sx={{ cursor: idx <= currentStep ? 'pointer' : 'unset' }}
            disabled={idx > currentStep}
            onClick={() => handleClickStep(idx)}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 8, mx: 'auto', maxWidth: '600px' }}>
        {step === CONTRACT_BUYER_STEP.BUYER_INFO && (
          <ContractDetailBuyerInfo
            contractId={contractId}
            onNext={() => setStep(CONTRACT_BUYER_STEP.OFFER_INFO)}
          />
        )}
        {step === CONTRACT_BUYER_STEP.OFFER_INFO && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Tabs value={offerTab}>
                <Tab label="Offer Specifics" />
                <Tab label="Contingencies" />
              </Tabs>
            </Box>
            {offerTab === 0 && (
              <ContractDetailBuyerOfferSpecifics
                contractId={contractId}
                onPrev={() => setStep(CONTRACT_BUYER_STEP.BUYER_INFO)}
                onNext={() => setOfferTab(1)}
              />
            )}
            {offerTab === 1 && (
              <ContractDetailBuyerContingency
                contractId={contractId}
                onPrev={() => setOfferTab(0)}
                onNext={() => {
                  setStep(CONTRACT_BUYER_STEP.THIRD_PARTY);
                  setThirdPartyTab(0);
                }}
              />
            )}
          </>
        )}
        {step === CONTRACT_BUYER_STEP.THIRD_PARTY && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Tabs value={thirdPartyTab}>
                <Tab label="Title/Settlement" />
                <Tab label="Inspection" />
              </Tabs>
            </Box>
            {thirdPartyTab === 0 && (
              <ContractDetailBuyerTitleCompany
                contractId={contractId}
                onPrev={() => {
                  setStep(CONTRACT_BUYER_STEP.OFFER_INFO);
                  setOfferTab(1);
                }}
                onNext={() => setThirdPartyTab(1)}
              />
            )}
            {thirdPartyTab === 1 && (
              <ContractDetailBuyerInspection
                contractId={contractId}
                onPrev={() => setThirdPartyTab(0)}
                onNext={() => setStep(CONTRACT_BUYER_STEP.TIMELINE)}
              />
            )}
          </>
        )}
        {step === CONTRACT_BUYER_STEP.TIMELINE && (
          <ContractDetailBuyerTimeline
            contractId={contractId}
            onPrev={() => {
              setStep(CONTRACT_BUYER_STEP.THIRD_PARTY);
              setThirdPartyTab(1);
            }}
            onNext={() => setStep(CONTRACT_BUYER_STEP.REVIEW)}
          />
        )}
        {step >= CONTRACT_BUYER_STEP.REVIEW &&
          (currentStep === CONTRACT_BUYER_STEP.COMPLETED ? (
            <ContractDetailBuyerSuccess />
          ) : (
            <ContractDetailBuyerReview
              contractId={contractId}
              onPrev={() => setStep(CONTRACT_BUYER_STEP.TIMELINE)}
              onNext={() => {}}
            />
          ))}
      </Box>
    </Box>
  );
};
