import { ProductDetail } from '@/components/ProductDetail/ProductDetail';
import { useParams } from 'react-router-dom';

export const ProductDetailPage = () => {
  const { productId } = useParams();

  if (!productId) return null;

  return <ProductDetail productId={productId} />;
};
