import { Box, Container, Grid, useTheme } from '@mui/material';

import { useAppLayout } from '@/hooks/useAppLayout';
import { LogoSvg } from '@/assets/icons/LogoSvg';
import BackgroundImg from '@/assets/images/Landing/hero-background.png';
import { HeroText } from './HeroSection/HeroText';

export const LandingHeroSection = () => {
  const theme = useTheme();
  const { headerHeight } = useAppLayout();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        pt: '40px',
        pb: '100px',
        minHeight: `calc(100vh - ${headerHeight}px)`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflow: 'hidden',
          pointerEvents: 'none',
          borderBottom: '2px solid',
          borderColor: theme.palette.greyShades.border1,
          opacity: 0.3,
        }}
      >
        <img
          alt="hero background"
          src={BackgroundImg}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Box>
      <Container sx={{ zIndex: 1 }}>
        <Grid container spacing={5} direction="row-reverse" alignItems="center">
          <Grid item xs={12} md={5}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LogoSvg size={200} />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <HeroText />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
