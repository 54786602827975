import { Fragment, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Divider, useTheme } from '@mui/material';

import { PRODUCT_COUNT_PER_PAGE } from '@/config/constants';
import { useGetProductsQuery } from '@/apis/extended/productApi';
import { useGetCurrentUserQuery } from '@/apis/extended/userApi';
import { useQualifiedProducts } from '@/hooks/useQualifiedProducts';
import { IncomeInfoModal } from '../ConfirmModals/IncomeInfoModal';
import { Loader } from '../Layout/Loader';
import { ProductCard } from '../ProductCard/ProductCard';
import { ProductCardNoItems } from '../ProductCard/ProductCardNoItems';
import { ProductQualifiedSummary } from './ProductQualifiedSummary';

export const ProductQualifiedCardList = () => {
  const theme = useTheme();

  const [offset, setOffset] = useState(0);
  const { data: user } = useGetCurrentUserQuery();
  const { data, isLoading, isFetching } = useGetProductsQuery({
    limit: PRODUCT_COUNT_PER_PAGE,
    offset,
  });
  const qualifiedProducts = useQualifiedProducts(data?.products);

  if (isLoading || !user || !data?.products) return <Loader isFull />;
  if (!user.loanInfo?.monthlyIncome) return <IncomeInfoModal isOpen={true} />;

  if (!qualifiedProducts.length) return <ProductCardNoItems />;

  return (
    <Container sx={{ py: '40px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          margin: 'auto',
          maxWidth: { xs: '400px', md: '832px' },
        }}
      >
        {qualifiedProducts.map((product, idx) => (
          <Fragment key={idx}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: '32px',
              }}
            >
              <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                <ProductCard product={product} />
              </Box>
              <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                <ProductQualifiedSummary product={product} />
              </Box>
            </Box>
            {idx < qualifiedProducts.length - 1 && (
              <Divider sx={{ borderColor: theme.palette.greyShades.border1 }} />
            )}
          </Fragment>
        ))}
      </Box>
      {!data.isEnd && (
        <LoadingButton
          variant="contained"
          size="large"
          fullWidth
          sx={{ mt: 4 }}
          loading={isFetching}
          endIcon={<i className="fa-light fa-plus" />}
          onClick={() => setOffset(offset + PRODUCT_COUNT_PER_PAGE)}
        >
          Load More
        </LoadingButton>
      )}
    </Container>
  );
};
