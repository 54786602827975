import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Container } from '@mui/material';

import { PRODUCT_COUNT_PER_PAGE } from '@/config/constants';
import { useGetProductsQuery } from '@/apis/extended/productApi';
import { Loader } from '../Layout/Loader';
import { ProductCard } from './ProductCard';
import { ProductCardNoItemsWithGuide } from './ProductCardNoItemsWithGuide';

export const ProductCardList = () => {
  const [offset, setOffset] = useState(0);
  const { data, isLoading, isFetching } = useGetProductsQuery({
    limit: PRODUCT_COUNT_PER_PAGE,
    offset,
  });

  if (isLoading || !data?.products) return <Loader isFull />;
  if (!data.products.length) return <ProductCardNoItemsWithGuide />;

  return (
    <Container sx={{ py: '40px' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: `repeat(1, minmax(200px, 1fr))`,
            md: `repeat(2, minmax(200px, 1fr))`,
            lg: `repeat(3, minmax(200px, 1fr))`,
          },
          gap: '32px',
          maxWidth: { xs: '400px', md: '832px', lg: 'unset' },
          margin: 'auto',
        }}
      >
        {data.products.map((product, idx) => (
          <ProductCard key={idx} product={product} />
        ))}
      </Box>
      {!data.isEnd && (
        <LoadingButton
          variant="contained"
          size="large"
          fullWidth
          sx={{ mt: 4 }}
          loading={isFetching}
          endIcon={<i className="fa-light fa-plus" />}
          onClick={() => setOffset(offset + PRODUCT_COUNT_PER_PAGE)}
        >
          Load More
        </LoadingButton>
      )}
    </Container>
  );
};
