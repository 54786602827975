import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useAppLayout } from '@/hooks/useAppLayout';
import { AppHeader } from './AppHeader';
import { Footer } from './Footer';

export const AppLayout = () => {
  const { headerHeight } = useAppLayout();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <AppHeader />
      <Box sx={{ minHeight: '70vh', mt: `${headerHeight}px` }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};
