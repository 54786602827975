import { USER_LOAN_PROGRAM } from '@/config/constants';
import { calculateConventionalClosingDisclosure } from './Conventional';
import { calculateDefaultClosingDisclosure } from './default';
import { calculateDSCRClosingDisclosure } from './DSCR';
import { calculateFHAClosingDisclosure } from './FHA';
import { calculateFixAndFlipClosingDisclosure } from './FixAndFlip';
import { calculateUSDAClosingDisclosure } from './USDA';
import { calculateVAClosingDisclosure } from './VA';

export const chooseMarketInterestRate = (
  interestRate: number,
  creditScore: number
) => {
  let marketInterestRate = interestRate;

  if (creditScore >= 760) {
    marketInterestRate -= 0.25;
  } else if (creditScore >= 740) {
    marketInterestRate -= 0.125;
  } else if (creditScore >= 720) {
    marketInterestRate -= 0.0625;
  } else if (creditScore >= 700) {
    // No change in interest rate
  } else if (creditScore >= 680) {
    marketInterestRate += 0.125;
  } else if (creditScore >= 660) {
    marketInterestRate += 0.25;
  } else if (creditScore >= 640) {
    marketInterestRate += 0.375;
  } else if (creditScore >= 620) {
    marketInterestRate += 0.5;
  } else if (creditScore >= 600) {
    marketInterestRate += 0.625;
  } else if (creditScore >= 580) {
    marketInterestRate += 0.75;
  } else if (creditScore >= 560) {
    marketInterestRate += 0.875;
  } else if (creditScore >= 540) {
    marketInterestRate += 1;
  } else if (creditScore >= 520) {
    marketInterestRate += 1.125;
  } else if (creditScore <= 500) {
    marketInterestRate += 1.25;
  } else {
    marketInterestRate += 2.5;
  }
  return marketInterestRate;
};

export const calculateTaxFactor = (zipcode: number) => {
  let taxFactor = 1; // Default tax factor

  if (zipcode >= 35000 && zipcode <= 36999) {
    taxFactor = 0.41;
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    taxFactor = 1.19;
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    taxFactor = 0.64;
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    taxFactor = 0.63;
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    taxFactor = 0.75;
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    taxFactor = 0.54;
  } else if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    taxFactor = 1.63;
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    taxFactor = 0.53;
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    taxFactor = 0.89;
  } else if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    taxFactor = 0.91;
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    taxFactor = 0.29;
  } else if (zipcode >= 83200 && zipcode <= 83999 && zipcode != 83414) {
    taxFactor = 0.76;
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    taxFactor = 2.3;
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    taxFactor = 0.87;
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    taxFactor = 1.53;
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    taxFactor = 1.4;
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    taxFactor = 0.84;
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    taxFactor = 0.51;
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    taxFactor = 1.3;
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    taxFactor = 1.0;
  } else if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode == 5501 ||
    zipcode == 5544
  ) {
    taxFactor = 1.16;
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    taxFactor = 1.53;
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    taxFactor = 1.05;
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    taxFactor = 0.62;
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    taxFactor = 1.02;
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    taxFactor = 0.68;
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    taxFactor = 0.86;
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    taxFactor = 1.01;
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    taxFactor = 1.77;
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    taxFactor = 0.7;
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    taxFactor = 2.2;
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    taxFactor = 2.44;
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    taxFactor = 0.67;
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode == 6390 ||
    zipcode == 501 ||
    zipcode == 544
  ) {
    taxFactor = 1.58;
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    taxFactor = 1.57;
  } else if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    taxFactor = 0.88;
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    taxFactor = 1.07;
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    taxFactor = 1.55;
  } else if (zipcode >= 300 && zipcode <= 999) {
    taxFactor = 0.6;
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    taxFactor = 1.53;
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    taxFactor = 0.57;
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    taxFactor = 1.22;
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    taxFactor = 0.74;
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    taxFactor = 1.81;
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    taxFactor = 0.63;
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    taxFactor = 1.78;
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode == 20598
  ) {
    taxFactor = 0.8;
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    taxFactor = 0.85;
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    taxFactor = 1.06;
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    taxFactor = 0.59;
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    taxFactor = 1.68;
  } else if ((zipcode >= 82000 && zipcode <= 83199) || zipcode == 83414) {
    taxFactor = 0.61;
  } else {
    taxFactor = 1;
  }
  return taxFactor;
};

export const calculateTransferTaxFactor = (zipcode: number) => {
  let transferTaxFactor = 1; // Default tax factor

  if (zipcode >= 35000 && zipcode <= 36999) {
    transferTaxFactor = 0.001; // Alabama
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    transferTaxFactor = 0; // Alaska
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    transferTaxFactor = 0.004; // Arizona
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    transferTaxFactor = 0.0033; // Arkansas
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    transferTaxFactor = 0.007; // California
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    transferTaxFactor = 0.002; // Colorado
  } else if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    transferTaxFactor = 0.00635; // Connecticut
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    transferTaxFactor = 0.00425; // Delaware
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    transferTaxFactor = 0.007; // Florida
  } else if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    transferTaxFactor = 0.001; // Georgia
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    transferTaxFactor = 0.0017; // Hawaii
  } else if (zipcode >= 83200 && zipcode <= 83999 && zipcode != 83414) {
    transferTaxFactor = 0.002; // Idaho
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    transferTaxFactor = 0.0025; // Illinois
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    transferTaxFactor = 0.002; // Indiana
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    transferTaxFactor = 0.00185; // Iowa
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    transferTaxFactor = 0.00333; // Kansas
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    transferTaxFactor = 0.00145; // Kentucky
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    transferTaxFactor = 0.005; // Louisiana
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    transferTaxFactor = 0.014; // Maine
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    transferTaxFactor = 0.011; // Maryland
  } else if (zipcode >= 1000 && zipcode <= 2799) {
    transferTaxFactor = 0.0023; // Massachusetts
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    transferTaxFactor = 0.006; // Michigan
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    transferTaxFactor = 0.0034; // Minnesota
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    transferTaxFactor = 0.003; // Mississippi
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    transferTaxFactor = 0.00133; // Missouri
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    transferTaxFactor = 0.007; // Montana
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    transferTaxFactor = 0.002; // North Carolina
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    transferTaxFactor = 0.002; // North Dakota
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    transferTaxFactor = 0.0025; // Nebraska
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    transferTaxFactor = 0.0071; // Nevada
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    transferTaxFactor = 0.014; // New Hampshire
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    transferTaxFactor = 0.0045; // New Jersey
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    transferTaxFactor = 0.011; // New Mexico
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode == 6390 ||
    zipcode == 501 ||
    zipcode == 544
  ) {
    transferTaxFactor = 0.0043; // New York
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    transferTaxFactor = 0.00195; // Ohio
  } else if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    transferTaxFactor = 0.003; // Oklahoma
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    transferTaxFactor = 0.0115; // Oregon
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    transferTaxFactor = 0.01; // Pennsylvania
  } else if (zipcode >= 300 && zipcode <= 999) {
    transferTaxFactor = 0.0057; // Puerto Rico
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    transferTaxFactor = 0.0042; // Rhode Island
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    transferTaxFactor = 0.00325; // South Carolina
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    transferTaxFactor = 0.002; // South Dakota
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    transferTaxFactor = 0.0375; // Tennessee
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    transferTaxFactor = 0.015; // Texas
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    transferTaxFactor = 0.00765; // Utah
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    transferTaxFactor = 0.01; // Vermont
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode == 20598
  ) {
    transferTaxFactor = 0.01; // Virginia
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    transferTaxFactor = 0.00456; // Washington DC
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    transferTaxFactor = 0.01; // Washington
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    transferTaxFactor = 0.00655; // West Virginia
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    transferTaxFactor = 0.003; // Wisconsin
  } else if ((zipcode >= 82000 && zipcode <= 83199) || zipcode == 83414) {
    transferTaxFactor = 0.01; // Wyoming
  } else {
    transferTaxFactor = 1;
  }
  return transferTaxFactor;
};

export type CalculatePurchasingPowerProps = {
  monthlyIncome: number;
  creditScore: number;
  downPayment: number;
  monthlyDebt: number;
  loanTerm?: number;
  interestRate?: number;
};

export const calculatePurchasingPower = ({
  monthlyIncome,
  creditScore,
  downPayment,
  monthlyDebt,
  loanTerm = 30,
  interestRate = 7.5,
}: CalculatePurchasingPowerProps) => {
  const maxMonthlyDebt = monthlyDebt || 0;
  const maxIncomeForDebt = monthlyIncome / 2 - maxMonthlyDebt;
  const maxInterestRate = chooseMarketInterestRate(interestRate, creditScore);
  const maxLoanAmount =
    (maxIncomeForDebt / (maxInterestRate / 100 / 12)) *
    (1 - Math.pow(1 + maxInterestRate / 100 / 12, -loanTerm * 12));

  const purchasingPower = maxLoanAmount + downPayment;

  return purchasingPower;
};

export type CalculateClosingDisclosureProps = {
  type?: USER_LOAN_PROGRAM;
  offerPrice?: number;
  listPrice: number;
  downPayment?: number;
  zipCode?: number;
  hoaFees?: number;
  commission?: number;
  marketInterestRate?: number;
  creditScore?: number;
  loanTerm?: number;

  rent?: number;
  experience?: number;
  rehab?: number;
  arv?: number;
};

export const calculateClosingDisclosure = ({
  type,
  ...props
}: CalculateClosingDisclosureProps) => {
  let closingDisclosure = null;
  switch (type) {
    case USER_LOAN_PROGRAM.DSCR:
      closingDisclosure = calculateDSCRClosingDisclosure(props);
      break;
    case USER_LOAN_PROGRAM.FHA:
      closingDisclosure = calculateFHAClosingDisclosure(props);
      break;
    case USER_LOAN_PROGRAM.USDA:
      closingDisclosure = calculateUSDAClosingDisclosure(props);
      break;
    case USER_LOAN_PROGRAM.FIX_AND_FLIP:
      closingDisclosure = calculateFixAndFlipClosingDisclosure(props);
      break;
    case USER_LOAN_PROGRAM.VA:
      closingDisclosure = calculateVAClosingDisclosure(props);
    case USER_LOAN_PROGRAM.CONVENTIONAL:
      closingDisclosure = calculateConventionalClosingDisclosure(props);
      break;
    default:
      closingDisclosure = calculateDefaultClosingDisclosure(props);
      break;
  }

  return closingDisclosure;
};
