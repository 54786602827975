import { useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material';

import {
  useGetContractByAgentQuery,
  useUpdateContractAgentMutation,
} from '@/apis/extended/contractApi';
import { parseString } from '@/utils/helper';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonNumberInput } from '../Common/CommonNumberInput';

const initialForm = {
  name: '',
  license: '',
  brokerage: '',
  brokerageLicense: '',
  commission: '',
};

type ContractDetailAgentInfoProps = {
  uid: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailAgentInfo = ({
  uid,
  onPrev,
  onNext,
}: ContractDetailAgentInfoProps) => {
  const { data: contract } = useGetContractByAgentQuery(uid);
  const [updateContractAgent, { isLoading }] = useUpdateContractAgentMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const agentInfo = contract?.agentInfo;
    if (!agentInfo) return;
    setForm({
      name: agentInfo.name || '',
      license: agentInfo.license || '',
      brokerage: agentInfo.brokerage || '',
      brokerageLicense: agentInfo.brokerageLicense || '',
      commission: parseString(agentInfo.commission),
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return (
      form.name &&
      form.license &&
      form.brokerage &&
      form.brokerageLicense &&
      !isNaN(parseFloat(form.commission))
    );
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractAgent({
      uid,
      agentInfo: {
        ...form,
        commission: parseFloat(form.commission),
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Agent Information"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-user" />}
        />
        <CardContent>
          <CommonFormControl label="Agent Name:" labelWidth={250}>
            <TextField
              placeholder="Agent Name"
              name="name"
              value={form.name}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Agent License:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Agent License"
              name="license"
              value={form.license}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Agent Brokerage:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Agent Brokerage"
              name="brokerage"
              value={form.brokerage}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Agent Brokerage License:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Agent Brokerage License"
              name="brokerageLicense"
              value={form.brokerageLicense}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Agent Commission:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <CommonNumberInput
              placeholder="Agent Commission"
              name="commission"
              value={form.commission}
              onChange={handleInputChange}
            />
          </CommonFormControl>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
