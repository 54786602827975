import { Box, CircularProgress, useTheme } from '@mui/material';

type LoaderProps = {
  isFull?: boolean;
};

export const Loader = ({ isFull }: LoaderProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: isFull ? '60vh' : '100px',
      }}
    >
      <CircularProgress sx={{ color: theme.palette.mainColors.white }} />
    </Box>
  );
};
