import { USER_LOAN_PROGRAM } from '@/config/constants';
import {
  CalculateClosingDisclosureProps,
  calculateTaxFactor,
  calculateTransferTaxFactor,
  chooseMarketInterestRate,
} from '.';

export const calculateDefaultClosingDisclosure = ({
  offerPrice,
  listPrice,
  downPayment = 0,
  zipCode = 0,
  hoaFees = 0,
  commission = 0,
  marketInterestRate = 6.875,
  creditScore = 0,
  loanTerm = 30,
}: CalculateClosingDisclosureProps) => {
  const purchasePrice = offerPrice || listPrice;

  const taxFactor = calculateTaxFactor(zipCode);
  const transferTaxFactor = calculateTransferTaxFactor(zipCode);

  //database Constants
  const interestRate = chooseMarketInterestRate(
    marketInterestRate,
    creditScore
  );

  //change on user input on account page
  const numberOfPayments = loanTerm * 12;

  // constant fees
  const countyRecordingFees = 250;
  const stateRecordingFees = 150;
  const titleTitleSearchBase = 250;
  const recordingDeedBase = 75;
  const appraisalFeeBase = 550;
  const underwritingFee = 995;
  const applicationFeeBase = 695;
  const rentalIncomeReportFee = 950;
  const recordingTitle = 75;
  const recordingMortgage = 75;
  const surveyFee = 250;
  const pestInspectionFee = 145;
  const floodMonitoringFee = 25;
  const floodDeterminationFee = 35;
  const creditReportFeeBase = 75;
  const processingFeeBase = 395;
  const titleSettlementAgentFee = 350;
  const earnestMoneyDeposit = 1000;
  const insuranceBinder = 150;
  const lenderCredits = 0;
  const initialEscrowMortgageInsurance = 0;
  const prepaidsPropertyTaxes = -100; //this is a credit from the buyer

  //loan amount and downpayment are dynamic from database for each house/user
  const loanAmount = purchasePrice - downPayment; //need to subtract down payment

  // factored calculations
  const annualPropertyTax = (purchasePrice * taxFactor) / 100;

  //UNFACTORED CALCULATIONS, NEED TO FIGURE OUT HOW TO INCLUDE CREDIT AND STATE FACTORS

  const monthlyMortgageInsurancePremiumBase = (purchasePrice * 0.0075) / 12; //State, Credit
  const optionalOwnersTitleInsuranceBase = purchasePrice * 0.00575; //State, Credit
  const recordingTransferTaxBase = purchasePrice * 0.01; //State dependent.
  const monthlyprivateMortgageInsuranceBase = (purchasePrice * 0.0075) / 12; //State, Credit
  const monthlyHomeownersInsuranceBase = (purchasePrice * 0.0103) / 12;
  const monthlyHomeownersInsurance = (purchasePrice * 0.01035) / 12;
  const monthlyPropertyTaxes = annualPropertyTax / 12;
  const prepaidsHomeownersInsurance = purchasePrice * 0.01035;
  const titleLendersTitleInsuranceBase = purchasePrice * 0.0065;
  const upfrontMortgageInsurancePremiumBase = loanAmount * 0.0175;
  const homeInspectionFeeBase = purchasePrice * 0.0035;
  const initialEscrowTaxes = monthlyPropertyTaxes * 6; //
  const initialEscrowHOI = ((purchasePrice * 0.01) / 12) * 3;
  const estimatedEscrow = (purchasePrice * 0.0103) / 12 + monthlyPropertyTaxes;

  //these aren't going to change, they are determined
  const dailyInterestRate = interestRate / 365 / 100; // Convert annual rate to daily decimal
  const prepaidInterest = loanAmount * dailyInterestRate * 15; // Return the result rounded to 2 decimal places
  const monthlyInterestRate = interestRate / 12 / 100; // Convert annual rate to monthly decimal
  const principalAndInterest =
    (loanAmount *
      (monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments))) /
    (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  const piti = principalAndInterest + estimatedEscrow;
  const transferTaxes = purchasePrice * transferTaxFactor;
  const cleanPayment = piti;
  const otherCosts =
    optionalOwnersTitleInsuranceBase +
    pestInspectionFee +
    initialEscrowTaxes +
    prepaidsPropertyTaxes +
    prepaidInterest +
    prepaidsHomeownersInsurance +
    stateRecordingFees +
    countyRecordingFees +
    recordingMortgage +
    recordingTitle +
    recordingDeedBase +
    transferTaxes;
  const costTotals =
    insuranceBinder +
    titleTitleSearchBase +
    titleLendersTitleInsuranceBase +
    surveyFee +
    titleSettlementAgentFee +
    floodDeterminationFee +
    floodMonitoringFee +
    creditReportFeeBase +
    appraisalFeeBase +
    applicationFeeBase +
    underwritingFee +
    processingFeeBase +
    creditReportFeeBase;
  const closingCosts = otherCosts + costTotals;
  const cashToClose = closingCosts + downPayment + earnestMoneyDeposit;
  const cashOutsideClosing =
    appraisalFeeBase + earnestMoneyDeposit + homeInspectionFeeBase;
  const unbeatableCredit = (purchasePrice * commission) / 100; //this is where we put the buyers agent commission
  const unbeatableDeal = cashToClose - unbeatableCredit;

  // Perform calculations using the provided purchase price and constants
  // Prepare the closing disclosure object
  const closingDisclosure = {
    type: USER_LOAN_PROGRAM.DEFAULT,
    offerPrice,
    listPrice,
    purchasePrice,
    commission,
    marketInterestRate,
    creditScore,
    downPayment,
    loanAmount,
    interestRate,

    cleanPayment,
    cashOutsideClosing,
    processingFeeBase,
    principalAndInterest,
    homeInspectionFeeBase,
    loanTerm,
    optionalOwnersTitleInsuranceBase,
    hoaFees,
    earnestMoneyDeposit,
    piti,
    estimatedEscrow,
    monthlyPropertyTaxes,
    monthlyHomeownersInsurance,
    prepaidsHomeownersInsurance,
    prepaidsPropertyTaxes,
    monthlyprivateMortgageInsuranceBase,
    upfrontMortgageInsurancePremiumBase,
    prepaidInterest,
    titleSettlementAgentFee,
    titleLendersTitleInsuranceBase,
    titleTitleSearchBase,
    insuranceBinder,
    recordingDeedBase,
    appraisalFeeBase,
    underwritingFee,
    applicationFeeBase,
    pestInspectionFee,
    floodMonitoringFee,
    floodDeterminationFee,
    creditReportFeeBase,
    rentalIncomeReportFee,
    recordingTitle,
    recordingMortgage,
    countyRecordingFees,
    stateRecordingFees,
    surveyFee,
    initialEscrowTaxes,
    initialEscrowMortgageInsurance,
    initialEscrowHOI,
    lenderCredits,
    costTotals,
    otherCosts,
    closingCosts,
    cashToClose,
    unbeatableDeal,
    transferTaxes,
  };
  return closingDisclosure;
};

export type ProductDefaultEsitmation = ReturnType<
  typeof calculateDefaultClosingDisclosure
>;
