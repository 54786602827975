import { ReactNode } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';

type CommonDialogProps = {
  isOpen: boolean;
  isLoading?: boolean;
  icon?: string;
  title?: ReactNode;
  closeButtonText?: string;
  confirmButtonText?: string;
  customActions?: ReactNode;
  confirmButtonColor?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning';
  onClose?: () => void;
  onConfirm?: () => void;
  children: ReactNode;
  sx?: SxProps;
};

export const CommonDialog = ({
  isOpen,
  isLoading,
  icon,
  title,
  closeButtonText,
  confirmButtonText,
  confirmButtonColor,
  customActions,
  children,
  onClose,
  onConfirm,
  sx,
}: CommonDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={onClose} sx={{ top: 50, ...sx }}>
      {title ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            borderBottom: '1px solid',
            borderColor: theme.palette.greyShades.border2,
            padding: '16px',
            color: theme.palette.mainColors.white,
          }}
        >
          {icon && <i className={icon} />}
          <Typography variant="h4">{title}</Typography>
        </Box>
      ) : (
        <Box sx={{ py: '16px' }} />
      )}
      {onClose && (
        <IconButton
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={onClose}
        >
          <i className="fa-light fa-xmark" />
        </IconButton>
      )}
      <DialogContent sx={{ overflowY: 'auto', p: '16px' }}>
        {children}
      </DialogContent>
      {(closeButtonText || confirmButtonText || customActions) && (
        <DialogActions
          sx={{
            padding: '8px 16px 16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {closeButtonText && onClose && (
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              {closeButtonText}
            </Button>
          )}
          {confirmButtonText && (
            <LoadingButton
              variant="contained"
              color={confirmButtonColor || 'primary'}
              onClick={() => {
                onConfirm && onConfirm();
              }}
              loading={isLoading}
            >
              {confirmButtonText}
            </LoadingButton>
          )}
          {customActions}
        </DialogActions>
      )}
    </Dialog>
  );
};
