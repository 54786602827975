import { useMemo } from 'react';
import { Box, Card, Typography, useTheme } from '@mui/material';

import { USER_APPROVE_STATUS, USER_QUALIFY_STATUS } from '@/config/constants';
import { getproductOwner } from '@/utils/product';
import { Product } from '@/types/ProductType';

type ProductDetailProgressProps = {
  product: Product;
};

export const ProductDetailProgress = ({
  product,
}: ProductDetailProgressProps) => {
  const theme = useTheme();

  const checkItems = useMemo(() => {
    const user = getproductOwner(product);
    const items = [
      {
        label: 'Add a home to Unbeatable',
        value: true,
      },
      {
        label: 'Complete a budget for guided shopping',
        value: Boolean(user?.loanInfo && product.loanInfo.zipCode),
      },
      {
        label: 'Check if you qualify for this property',
        value: user?.qualifyStatus === USER_QUALIFY_STATUS.QUALIFY,
      },
      {
        label: 'Receive a personal loan estimate for this property',
        value: Boolean(user?.approveStatus === USER_APPROVE_STATUS.APPROVED),
      },
      {
        label: 'Make an offer to buy this property',
        value: Boolean(product.contract),
      },
    ];
    return items;
  }, [product]);

  return (
    <Card
      sx={{
        p: 2,
        mt: 2,
        background: theme.palette.mainColors.backgroundOpacity1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {checkItems.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {item.value ? (
              <i className="fa-light fa-check color-success" />
            ) : (
              <i className="fa-light fa-close color-error" />
            )}
            <Typography
              sx={{ color: theme.palette.greyShades.grey1 }}
              variant="body2"
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
};
