import { Box, Typography } from '@mui/material';

import { ManageUserTable } from '@/components/Admin/ManageUser/ManageUserTable';

export const AdminUser = () => {
  return (
    <Box>
      <Typography variant="h1">Manage Users</Typography>
      <Box mt={3}>
        <ManageUserTable />
      </Box>
    </Box>
  );
};
