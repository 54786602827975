import { Box, IconButton } from '@mui/material';

type CommonActionsProps = {
  onPrev?: () => void;
  onNext?: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  isLoading?: boolean;
};

export const CommonActions = ({
  isPrevDisabled,
  isNextDisabled,
  isLoading,
  onPrev,
  onNext,
}: CommonActionsProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {onPrev && (
        <IconButton
          size="small"
          title="Previous"
          disabled={isPrevDisabled || isLoading}
          onClick={onPrev}
        >
          <i className="fa-light fa-arrow-left" />
        </IconButton>
      )}
      {onNext && (
        <IconButton
          size="small"
          title="Next"
          disabled={isNextDisabled || isLoading}
          onClick={onNext}
        >
          <i className="fa-light fa-arrow-right" />
        </IconButton>
      )}
    </Box>
  );
};
