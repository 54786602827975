import { useState } from 'react';
import { Box, Button, Container, Typography, useTheme } from '@mui/material';

import ExtensionVideo from '@/assets/videos/extension.mp4';
import { SigninModal } from '../Auth/SigninModal';

export const LandingVideoSection = () => {
  const theme = useTheme();
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  return (
    <Container sx={{ py: '40px' }}>
      <Typography
        variant="h2"
        sx={{ textAlign: 'center', fontSize: { md: '36pt' } }}
      >
        Get Approved and Make Offers with{' '}
        <span style={{ color: theme.palette.mainColors.primary }}>
          Unbeatable Loans
        </span>
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', mt: 4, fontSize: { md: '16pt' } }}
      >
        Use all of the commission to reduce closing costs or down payment.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Button
          sx={{ width: { xs: '100%', md: 'auto' } }}
          size="large"
          onClick={() => setIsSignInModalOpen(true)}
        >
          Get Started
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <video
          style={{ width: '100%', borderRadius: '8px' }}
          controls
          src={ExtensionVideo}
        />
      </Box>
      <SigninModal
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
    </Container>
  );
};
