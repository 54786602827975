import { rtkApi } from '@/apis/rtkApi';
import {
  GetFeedbacksByAdminRequest,
  GetFeedbacksByAdminResponse,
} from '@/types/GlobalType';

export const feedbackApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbacksByAdmin: builder.query<
      GetFeedbacksByAdminResponse,
      GetFeedbacksByAdminRequest
    >({
      query: (payload) => ({ url: `/admin/feedbacks`, params: payload }),
    }),
  }),
});

export const { useGetFeedbacksByAdminQuery } = feedbackApi;
