import { Box, Container, styled, Typography, useTheme } from '@mui/material';

import { BodyText } from './PrivacyPolicy';

export const Support = () => {
  const theme = useTheme();
  return (
    <Container sx={{ py: '40px', color: theme.palette.greyShades.grey1 }}>
      <Box>
        <Typography variant="h1">Support - Unbeatable Loans</Typography>
        <BodyText sx={{ mt: 3 }}>
          <ul>
            <li>
              <a href="#account">Secure an Account</a>
            </li>
            <li>
              <a href="#add-property">Add a Property</a>
            </li>
            <li>
              <a href="#create-budget">Create a Budget</a>
            </li>
            <li>
              <a href="#get-approved">Get Approved for a Mortgage</a>
            </li>
            <li>
              <a href="#submit-offer">Submit an Offer</a>
            </li>
            <li>
              <a href="#costs">Costs Involved</a>
            </li>
          </ul>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }} id="account">
        <Typography variant="h3">How to Secure an Account</Typography>
        <BodyText sx={{ mt: 3 }}>
          To secure your account with email verification on Unbeatable Loans:
          <ol>
            <li>After signing in with your email, visit your account page.</li>
            <li>
              Fill in your name and you have the option to add a custom
              username.
            </li>
            <li>
              Submit the button "Enable Security" to require login with email
              verification.
            </li>
            <li>
              Verify your email address by clicking the link sent to your email.
            </li>
            <li>Log in to your email and verify your account.</li>
            <li>
              As long as account security is enabled, you will need to verify
              your login at each attempt through your email.
            </li>
          </ol>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }} id="use-extension">
        <Typography variant="h3">How to Use the Chrome Extension</Typography>
        <BodyText sx={{ mt: 3 }}>
          Using the Chrome extension:
          <ol>
            <li>
              The Unbeatable Loans Chrome extension requires a recent version of
              Google Chrome.
            </li>
            <li>
              Download and install the Unbeatable Loans Chrome extension from
              the{' '}
              <a href={process.env.VITE_EXTENSION_URL} target="_blank">
                Chrome Web Store
              </a>
              .
            </li>
            <li>
              Pin the Chrome extension to your toolbar by selecting the puzzle
              piece icon, and then the pin icon next to the Unbeatable Loans
              extension.
            </li>
            <li>
              The Chrome extension works by selecting specific information from
              your browser and passing it to the Unbeatable Loans platform.
            </li>
            <li>
              Click on the Unbeatable Loans icon in your Chrome toolbar to
              activate the extension.
            </li>
            <li>
              To deactivate the extension, click again on the Unbeatable Loans
              icon in your Chrome toolbar.
            </li>
            <li>
              The Unbeatable Loans Extension is designed so you can get property
              information from any website with minimal user interaction.
            </li>
          </ol>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }} id="add-property">
        <Typography variant="h3">
          How to Add a Property to the Platform with the Chrome Extension
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          To add a property to our platform using the Chrome extension:
          <ol>
            <li>
              Download and install the Unbeatable Loans Chrome extension from
              the{' '}
              <a href={process.env.VITE_EXTENSION_URL} target="_blank">
                Chrome Web Store
              </a>
              .
            </li>
            <li>
              Log in to your Unbeatable Loans account with the extension
              downloaded.
            </li>
            <li>
              Navigate to the property listing you want to add via any listing
              service or property detail website.
            </li>
            <li>
              Click on the Unbeatable Loans icon in your Chrome toolbar to
              activate the extension.
            </li>
            <li>
              Click when the correct property information prompts you from the
              chrome extension modal.
            </li>
            <li>
              Fill in any additional information about the property when
              prompted, or edit the information by repicking the information
              with your cursor.
            </li>
            <li>
              Click "Add to Unbeatable" to add the property to your account.
            </li>
            <li>
              To visit Unbeatable Loans after you add the property to your
              account, click the emblem on the extension popup before it
              dissapears.
            </li>
          </ol>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }} id="create-budget">
        <Typography variant="h3">
          How to Create a Budget for Home Shopping
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          To create a budget for home shopping:
          <ol>
            <li>Log in to your Unbeatable Loans account.</li>
            <li>
              Navigate to the "See if I Qualify" button from the main page.
            </li>
            <li>
              Enter your monthly income, credit score, existing monthly
              payments, and other financial obligations.
            </li>
            <li>
              This budgeting tool will calculate how much you can afford to
              spend on a home.
            </li>
            <li>Review the suggested budget and make adjustments as needed.</li>
            <li>Save your budget for future reference.</li>
            <li>
              Your budget is based on current interest rates, so check back
              often for updates!
            </li>
          </ol>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }} id="get-approved">
        <Typography variant="h3">
          How to Get Approved for a Mortgage through the Portal
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          To get approved for a mortgage through our portal:
          <ol>
            <li>Log in to your Unbeatable Loans account.</li>
            <li>
              After you have completed a budget on the "See if I Qualify
              Section, it will show "Get Approved".
            </li>
            <li>
              Navigate to the "Get Approved" section. Confirm your phone number,
              and upload a photo of your Government Issued ID.
            </li>
            <li>
              After you complete your upload and confirm your phone number, you
              will be transferred to your Loan Officers specific Loan
              Application.
            </li>
            <li>
              Upload required documents and information to the Loan Application,
              such as proof of income, bank statemenst, and identification to
              the secure Loan Origination System.
            </li>
            <li>
              Submit your application for review, and stand by for a call from
              our Loan Officers.
            </li>
            <li>
              Our team will review your application and send you an official
              approval decision.
            </li>
            <li>
              Once approved, you'll receive the ability to submit offers on
              properties that Listing Agents can accept on your behalf to
              legally enter into contract to purchase a home.
            </li>
          </ol>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }} id="submit-offer">
        <Typography variant="h3">How to Submit an Offer</Typography>
        <BodyText sx={{ mt: 3 }}>
          To submit an offer on a property:
          <ol>
            <li>Log in to your Unbeatable Loans account.</li>
            <li>
              Navigate to the "My Approved Properties" section along the top,
              the third icon along the top from the left.
            </li>
            <li>Select the property you wish to make an offer on.</li>
            <li>Click the "Start Offer" button.</li>
            <li>
              Fill in the offer details, including the offer amount and any
              contingencies.
            </li>
            <li>Submit your offer for review.</li>
            <li>
              You will receive an email confirmation once your offer has been
              sent to the seller.
            </li>
          </ol>
        </BodyText>
      </Box>
      <Box sx={{ mt: 5 }} id="costs">
        <Typography variant="h3">
          Costs Involved with Using Unbeatable Loans
        </Typography>
        <BodyText sx={{ mt: 3 }}>
          Using Unbeatable Loans involves the following costs:
          <ul>
            <li>
              <strong>Credit Report Fee:</strong> A fee for obtaining your
              credit report as part of the mortgage approval process.
            </li>
            <li>
              <strong>Application Fee:</strong> A non-refundable fee for
              processing your mortgage application paid at closing.
            </li>
            <li>
              <strong>Origination Fee:</strong> A fee charged by the lender for
              processing the loan paid at closing.
            </li>
            <li>
              <strong>Appraisal Fee:</strong> A fee for the professional
              appraisal of the property paid on the acceptance of a contract.
            </li>
            <li>
              <strong>Closing Costs:</strong> Various third party fees
              associated with closing the loan, including attorney fees, title
              insurance, and recording fees.
            </li>
          </ul>
        </BodyText>
      </Box>
    </Container>
  );
};
