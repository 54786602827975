import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { HEADER_HEIGHT } from '@/config/constants';
import { AdminHeader } from './AdminHeader';

export const AdminLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <AdminHeader />
      <Container
        sx={{ minHeight: '70vh', mt: `${HEADER_HEIGHT}px`, py: '40px' }}
      >
        <Outlet />
      </Container>
    </Box>
  );
};
