type LogoSvgProps = {
  size?: number;
};

export const LogoSvg = ({ size = 30 }: LogoSvgProps) => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 2329.98 1093.52"
      style={{ height: size + 'px' }}
    >
      <defs>
        <linearGradient
          id="Sfumatura_senza_nome_170"
          data-name="Sfumatura senza nome 170"
          x1="0"
          y1="546.76"
          x2="2329.98"
          y2="546.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#14ff9d" />
          <stop offset=".23" stopColor="#13f99a" />
          <stop offset=".51" stopColor="#10ea91" />
          <stop offset=".83" stopColor="#0bd083" />
          <stop offset="1" stopColor="#09c07a" />
        </linearGradient>
      </defs>
      <g id="Livello_1" data-name="Livello 1">
        <path
          style={{ fill: 'url(#Sfumatura_senza_nome_170)', strokeWidth: 0 }}
          d="m90.2,468.55L0,624.81h1356.01l90.13-156.18-1355.93-.08ZM1833.89,0H790.48l-90.2,156.18h1043.41l430.11,744.89v192.45h156.18v-234.35L1833.89,0ZM381.73,234.28l-90.13,156.26h1199.67l90.21-156.26H381.73Zm929.13,468.71l-90.2,156.18v234.35h-156.19v-234.35H255.27l90.28-156.18h965.32Zm230.18,39.12v351.42h273.32v-351.42h-273.32Z"
        />
      </g>
    </svg>
  );
};
