import { useTheme } from '@mui/material';

type DataGridIconCellProps = {
  status: boolean;
  icon?: string;
};

export const DataGridIconCell = ({
  status,
  icon = 'fa-shield-check',
}: DataGridIconCellProps) => {
  const theme = useTheme();
  if (!status) return null;
  return (
    <i
      className={`fa-light ${icon}`}
      style={{ color: theme.palette.mainColors.green, margin: 'auto' }}
    />
  );
};
