import { Product } from '@/types/ProductType';

export const getProductOwnerId = (product?: Product) => {
  if (!product?.user) return;
  if (typeof product.user === 'string') return product.user;
  return product.user._id;
};

export const getproductOwner = (product?: Product) => {
  if (!product?.user) return null;
  if (typeof product.user === 'string') return null;
  return product.user;
};
