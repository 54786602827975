import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Box, useTheme } from '@mui/material';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Product } from '@/types/ProductType';
import { CommonImage } from '../Common/CommonImage';

type ProductDetailLogos = {
  product: Product;
};

export const ProductDetailLogos = ({ product }: ProductDetailLogos) => {
  const theme = useTheme();

  if (!product) return null;

  return (
    <Box>
      <Box
        sx={{
          aspectRatio: '4 / 3',
          borderRadius: '12px',

          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: theme.palette.primary.main,
          overflow: 'hidden',
        }}
      >
        <CommonImage photo={product.photo} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Swiper
          slidesPerView="auto"
          loop
          navigation
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
        >
          {product.photos.map((photo, idx) => (
            <SwiperSlide key={idx} style={{ width: '200px', height: '150px' }}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  border: '1px solid',
                  borderColor: theme.palette.mainColors.primary,
                  overflow: 'hidden',
                }}
              >
                <CommonImage photo={photo} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
