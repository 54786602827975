type CommonGapInputProps = {
  value: string;
  onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  type?: string;
  disabled?: boolean;
};

export const CommonGapInput = ({
  name,
  value,
  onChange,
  disabled,
  type = 'text',
}: CommonGapInputProps) => {
  return (
    <input
      style={{
        margin: '0 4px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        color: disabled ? 'gray' : 'white',
        borderBottom: '1px solid',
        borderColor: disabled ? 'gray' : 'white',
        width: '50px',
        textAlign: 'center',
      }}
      disabled={disabled}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};
