import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig } from 'axios';

import { getCeId } from '@/utils/auth';
import { apiBaseUrl, rawApi } from './rawApi';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, headers }: AxiosRequestConfig) => {
    try {
      let _data = data;
      if (
        method?.toLocaleLowerCase() === 'post' ||
        method?.toLocaleLowerCase() === 'put'
      ) {
        if (!_data) _data = {};
        _data.ce_id = getCeId();
      }
      const result = await rawApi.request({
        url: baseUrl + url,
        method,
        data: _data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError: any) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const rtkApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: [
    'Product',
    'Contract',
    'Contract Agent',
    'Contract Seller',
    'User',
    'Admin User',
    'Admin Agent',
    'Admin Product',
    'Admin Email',
  ],
  endpoints: (_builder) => ({}),
});
