import { LoadingButton } from '@mui/lab';
import { Box, Button, SxProps } from '@mui/material';

type CommonPrevNextButtonsProps = {
  onPrev?: () => void;
  onNext?: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  isLoading?: boolean;
  prevText?: string;
  nextText?: string;
  sx?: SxProps;
};

export const CommonPrevNextButtons = ({
  isPrevDisabled,
  isNextDisabled,
  isLoading,
  onPrev,
  onNext,
  prevText = 'Prev',
  nextText = 'Next',
  sx,
}: CommonPrevNextButtonsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        ...sx,
      }}
    >
      {onPrev && (
        <Button disabled={isPrevDisabled || isLoading} onClick={onPrev}>
          {prevText}
        </Button>
      )}
      {onNext && (
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={isNextDisabled}
          onClick={onNext}
        >
          {nextText}
        </LoadingButton>
      )}
    </Box>
  );
};
