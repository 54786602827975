import { CommonConfirmDialog } from '@/components/Common/CommonConfirmDialog';

type RemoveEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const RemoveEmailModal = ({
  isOpen,
  onClose,
  onConfirm,
}: RemoveEmailModalProps) => {
  return (
    <CommonConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      icon="fa-light fa-trash-can"
      title="Remove Email?"
      content="Are you sure you want to remove this email?"
      confirmButtonText="Remove"
      onConfirm={onConfirm}
    />
  );
};
