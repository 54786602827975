import { useMemo } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import { USER_LOAN_PROGRAM } from '@/config/constants';
import { useEstimation } from '@/hooks/useEstimation';
import { ProductConventionalEsitmation } from '@/utils/calculator/Conventional';
import { ProductDefaultEsitmation } from '@/utils/calculator/default';
import { ProductFHAEsitmation } from '@/utils/calculator/FHA';
import { ProductFixAndFlipEsitmation } from '@/utils/calculator/FixAndFlip';
import { ProductUSDAEsitmation } from '@/utils/calculator/USDA';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';
import { CommonFormControl } from '../Common/CommonFormControl';

type ProductDetailEstimationProps = {
  product: Product;
};

export const ProductDetailProjectedPayment = ({
  product,
}: ProductDetailEstimationProps) => {
  const { estimation } = useEstimation(product);

  const totalMonthlyPaymentLabel = useMemo(() => {
    return estimation?.type === USER_LOAN_PROGRAM.FIX_AND_FLIP
      ? 'Initial Monthly Payment:'
      : 'Total Monthly Payment:';
  }, [estimation]);

  const monthlyPrincipalInterest = useMemo(() => {
    if (!estimation) return;
    if (estimation.type === USER_LOAN_PROGRAM.FIX_AND_FLIP) {
      return (estimation as ProductFixAndFlipEsitmation).interestPayment;
    }
    return (estimation as ProductDefaultEsitmation).principalAndInterest;
  }, [estimation]);

  const monthlyprivateMortgageInsuranceBase = useMemo(() => {
    if (!estimation) return;
    if (estimation.type === USER_LOAN_PROGRAM.DEFAULT) {
      return (estimation as ProductDefaultEsitmation)
        .monthlyprivateMortgageInsuranceBase;
    }
    return;
  }, [estimation]);

  const monthlyMortgageInsurance = useMemo(() => {
    if (!estimation) return;
    if (estimation.type === USER_LOAN_PROGRAM.FHA) {
      return (estimation as ProductFHAEsitmation).monthlyMIP;
    }
    if (estimation.type === USER_LOAN_PROGRAM.CONVENTIONAL) {
      return (estimation as ProductConventionalEsitmation).monthlyPMIPayment;
    }
    return;
  }, [estimation]);

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Projected Payments"
        avatar={<i className="fa-light fa-square-kanban" />}
      />
      <CardContent>
        <CommonFormControl
          label={totalMonthlyPaymentLabel}
          secondLabel={formatPrice(estimation.cleanPayment)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Monthly Interest Payment:"
          secondLabel={formatPrice(monthlyPrincipalInterest)}
          inLine
          labelWidth={270}
        />
        {estimation.type === USER_LOAN_PROGRAM.FIX_AND_FLIP && (
          <CommonFormControl
            label="Final Monthly Payment:"
            secondLabel={formatPrice(
              (estimation as ProductFixAndFlipEsitmation).carry
            )}
            inLine
            labelWidth={270}
          />
        )}
        {estimation.type === USER_LOAN_PROGRAM.USDA && (
          <CommonFormControl
            label="Monthly USDA Gaurantee:"
            secondLabel={formatPrice(
              (estimation as ProductUSDAEsitmation).monthlyGauranteefee
            )}
            inLine
            labelWidth={270}
          />
        )}
        {monthlyprivateMortgageInsuranceBase !== undefined && (
          <CommonFormControl
            label="Mortgage Insurance:"
            secondLabel={formatPrice(monthlyprivateMortgageInsuranceBase)}
            inLine
            labelWidth={270}
          />
        )}
        <CommonFormControl
          label="Monthly Property Taxes:"
          secondLabel={formatPrice(estimation.monthlyPropertyTaxes)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Monthly Homeowners insurance:"
          secondLabel={formatPrice(estimation.monthlyHomeownersInsurance)}
          inLine
          labelWidth={270}
        />
        {monthlyMortgageInsurance !== undefined && (
          <CommonFormControl
            label="Monthly Mortgage Insurance:"
            secondLabel={formatPrice(monthlyMortgageInsurance)}
            inLine
            labelWidth={270}
          />
        )}
        <CommonFormControl
          label="Estimated Escrow:"
          secondLabel={formatPrice(estimation.estimatedEscrow)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Monthly HOA/Condo Fees:"
          secondLabel={formatPrice(estimation.hoaFees)}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
