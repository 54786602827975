import { useCallback, useMemo, useState } from 'react';
import { IconButton, useTheme } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowModel,
} from '@mui/x-data-grid';
import _ from 'lodash';

import { DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE } from '@/config/constants';
import {
  useGetEmailsByAdminQuery,
  useRemoveEmailByAdminMutation,
  useUpdateEmailByAdminMutation,
} from '@/apis/extended/admin/emailApi';
import { CommonDuplicateIconButton } from '@/components/Common/CommonButton/CommonDuplicateIconButton';
import { CommonEditIconButton } from '@/components/Common/CommonButton/CommonEditIconButton';
import { CommonRemoveIconButton } from '@/components/Common/CommonButton/CommonRemoveIconButton';
import { CommonDataGrid } from '@/components/Common/CommonDataGrid';
import { DataGridTextField } from '@/components/Common/CommonDataGrid/DataGridTextField';
import { AddEmailModal } from '@/components/ConfirmModals/AddEmailModal';
import { RemoveEmailModal } from '@/components/ConfirmModals/RemoveEmailModal';
import { Loader } from '@/components/Layout/Loader';
import { extractSpecialTags } from '@/utils/helper';
import { Email } from '@/types/EmailType';

export const ManageEmailTable = () => {
  const theme = useTheme();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE);
  const [removingEmail, setRemovingEmail] = useState<Email | null>(null);
  const isRemoveEmailModalOpen = Boolean(removingEmail);
  const [updatingEmail, setUpdatingEmail] = useState<Email | null>(null);
  const isUpdateEmailModalOpen = Boolean(updatingEmail);
  const [duplicatingEmail, setDuplicatingEmail] =
    useState<Partial<Email> | null>(null);
  const isDuplicateEmailModalOpen = Boolean(duplicatingEmail);

  const { data, isLoading, isFetching } = useGetEmailsByAdminQuery({
    offset,
    limit,
  });
  const [updateEmailByAdmin] = useUpdateEmailByAdminMutation();
  const [removeEmailByAdmin] = useRemoveEmailByAdminMutation();

  const columns = useMemo(
    () =>
      [
        {
          field: 'key',
          headerName: 'Key',
          width: 180,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        {
          field: 'title',
          headerName: 'Title',
          width: 280,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        {
          field: 'content',
          headerName: 'Tags',
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            return extractSpecialTags(params.value);
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          type: 'actions',
          getActions: (params) => [
            <CommonDuplicateIconButton
              onClick={() =>
                setDuplicatingEmail({ ...params.row, _id: undefined })
              }
            />,
            <CommonEditIconButton
              onClick={() => setUpdatingEmail(params.row)}
            />,
            <CommonRemoveIconButton
              onClick={() => setRemovingEmail(params.row)}
            />,
          ],
        },
      ] as GridColDef<Email>[],
    []
  );

  const onPaginationChanged = ({ page, pageSize }: GridPaginationModel) => {
    setOffset(page * pageSize);
    setLimit(pageSize);
  };

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const isUpdated = !_.isEqual(newRow, oldRow);
        if (isUpdated) {
          updateEmailByAdmin({
            id: newRow._id,
            ..._.pick(newRow, ['key', 'title']),
          })
            .unwrap()
            .then(() => resolve(newRow))
            .catch(() => resolve(oldRow));
        } else {
          resolve(oldRow);
        }
      }),
    []
  );

  const handleRemoveEmail = () => {
    if (!removingEmail) return;
    removeEmailByAdmin(removingEmail._id);
    setRemovingEmail(null);
  };

  if (isLoading || !data) return <Loader isFull />;

  return (
    <>
      <CommonDataGrid
        columns={columns}
        rows={data.emails}
        rowCount={data.count}
        paginationModel={{ page: offset / limit, pageSize: limit }}
        onPaginationModelChange={onPaginationChanged}
        loading={isFetching}
        processRowUpdate={processRowUpdate}
      />
      <RemoveEmailModal
        isOpen={isRemoveEmailModalOpen}
        onClose={() => setRemovingEmail(null)}
        onConfirm={handleRemoveEmail}
      />
      <AddEmailModal
        isOpen={isUpdateEmailModalOpen}
        email={updatingEmail!}
        onClose={() => setUpdatingEmail(null)}
      />
      <AddEmailModal
        isOpen={isDuplicateEmailModalOpen}
        email={duplicatingEmail!}
        onClose={() => setDuplicatingEmail(null)}
      />
    </>
  );
};
