import { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader } from '@mui/material';

import { useUpdateSecurityMutation } from '@/apis/extended/authApi';
import { useGetCurrentUserQuery } from '@/apis/extended/userApi';
import { CommonFormControl } from '../Common/CommonFormControl';
import { SecurityStatusBadge } from './components/SecurityStatusBadge';

export const ProfileSecurity = () => {
  const { data: user, refetch } = useGetCurrentUserQuery();
  const [updateSecurity] = useUpdateSecurityMutation();

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const isLoading = intervalId !== null;

  useEffect(() => {
    if (user?.securityEnabled && intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [user, intervalId]);

  useEffect(() => {
    if (intervalId === null) return;
    return () => clearInterval(intervalId);
  }, [intervalId]);

  const checkSecurity = () => {
    refetch();
  };

  const handleUpdateSecurity = () => {
    const checked = !user?.securityEnabled;
    updateSecurity(checked);
    if (checked && intervalId === null) {
      const id = setInterval(checkSecurity, 3000);
      setIntervalId(id);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title={
          <>
            Security{' '}
            <SecurityStatusBadge
              sx={{ ml: 2 }}
              enabled={user?.securityEnabled}
              isLoading={isLoading}
            />
          </>
        }
        avatar={<i className="fa-light fa-shield-check" />}
      />
      <CardContent>
        <CommonFormControl
          label="Secure my account:"
          labelWidth={200}
          inLine
          secondLabel={
            user?.securityEnabled
              ? 'Security is enabled'
              : isLoading
              ? 'Check your Inbox to verify email address'
              : ''
          }
        >
          {!user?.securityEnabled && !isLoading && (
            <Button onClick={handleUpdateSecurity}>Enable Security</Button>
          )}
        </CommonFormControl>
      </CardContent>
    </Card>
  );
};
