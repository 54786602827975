import { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';

import { USER_QUALIFY_STATUS } from '@/config/constants';
import { getproductOwner } from '@/utils/product';
import { Product } from '@/types/ProductType';

type ProductDetailNoEstimationProps = {
  product: Product;
};

export const ProductDetailNoEstimation = ({
  product,
}: ProductDetailNoEstimationProps) => {
  const theme = useTheme();

  if (!product) return null;

  const checkItems = useMemo(() => {
    const user = getproductOwner(product);
    const items = [
      {
        label: 'Click See if I qualify',
        value: user?.qualifyStatus !== USER_QUALIFY_STATUS.INITIAL,
      },
      {
        label: 'Fill in all loan information',
        value: Boolean(product.loanInfo?.zipCode),
      },
      {
        label: 'Fill in all income information',
        value: Boolean(product.user?.loanInfo?.monthlyIncome),
      },
    ];
    return items;
  }, [product]);

  return (
    <Card>
      <CardHeader
        title="Can't estimate"
        avatar={<i className="fa-light fa-calendar-circle-exclamation" />}
      />
      <CardContent>
        <Box>
          <Typography variant="body1">
            We can't calculate the cost estimation at this moment. Please fill
            in all fields and add your property to Qualify.
          </Typography>
          {checkItems.map((item, idx) => (
            <Box
              key={idx}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                ml: 1,
                mt: 2,
              }}
            >
              {item.value ? (
                <i className="fa-light fa-check color-success" />
              ) : (
                <i className="fa-light fa-close color-error" />
              )}
              <Typography
                sx={{ color: theme.palette.greyShades.grey1 }}
                variant="body2"
              >
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
