import { calculateClosingDisclosure } from '@/utils/calculator';
import { User } from './UserType';

export interface GetProductsRequest {
  offset?: number;
  limit?: number;
  contractStarted?: boolean;
}

export interface GetProductsResponse {
  products: Product[];
  isEnd: boolean;
}

export interface UpdateProductRequest {
  id: string;
  name?: string;
  description?: string;
  photo?: string;
  photos?: string[];
  price?: number;
  note?: string;

  zipCode?: number;
  offerPrice?: number;
  hoaFees?: number;
  commission?: number;
  rent?: number;
  experience?: number;
  rehab?: number;
  arv?: number;
}

export interface GetProductsByAdminRequest {
  offset?: number;
  limit?: number;
}

export interface GetProductsByAdminResponse {
  products: Product[];
  count: number;
}

export interface ProductLoanInfo {
  zipCode: number;
  offerPrice: number;
  hoaFees: number;
  commission: number;
  rent: number;
  experience: number;
  rehab: number;
  arv: number;
}

export interface Product {
  _id: string;
  name: string;
  price: number;
  photo: string;
  photos: string[];
  url: string;
  domain: string;
  description: string;
  note: string;
  loanInfo: ProductLoanInfo;
  user?: User;
  contract?: string;
  contractStarted?: boolean;
  createdAt: string;
  updatedAt: string;
}

export type ProductEsitmation = ReturnType<typeof calculateClosingDisclosure>;

export enum PRODUCT_PRICE_MATCH_STATUS {
  AFFORDABLE = 'Affordable',
  CLOSE_MATCH = 'Close Match',
  NOT_AFFORDABLE = 'Not Affordable',
}

export enum PRODUCT_QUALIFY_STATUS {
  QUALIFIED = 'Qualified',
  UNQUALIFIED = 'Unqualified',
}
export enum PRODUCT_APPROVE_STATUS {
  APPROVED = 'Approved',
  NOT_APPROVED = 'Not Approved',
}
