import { useState } from 'react';
import { Box, IconButton, Link, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useRemoveProductMutation } from '@/apis/extended/productApi';
import { formatNumber } from '@/utils/helper';
import { Product } from '@/types/ProductType';
import { RemoveProductModal } from '../ConfirmModals/RemoveProductModal';
import { ProductBadges } from '../ProductDetail/ProductBadges';
import { ProductCardImage } from './ProductCardImage';

type ProductCardProps = {
  product: Product;
  isContract?: boolean;
};

export const ProductCard = ({
  product,
  isContract = false,
}: ProductCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [removeProduct] = useRemoveProductMutation();

  const [isRemoveProductModalOpen, setIsRemoveProductModalOpen] =
    useState(false);

  const goDetailPage = () => {
    if (isContract) {
      return navigate('/contract/' + product._id);
    }
    navigate('/product/' + product._id);
  };

  const handleRemoveProduct = () => {
    removeProduct(product._id);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={goDetailPage}
      >
        <ProductCardImage product={product} />
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            position: 'absolute',
            top: '16px',
            right: '16px',
            zIndex: 1,
          }}
        >
          <ProductBadges product={product} />
        </Box>
        <Box
          sx={{
            mt: 1,
            px: 1,
            minHeight: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">$ {formatNumber(product.price)}</Typography>
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Link
              href={product.url}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              <IconButton
                sx={{
                  color: theme.palette.greyShades.grey1,
                }}
                size="small"
              >
                <i className="fa-light fa-link" />
              </IconButton>
            </Link>
            <IconButton
              sx={{
                color: theme.palette.mainColors.secondary,
              }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsRemoveProductModalOpen(true);
              }}
            >
              <i className="fa-light fa-trash-can" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ mt: '4px' }}>
          <Typography
            variant="h5"
            sx={{ color: theme.palette.greyShades.grey1 }}
          >
            {product.name}
          </Typography>
        </Box>
      </Box>
      <RemoveProductModal
        isOpen={isRemoveProductModalOpen}
        onClose={() => setIsRemoveProductModalOpen(false)}
        onConfirm={handleRemoveProduct}
      />
    </>
  );
};
