import {
  GetProductsRequest,
  GetProductsResponse,
  Product,
  UpdateProductRequest,
} from '@/types/ProductType';
import { rtkApi } from '../rtkApi';

export const productApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<GetProductsResponse, GetProductsRequest>({
      query: (query) => ({ url: `/product`, params: query }),
      providesTags: ['Product'],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newResponse, { arg }) => {
        if (currentCache?.products?.length && arg.offset) {
          return {
            products: [...currentCache.products, ...newResponse.products],
            isEnd: newResponse.isEnd,
          };
        }
        return newResponse;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.offset !== previousArg?.offset ||
          currentArg?.contractStarted !== previousArg?.contractStarted
        );
      },
    }),
    getProduct: builder.query<Product, string>({
      query: (productId) => ({ url: `/product/${productId}` }),
      providesTags: (_response, _result, productId) => [
        { type: 'Product', id: productId },
      ],
    }),
    updateProduct: builder.mutation<void, UpdateProductRequest>({
      query: (payload) => ({
        url: `/product/${payload.id}`,
        method: 'PUT',
        data: payload,
      }),
      invalidatesTags: (_response, _result, payload) => [
        { type: 'Product', id: payload.id },
      ],
    }),
    startContract: builder.mutation<void, string>({
      query: (productId) => ({
        url: `/product/${productId}/contract`,
        method: 'POST',
      }),
      invalidatesTags: (_response, _result, productId) => [
        { type: 'Product', id: productId },
        { type: 'Product' },
      ],
    }),
    removeProduct: builder.mutation<void, string>({
      query: (productId) => ({
        url: `/product/${productId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(productId, { queryFulfilled, dispatch, getState }) {
        try {
          await queryFulfilled;
          const cache = productApi.util.selectInvalidatedBy(getState(), [
            { type: 'Product' },
          ]);
          cache
            .filter(({ endpointName }) => endpointName === 'getProducts')
            .forEach(({ originalArgs }) => {
              dispatch(
                productApi.util.updateQueryData(
                  'getProducts',
                  originalArgs,
                  (data) => {
                    data.products = data.products.filter(
                      (product: Product) => product._id !== productId
                    );
                    return data;
                  }
                )
              );
            });
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useUpdateProductMutation,
  useStartContractMutation,

  useRemoveProductMutation,
} = productApi;
