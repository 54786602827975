import { Card, CardContent, CardHeader } from '@mui/material';

import { USER_LOAN_PROGRAM } from '@/config/constants';
import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { ProductFixAndFlipEsitmation } from '@/utils/calculator/FixAndFlip';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailProfitabilityProps = {
  product: Product;
};

export const ProductDetailProfitability = ({
  product,
}: ProductDetailProfitabilityProps) => {
  const { estimation } = useEstimation(product);
  const fixAndFlipEstimation = estimation as ProductFixAndFlipEsitmation;

  if (
    !fixAndFlipEstimation ||
    fixAndFlipEstimation.type !== USER_LOAN_PROGRAM.FIX_AND_FLIP
  )
    return null;

  return (
    <Card>
      <CardHeader
        title="Calculating Profitability"
        avatar={<i className="fa-light fa-coin" />}
      />
      <CardContent>
        <CommonFormControl
          label="Total Cost:"
          secondLabel={formatPrice(fixAndFlipEstimation.totalCost)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Profit Margin at ARV:"
          secondLabel={formatPrice(fixAndFlipEstimation.profitMargin)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Marginal Profit with Unbeatable Offer:"
          secondLabel={formatPrice(
            fixAndFlipEstimation.totalCost -
              fixAndFlipEstimation.unbeatableCredit
          )}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
