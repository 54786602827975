import { ReactNode, useState } from 'react';
import { Box, Button, Container, IconButton, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  HEADER_HEIGHT,
  USER_APPROVE_STATUS,
  USER_QUALIFY_STATUS,
  Z_INDEX,
} from '@/config/constants';
import { useGetCurrentUserQuery } from '@/apis/extended/userApi';
import { SigninModal } from '@/components/Auth/SigninModal';
import { SignOutModal } from '@/components/Auth/SignOutModal';
import { Logo } from '@/components/Logo/Logo';
import { UserActionButton } from '@/components/UserAction/UserActionButton';
import { useAppLayout } from '@/hooks/useAppLayout';
import { useAuth } from '@/hooks/useAuth';
import { useDevice } from '@/hooks/useDevice';

const NavIconButton = ({
  url,
  children,
}: {
  url: string;
  children: ReactNode;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === url;

  return (
    <IconButton
      onClick={() => navigate(url)}
      sx={{
        color: isActive
          ? theme.palette.mainColors.primary
          : theme.palette.greyShades.grey1,
        position: 'relative',
        '&::after': {
          content: isActive ? '""' : 'none',
          position: 'absolute',
          left: 0,
          bottom: '0px',
          width: '100%',
          height: '2px',
          backgroundColor: theme.palette.mainColors.primary,
        },
      }}
    >
      {children}
    </IconButton>
  );
};

export const AppHeader = () => {
  const theme = useTheme();
  const { isAuthenticated, isAdmin } = useAuth();
  const { data: user } = useGetCurrentUserQuery();
  const { headerHeight } = useAppLayout();
  const { isMobile } = useDevice();

  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);

  const openSigninModal = () => {
    setIsSignInModalOpen(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.mainColors.background1,
        boxShadow: `0 0 4px 1px ${theme.palette.mainColors.background}`,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: Z_INDEX.HEADER,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          height: `${HEADER_HEIGHT}px`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: '12px', md: '36px' },
          }}
        >
          <Logo />
          {isAuthenticated && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <NavIconButton url="/main">
                <i className="fa-light fa-dumpster" />
              </NavIconButton>
              {user?.qualifyStatus === USER_QUALIFY_STATUS.QUALIFY && (
                <NavIconButton url="/qualified">
                  <i className="fa-light fa-check-to-slot" />
                </NavIconButton>
              )}
              {user?.approveStatus === USER_APPROVE_STATUS.APPROVED && (
                <NavIconButton url="/contract">
                  <i className="fa-light fa-file-contract" />
                </NavIconButton>
              )}
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {isAuthenticated ? (
            <>
              {!isMobile && <UserActionButton />}
              {isAdmin && (
                <NavIconButton url="/admin/user">
                  <i className="fa-light fa-crown" />
                </NavIconButton>
              )}
              <NavIconButton url="/profile">
                <i className="fa-light fa-user" />
              </NavIconButton>
              <IconButton
                sx={{ color: theme.palette.greyShades.grey1 }}
                onClick={() => setIsSignOutModalOpen(true)}
              >
                <i className="fa-light fa-arrow-right-from-bracket" />
              </IconButton>
            </>
          ) : (
            <>
              <Button variant="text" onClick={openSigninModal}>
                Sign In
              </Button>
              <Button variant="contained" onClick={openSigninModal}>
                Get Started
              </Button>
            </>
          )}
        </Box>
      </Container>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: headerHeight - HEADER_HEIGHT + 'px',
        }}
      >
        {isMobile && <UserActionButton sx={{ width: '100%' }} />}
      </Container>
      <SigninModal
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <SignOutModal
        isOpen={isSignOutModalOpen}
        onClose={() => setIsSignOutModalOpen(false)}
      />
    </Box>
  );
};
