import { Card, CardContent, CardHeader } from '@mui/material';

import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailClosingCostsProps = {
  product: Product;
};

export const ProductDetailClosingCosts = ({
  product,
}: ProductDetailClosingCostsProps) => {
  const { estimation } = useEstimation(product);

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Costs at Closing"
        avatar={<i className="fa-light fa-mask" />}
      />
      <CardContent>
        <CommonFormControl
          label="Estimated Costs prior to Closing:"
          secondLabel={formatPrice(estimation.cashOutsideClosing)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Estimated Closing Costs:"
          secondLabel={formatPrice(estimation.closingCosts)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Estimated Cash to Close:"
          secondLabel={formatPrice(
            estimation.closingCosts + estimation.downPayment
          )}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
