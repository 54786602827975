import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import {
  CONTRACT_TITLE_COMPANY_CHOICE_TIMELINE,
  CONTRACT_TRANSFER_TAX_ELECTION,
  contractTitleCompanyChoiceTimelines,
  contractTransferTaxElections,
} from '@/config/constants';
import {
  useGetContractQuery,
  useUpdateContractBuyerMutation,
} from '@/apis/extended/contractApi';
import { ContractThirdPartyTitleSettlement } from '@/types/ContractType';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';

const initialForm: ContractThirdPartyTitleSettlement = {
  titleCompany: '',
  titleCompanyChoiceTimeline:
    CONTRACT_TITLE_COMPANY_CHOICE_TIMELINE.NOT_SELECTED,
  transferTaxElection: CONTRACT_TRANSFER_TAX_ELECTION.NOT_SELECTED,
};

type ContractDetailBuyerTitleCompanyProps = {
  contractId: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailBuyerTitleCompany = ({
  contractId,
  onPrev,
  onNext,
}: ContractDetailBuyerTitleCompanyProps) => {
  const theme = useTheme();
  const { data: contract } = useGetContractQuery(contractId);
  const [updateContractBuyer, { isLoading }] = useUpdateContractBuyerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const thirdPartyTitleSettlement = contract?.thirdPartyTitleSettlement;
    if (!thirdPartyTitleSettlement) return;
    setForm({
      titleCompany: thirdPartyTitleSettlement.titleCompany,
      titleCompanyChoiceTimeline:
        thirdPartyTitleSettlement.titleCompanyChoiceTimeline,
      transferTaxElection: thirdPartyTitleSettlement.transferTaxElection,
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return form.titleCompanyChoiceTimeline && form.transferTaxElection;
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractBuyer({
      contractId,
      thirdPartyTitleSettlement: {
        ...form,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Title/Settlement"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-buildings" />}
        />
        <CardContent>
          <CommonFormControl label="Title Company (Optional):" labelWidth={200}>
            <TextField
              placeholder="Title Company"
              name="titleCompany"
              fullWidth
              value={form.titleCompany}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Title Company Choice Timeline:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="titleCompanyChoiceTimeline"
              value={form.titleCompanyChoiceTimeline}
              onChange={handleInputChange}
            >
              {contractTitleCompanyChoiceTimelines.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Transfer Tax Election:</Typography>
            <Typography
              variant="body2"
              sx={{ mt: 2, color: theme.palette.greyShades.grey1 }}
            >
              Transfer Taxes are typically split between buyer and seller. In
              this case, the seller is to pay the transfer taxes as a function
              of the seller paying all the closing costs.
            </Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="transferTaxElection"
              value={form.transferTaxElection}
              onChange={handleInputChange}
            >
              {contractTransferTaxElections.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
