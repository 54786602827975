import { CommonConfirmDialog } from '@/components/Common/CommonConfirmDialog';

type RemoveAgentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const RemoveAgentModal = ({
  isOpen,
  onClose,
  onConfirm,
}: RemoveAgentModalProps) => {
  return (
    <CommonConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      icon="fa-light fa-trash-can"
      title="Remove Agent?"
      content="Are you sure you want to remove this agent?"
      confirmButtonText="Remove"
      onConfirm={onConfirm}
    />
  );
};
