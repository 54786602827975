import { USER_ROLE } from '@/config/constants';
import { useGetCurrentUserQuery } from '@/apis/extended/userApi';

export const useAuth = () => {
  const { data: user, isLoading } = useGetCurrentUserQuery();
  const isAuthenticated = Boolean(user?._id);
  const isAdmin = user?.role === USER_ROLE.ADMIN;

  return { isLoading, isAuthenticated, isAdmin };
};
