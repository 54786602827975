import { useMemo, useState } from 'react';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';

import { DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE } from '@/config/constants';
import { useGetFeedbacksByAdminQuery } from '@/apis/extended/admin/feedbackApi';
import { CommonDataGrid } from '@/components/Common/CommonDataGrid';
import { Loader } from '@/components/Layout/Loader';
import { Feedback } from '@/types/GlobalType';

export const ManageFeedback = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE);

  const { data, isLoading, isFetching } = useGetFeedbacksByAdminQuery({
    offset,
    limit,
  });

  const columns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          minWidth: 200,
        },
        {
          field: 'email',
          headerName: 'Email',
          minWidth: 200,
        },
        {
          field: 'content',
          headerName: 'Content',
          flex: 1,
        },
      ] as GridColDef<Feedback>[],
    []
  );

  const onPaginationChanged = ({ page, pageSize }: GridPaginationModel) => {
    setOffset(page * pageSize);
    setLimit(pageSize);
  };

  if (isLoading || !data) return <Loader isFull />;

  return (
    <CommonDataGrid
      columns={columns}
      rows={data.feedbacks}
      rowCount={data.count}
      paginationModel={{ page: offset / limit, pageSize: limit }}
      onPaginationModelChange={onPaginationChanged}
      loading={isFetching}
    />
  );
};
