import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { useDevice } from '@/hooks/useDevice';
import { LogoSvg } from '@/assets/icons/LogoSvg';
import { LogoTextSvg } from '@/assets/icons/LogoTextSvg';

export const Logo = () => {
  const { isMobile } = useDevice();
  return (
    <Link to="/">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          textDecoration: 'none',
        }}
      >
        {isMobile ? <LogoSvg /> : <LogoTextSvg />}
      </Box>
    </Link>
  );
};
