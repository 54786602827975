import { rtkApi } from '@/apis/rtkApi';
import {
  GetUsersByAdminRequest,
  GetUsersByAdminResponse,
  UpdateUserByAdminRequest,
  User,
} from '@/types/UserType';

export const userApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersByAdmin: builder.query<
      GetUsersByAdminResponse,
      GetUsersByAdminRequest
    >({
      query: (payload) => ({ url: `/admin/user`, params: payload }),
      providesTags: ['Admin User'],
    }),
    getUserByAdmin: builder.query<User, string>({
      query: (userId) => ({ url: `/admin/user/${userId}` }),
      providesTags: (_response, _result, userId) => [
        { type: 'Admin User', id: userId },
      ],
    }),
    updateUserByAdmin: builder.mutation<void, UpdateUserByAdminRequest>({
      query: (request) => ({
        url: `/admin/user/${request.id}`,
        method: 'PUT',
        data: request,
      }),
      invalidatesTags: (_response, _result, request) => [
        'Admin User',
        { type: 'Admin User', id: request.id },
      ],
    }),
    removeUserByAdmin: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/admin/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Admin User'],
    }),
  }),
});

export const {
  useGetUserByAdminQuery,
  useGetUsersByAdminQuery,
  useUpdateUserByAdminMutation,
  useRemoveUserByAdminMutation,
} = userApi;
