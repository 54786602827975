import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { ManageEmailTable } from '@/components/Admin/ManageEmail/ManageEmailTable';
import { AddEmailModal } from '@/components/ConfirmModals/AddEmailModal';

export const AdminEmail = () => {
  const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px',
          flexWrap: {
            xs: 'wrap',
            sm: 'nowrap',
          },
        }}
      >
        <Typography variant="h1">Manage Emails</Typography>
        <Button
          sx={{ width: { xs: '100%', sm: 'unset' } }}
          endIcon={<i className="fa-light fa-plus" />}
          onClick={() => setIsAddEmailModalOpen(true)}
        >
          Add Email
        </Button>
      </Box>
      <Box mt={3}>
        <ManageEmailTable />
      </Box>
      <AddEmailModal
        isOpen={isAddEmailModalOpen}
        onClose={() => setIsAddEmailModalOpen(false)}
      />
    </Box>
  );
};
