import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import {
  CONTRACT_COPY_OF_INSPECTION,
  CONTRACT_INSPECTION_PRESENCE_OPTION,
  contractCopyOfInspections,
  contractInspectionPresenceOptions,
} from '@/config/constants';
import {
  useGetContractQuery,
  useUpdateContractBuyerMutation,
} from '@/apis/extended/contractApi';
import { parseString } from '@/utils/helper';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonGapInput } from '../Common/CommonGapInput';

const initialForm = {
  buyerWaivesInspection: false,
  inspectionCompany: '',
  inspectionReportCompletedBy: '',

  copyOfInspection: CONTRACT_COPY_OF_INSPECTION.NOT_SELECTED,
  presenceOption: CONTRACT_INSPECTION_PRESENCE_OPTION.NOT_SELECTED,

  enableBuyerNegotiateReleaseDays: false,
  buyerNegotiateReleaseDays: '',
  enableBuyerNegotiateCreditsPriceDays: false,
  buyerNegotiateCreditsPriceDays: '',
  enableBuyerNegotiateCreditsCostsDays: false,
  buyerNegotiateCreditsCostsDays: '',
};

type ContractDetailBuyerInspectionProps = {
  contractId: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailBuyerInspection = ({
  contractId,
  onPrev,
  onNext,
}: ContractDetailBuyerInspectionProps) => {
  const { data: contract } = useGetContractQuery(contractId);
  const [updateContractBuyer, { isLoading }] = useUpdateContractBuyerMutation();

  const [form, setForm] = useState(initialForm);
  const isProvidingFullAccess =
    form.copyOfInspection === CONTRACT_COPY_OF_INSPECTION.PROVIDE_FULL_COPY;

  useEffect(() => {
    const thirdPartyInspection = contract?.thirdPartyInspection;
    if (!thirdPartyInspection) return;
    setForm({
      buyerWaivesInspection: thirdPartyInspection.buyerWaivesInspection,
      inspectionCompany: thirdPartyInspection.inspectionCompany,
      inspectionReportCompletedBy:
        thirdPartyInspection.inspectionReportCompletedBy,
      copyOfInspection: thirdPartyInspection.copyOfInspection,
      presenceOption: thirdPartyInspection.presenceOption,

      enableBuyerNegotiateReleaseDays:
        thirdPartyInspection.enableBuyerNegotiateReleaseDays,
      buyerNegotiateReleaseDays: parseString(
        thirdPartyInspection.buyerNegotiateReleaseDays
      ),
      enableBuyerNegotiateCreditsPriceDays:
        thirdPartyInspection.enableBuyerNegotiateCreditsPriceDays,
      buyerNegotiateCreditsPriceDays: parseString(
        thirdPartyInspection.buyerNegotiateCreditsPriceDays
      ),
      enableBuyerNegotiateCreditsCostsDays:
        thirdPartyInspection.enableBuyerNegotiateCreditsCostsDays,
      buyerNegotiateCreditsCostsDays: parseString(
        thirdPartyInspection.buyerNegotiateCreditsCostsDays
      ),
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return (
      form.inspectionReportCompletedBy &&
      form.copyOfInspection &&
      (form.copyOfInspection ===
        CONTRACT_COPY_OF_INSPECTION.PROVIDE_FULL_COPY ||
        form.presenceOption)
    );
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractBuyer({
      contractId,
      thirdPartyInspection: {
        ...form,
        buyerNegotiateReleaseDays: form.buyerNegotiateReleaseDays
          ? Number(form.buyerNegotiateReleaseDays)
          : undefined,
        buyerNegotiateCreditsPriceDays: form.buyerNegotiateCreditsPriceDays
          ? Number(form.buyerNegotiateCreditsPriceDays)
          : undefined,
        buyerNegotiateCreditsCostsDays: form.buyerNegotiateCreditsCostsDays
          ? Number(form.buyerNegotiateCreditsCostsDays)
          : undefined,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Inspection"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-binoculars" />}
        />
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.buyerWaivesInspection}
                name="buyerWaivesInspection"
                onChange={handleCheckboxChange}
              />
            }
            label="Buyer waives right to home inspection"
          />
          <CommonFormControl
            sx={{ mt: 4 }}
            label="Inspection Company:"
            labelWidth={250}
          >
            <TextField
              placeholder="Inspection Company"
              name="inspectionCompany"
              fullWidth
              value={form.inspectionCompany}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <CommonFormControl
            label="Inspection Report Completed by:"
            labelWidth={250}
            sx={{ mt: 2 }}
          >
            <TextField
              placeholder="Inspection Report Completed by"
              name="inspectionReportCompletedBy"
              type="date"
              fullWidth
              value={form.inspectionReportCompletedBy}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <Box sx={{ mt: 4 }}>
            <RadioGroup
              sx={{ mt: 1 }}
              name="copyOfInspection"
              value={form.copyOfInspection}
              onChange={handleInputChange}
            >
              {contractCopyOfInspections.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <RadioGroup
              sx={{ mt: 1 }}
              name="presenceOption"
              value={form.presenceOption}
              onChange={handleInputChange}
            >
              {contractInspectionPresenceOptions.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  disabled={isProvidingFullAccess}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.enableBuyerNegotiateReleaseDays}
                  disabled={isProvidingFullAccess}
                  name="enableBuyerNegotiateReleaseDays"
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <>
                  Buyer reserves right to negotiate with release of contract for
                  up to{' '}
                  <CommonGapInput
                    type="number"
                    disabled={
                      !form.enableBuyerNegotiateReleaseDays ||
                      isProvidingFullAccess
                    }
                    value={form.buyerNegotiateReleaseDays}
                    name="buyerNegotiateReleaseDays"
                    onChange={handleInputChange}
                  />{' '}
                  days after inspection timeline.
                </>
              }
            />
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Checkbox
                  checked={form.enableBuyerNegotiateCreditsPriceDays}
                  disabled={isProvidingFullAccess}
                  name="enableBuyerNegotiateCreditsPriceDays"
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <>
                  Buyer reserves right to negotiate credits to price for up to
                  <CommonGapInput
                    type="number"
                    disabled={
                      !form.enableBuyerNegotiateCreditsPriceDays ||
                      isProvidingFullAccess
                    }
                    value={form.buyerNegotiateCreditsPriceDays}
                    name="buyerNegotiateCreditsPriceDays"
                    onChange={handleInputChange}
                  />{' '}
                  days after inspection timeline.
                </>
              }
            />
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Checkbox
                  checked={form.enableBuyerNegotiateCreditsCostsDays}
                  disabled={isProvidingFullAccess}
                  name="enableBuyerNegotiateCreditsCostsDays"
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <>
                  Buyer reserves right to negotiate credits to costs for up to
                  <CommonGapInput
                    type="number"
                    disabled={
                      !form.enableBuyerNegotiateCreditsCostsDays ||
                      isProvidingFullAccess
                    }
                    value={form.buyerNegotiateCreditsCostsDays}
                    name="buyerNegotiateCreditsCostsDays"
                    onChange={handleInputChange}
                  />{' '}
                  days after inspection timeline.
                </>
              }
            />
          </Box>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
