import { useState } from 'react';
import { Button, SxProps } from '@mui/material';

import { USER_APPROVE_STATUS } from '@/config/constants';
import {
  useGetCurrentUserQuery,
  useSendApproveRequestMutation,
} from '@/apis/extended/userApi';
import { GetApproveModal } from '../ConfirmModals/GetApproveModal';

type GetApproveButtonProps = {
  sx?: SxProps;
};

export const GetApproveButton = ({ sx }: GetApproveButtonProps) => {
  const { data: user } = useGetCurrentUserQuery();
  const [sendApproveRequest] = useSendApproveRequestMutation();

  const [isGetApproveModalOpen, setIsGetApproveModalOpen] = useState(false);

  const handleSubmit = () => {
    if (!user?.phone || !user?.documents?.length) {
      setIsGetApproveModalOpen(true);
      return;
    }

    sendApproveRequest();
    window.open('http://jpastre.com/', '_blank');
  };

  return (
    <>
      <Button
        size="small"
        color="success"
        sx={sx}
        startIcon={<i className="fa-light fa-paper-plane" />}
        onClick={handleSubmit}
        disabled={user?.approveStatus === USER_APPROVE_STATUS.PENDING}
      >
        Get Approved
      </Button>
      <GetApproveModal
        isOpen={isGetApproveModalOpen}
        onClose={() => setIsGetApproveModalOpen(false)}
      />
    </>
  );
};
