import { useCallback, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowModel,
} from '@mui/x-data-grid';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE } from '@/config/constants';
import { useGetProductsByAdminQuery } from '@/apis/extended/admin/productApi';
import {
  useRemoveProductMutation,
  useUpdateProductMutation,
} from '@/apis/extended/productApi';
import { CommonEditIconButton } from '@/components/Common/CommonButton/CommonEditIconButton';
import { CommonRemoveIconButton } from '@/components/Common/CommonButton/CommonRemoveIconButton';
import { CommonDataGrid } from '@/components/Common/CommonDataGrid';
import { DataGridIconCell } from '@/components/Common/CommonDataGrid/DataGridIconCell';
import { DataGridTextField } from '@/components/Common/CommonDataGrid/DataGridTextField';
import { CommonImage } from '@/components/Common/CommonImage';
import { RemoveProductModal } from '@/components/ConfirmModals/RemoveProductModal';
import { Loader } from '@/components/Layout/Loader';
import { formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

export const ManageProductTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE);
  const [removingProduct, setRemovingProduct] = useState<Product | null>(null);
  const isRemoveProductModalOpen = Boolean(removingProduct);

  const { data, isLoading, isFetching } = useGetProductsByAdminQuery({
    offset,
    limit,
  });
  const [updateProductByAdmin] = useUpdateProductMutation();
  const [removeProductByAdmin] = useRemoveProductMutation();

  const columns = useMemo(
    () =>
      [
        {
          field: 'photo',
          headerName: '',
          width: 150,
          renderCell: (params) => {
            return (
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '4 / 3',
                  borderRadius: '8px',
                  my: 1,
                  border: '1px solid',
                  borderColor: theme.palette.mainColors.primary,
                  overflow: 'hidden',
                }}
              >
                <CommonImage photo={params.value} />
              </Box>
            );
          },
        },
        {
          field: 'name',
          headerName: 'Name',
          minWidth: 300,
          flex: 1,
          editable: true,
          renderEditCell: (params) => {
            return <DataGridTextField {...params} />;
          },
        },
        {
          field: 'price',
          headerName: 'Price',
          width: 120,
          editable: true,
          renderCell: (params) => {
            return `${formatPrice(params.value)}`;
          },
          renderEditCell: (params) => {
            return <DataGridTextField {...params} type="number" />;
          },
        },
        {
          field: 'user',
          headerName: 'Owner',
          width: 180,
          renderCell: (params) => {
            return params.value?.email;
          },
        },
        {
          field: 'contract',
          headerName: 'Contract',
          width: 80,
          renderCell: (params) => {
            return (
              <DataGridIconCell
                status={Boolean(params.value)}
                icon="fa-badge-check"
              />
            );
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          type: 'actions',
          getActions: (params) => [
            <CommonEditIconButton
              onClick={() => navigate(`/admin/product/${params.id}`)}
            />,
            <CommonRemoveIconButton
              onClick={() => setRemovingProduct(params.row)}
            />,
          ],
        },
      ] as GridColDef<Product>[],
    []
  );

  const onPaginationChanged = ({ page, pageSize }: GridPaginationModel) => {
    setOffset(page * pageSize);
    setLimit(pageSize);
  };

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const isUpdated = !_.isEqual(newRow, oldRow);
        if (isUpdated) {
          updateProductByAdmin({
            id: newRow._id,
            ..._.pick(newRow, ['name', 'price']),
          })
            .unwrap()
            .then(() => resolve(newRow))
            .catch(() => resolve(oldRow));
        } else {
          resolve(oldRow);
        }
      }),
    []
  );

  const handleRemoveProduct = () => {
    if (!removingProduct) return;
    removeProductByAdmin(removingProduct._id);
    setRemovingProduct(null);
  };

  if (isLoading || !data) return <Loader isFull />;

  return (
    <>
      <CommonDataGrid
        columns={columns}
        rows={data.products}
        rowCount={data.count}
        getRowHeight={() => 'auto'}
        paginationModel={{ page: offset / limit, pageSize: limit }}
        onPaginationModelChange={onPaginationChanged}
        loading={isFetching}
        processRowUpdate={processRowUpdate}
      />
      <RemoveProductModal
        isOpen={isRemoveProductModalOpen}
        onClose={() => setRemovingProduct(null)}
        onConfirm={handleRemoveProduct}
      />
    </>
  );
};
