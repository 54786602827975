import { Box, Typography, useTheme } from '@mui/material';

export const VerifyFailed = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        minHeight: '70vh',
        py: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <i
        className="fa-light fa-circle-xmark"
        style={{ color: theme.palette.error.main, fontSize: '96px' }}
      />
      <Typography variant="h1" sx={{ mt: 4 }}>
        Error!
      </Typography>
      <Typography variant="h4" sx={{ mt: 2 }}>
        Your email address could not be verified.
      </Typography>
    </Box>
  );
};
