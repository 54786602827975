import { useState } from 'react';
import { Box, List, ListItem, Typography, useTheme } from '@mui/material';

import {
  S3_FOLDER,
  userLoanEstimationTypeMap,
  userLoanProgramMap,
  userOccupancyMap,
} from '@/config/constants';
import { useGetSignedUrlMutation } from '@/apis/extended/globalApi';
import {
  useAddDocumentMutation,
  useGetCurrentUserQuery,
  useRemoveDocumentMutation,
} from '@/apis/extended/userApi';
import { getRequiredDocuments } from '@/utils/document';
import { uploadFile } from '@/utils/storage';
import { CommonDialog } from '../Common/CommonDialog';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonMultipleFileInput } from '../Common/CommonMultipleFileInput';

type ManageDocumentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ManageDocumentsModal = ({
  isOpen,
  onClose,
}: ManageDocumentsModalProps) => {
  const theme = useTheme();
  const { data } = useGetCurrentUserQuery();

  const [getSignedUrl] = useGetSignedUrlMutation();
  const [addDocument] = useAddDocumentMutation();
  const [removeDocument] = useRemoveDocumentMutation();

  const [isUpdatingDocument, setIsUpdatingDocument] = useState(false);

  if (!data) return;

  const documents = getRequiredDocuments(data);

  const handleAddDocument = async (file: File | null) => {
    if (!file) return;
    let documentUrl;
    setIsUpdatingDocument(true);
    try {
      const signedUrl = await getSignedUrl({
        folderName: S3_FOLDER.DOCUMENT,
        fileName: file.name,
      }).unwrap();
      if (signedUrl) {
        documentUrl = await uploadFile(signedUrl, file);
      }

      if (!documentUrl) return;

      await addDocument(documentUrl).unwrap();
    } catch (err) {
      console.error(err);
    }
    setIsUpdatingDocument(false);
  };

  const handleRemoveDocument = async (url: string) => {
    setIsUpdatingDocument(true);
    await removeDocument(url).unwrap();
    setIsUpdatingDocument(false);
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      title="Manage Documents"
      icon="fa-light fa-file"
      onClose={onClose}
    >
      {data.loanEstimationType && (
        <CommonFormControl
          label="Loan Estimation:"
          labelWidth={150}
          secondLabel={userLoanEstimationTypeMap[data.loanEstimationType]}
        />
      )}
      {data.loanProgram && (
        <CommonFormControl
          label="Loan Program:"
          labelWidth={150}
          secondLabel={userLoanProgramMap[data.loanProgram]}
        />
      )}
      {data.occupancy && (
        <CommonFormControl
          label="Occupancy:"
          labelWidth={150}
          secondLabel={userOccupancyMap[data.occupancy]}
        />
      )}
      <Typography variant="h5" mt={2}>
        Required Documents:
      </Typography>
      <List>
        {documents.map((label, idx) => (
          <ListItem key={idx}>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.greyShades.grey1 }}
            >
              {idx + 1}. {label}
            </Typography>
          </ListItem>
        ))}
      </List>

      <Box sx={{ mt: 2 }}>
        <CommonMultipleFileInput
          urls={data?.documents}
          onFileChange={handleAddDocument}
          isLoading={isUpdatingDocument}
          label="Add new document"
          onRemove={handleRemoveDocument}
        />
      </Box>
    </CommonDialog>
  );
};
