import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import {
  CONTRACT_DEPOSIT_OPTION,
  contractDepositOptions,
} from '@/config/constants';
import {
  useGetContractQuery,
  useUpdateContractBuyerMutation,
} from '@/apis/extended/contractApi';
import { parseString } from '@/utils/helper';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonGapInput } from '../Common/CommonGapInput';

const initialForm = {
  closingDate: '',
  enableBuyerNegotiateAppraisalDays: false,
  buyerNegotiateAppraisalDays: '',

  depositOption: CONTRACT_DEPOSIT_OPTION.NOT_SELECTED,
  deposit1Days: '',
  deposit2Days: '',
};

type ContractDetailBuyerTimelineProps = {
  contractId: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailBuyerTimeline = ({
  contractId,
  onPrev,
  onNext,
}: ContractDetailBuyerTimelineProps) => {
  const { data: contract } = useGetContractQuery(contractId);
  const [updateContractBuyer, { isLoading }] = useUpdateContractBuyerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const timeline = contract?.timeline;
    if (!timeline) return;
    setForm({
      closingDate: timeline.closingDate,
      enableBuyerNegotiateAppraisalDays:
        timeline.enableBuyerNegotiateAppraisalDays,
      buyerNegotiateAppraisalDays: parseString(
        timeline.buyerNegotiateAppraisalDays
      ),

      depositOption: timeline.depositOption,
      deposit1Days: parseString(timeline.deposit1Days),
      deposit2Days: parseString(timeline.deposit2Days),
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return form.closingDate && form.depositOption;
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractBuyer({
      contractId,
      timeline: {
        ...form,
        buyerNegotiateAppraisalDays: form.buyerNegotiateAppraisalDays
          ? Number(form.buyerNegotiateAppraisalDays)
          : undefined,
        deposit1Days: form.deposit1Days ? Number(form.deposit1Days) : undefined,
        deposit2Days: form.deposit2Days ? Number(form.deposit2Days) : undefined,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Contract Timeline"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-timeline" />}
        />
        <CardContent>
          <CommonFormControl label="Closing Date:" labelWidth={150}>
            <TextField
              placeholder="Closing Date"
              name="closingDate"
              fullWidth
              type="date"
              value={form.closingDate}
              onChange={handleInputChange}
            />
          </CommonFormControl>
          <Box sx={{ mt: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.enableBuyerNegotiateAppraisalDays}
                  name="enableBuyerNegotiateAppraisalDays"
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <>
                  Buyer reserves the right to negotiate after receipt of
                  appraisal for{' '}
                  <CommonGapInput
                    type="number"
                    disabled={!form.enableBuyerNegotiateAppraisalDays}
                    value={form.buyerNegotiateAppraisalDays}
                    name="buyerNegotiateAppraisalDays"
                    onChange={handleInputChange}
                  />{' '}
                  days.
                </>
              }
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <RadioGroup
              sx={{ mt: 1 }}
              name="depositOption"
              value={form.depositOption}
              onChange={handleInputChange}
            >
              {contractDepositOptions.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={
                    <>
                      {item.label1}
                      {item.name && (
                        <CommonGapInput
                          type="number"
                          disabled={form.depositOption !== item.value}
                          value={form[item.name]}
                          name={item.name}
                          onChange={handleInputChange}
                        />
                      )}
                      {item.label2}
                    </>
                  }
                />
              ))}
            </RadioGroup>
          </Box>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
