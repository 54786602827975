import {
  Box,
  FormHelperText,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';

import { useDevice } from '@/hooks/useDevice';

type CommonFormControlProps = {
  label?: string;
  secondLabel?: string;
  labelWidth?: number;
  error?: string;
  children?: React.ReactNode;
  sx?: SxProps;
  inLine?: boolean;
};

export const CommonFormControl = ({
  label,
  secondLabel,
  labelWidth = 120,
  inLine,
  error,
  children,
  sx,
}: CommonFormControlProps) => {
  const theme = useTheme();
  const { isMobile } = useDevice();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          alignItems: 'flex-start',
          ...(isMobile && !inLine && { flexDirection: 'column', gap: '4px' }),
          ...sx,
        }}
      >
        <Typography
          sx={{
            width: `${labelWidth}px`,
            minHeight: '40px',
            color: theme.palette.greyShades.grey1,
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            ...(isMobile && { minHeight: 'unset', flexShrink: 'unset' }),
            ...(isMobile && !inLine && { width: 'unset' }),
          }}
          variant="body1"
        >
          {label}
        </Typography>
        {children}
        {secondLabel ? (
          <Typography
            sx={{
              minHeight: '40px',
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
              whiteSpace: 'nowrap',
              ...(isMobile && { minHeight: 'unset' }),
            }}
            variant="body1"
          >
            {secondLabel}
          </Typography>
        ) : (
          ''
        )}
      </Box>
      {error && (
        <Box
          sx={{
            mt: 1,
            ...((!isMobile || inLine) && { pl: `${labelWidth + 8}px` }),
          }}
        >
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
    </>
  );
};
