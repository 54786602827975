import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { ManageAgentTable } from '@/components/Admin/ManageAgent/ManageAgentTable';
import { AddAgentModal } from '@/components/ConfirmModals/AddAgentModal';

export const AdminAgent = () => {
  const [isAddAgentModalOpen, setIsAddAgentModalOpen] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px',
          flexWrap: {
            xs: 'wrap',
            sm: 'nowrap',
          },
        }}
      >
        <Typography variant="h1">Manage Agents</Typography>
        <Button
          sx={{ width: { xs: '100%', sm: 'unset' } }}
          endIcon={<i className="fa-light fa-plus" />}
          onClick={() => setIsAddAgentModalOpen(true)}
        >
          Add Agent
        </Button>
      </Box>
      <Box mt={3}>
        <ManageAgentTable />
      </Box>
      <AddAgentModal
        isOpen={isAddAgentModalOpen}
        onClose={() => setIsAddAgentModalOpen(false)}
      />
    </Box>
  );
};
