import { Box, Grid } from '@mui/material';

import { useGetProductQuery } from '@/apis/extended/productApi';
import { Loader } from '@/components/Layout/Loader';
import { ProductContractSummary } from '@/components/ProductContract/ProductContractSummary';
import { ProductDetailBasic } from '@/components/ProductDetail/ProductDetailBasic';
import { ProductDetailEstimation } from '@/components/ProductDetail/ProductDetailEstimation';
import { ProductDetailLogos } from '@/components/ProductDetail/ProductDetailLogos';
import { ProductDetailProjectedPayment } from '@/components/ProductDetail/ProductDetailProjectedPayment';
import { ProductDetailPropertySpecifics } from '@/components/ProductDetail/ProductDetailPropertySpecifics';

type ProductDetailProps = {
  productId: string;
};

export const ManageProductDetail = ({ productId }: ProductDetailProps) => {
  const { data: product, isFetching } = useGetProductQuery(productId);

  if (isFetching || !product) return <Loader isFull />;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ProductDetailLogos product={product} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductDetailBasic product={product} />
          {product.contract && (
            <Box sx={{ mt: 4 }}>
              <ProductContractSummary product={product} />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} mt={2}>
        <Grid item xs={12} md={6}>
          <ProductDetailPropertySpecifics product={product} />
          <Box
            sx={{
              position: { md: 'sticky' },
              top: '90px',
              mt: 2,
            }}
          >
            <ProductDetailProjectedPayment product={product} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductDetailEstimation product={product} />
        </Grid>
      </Grid>
    </>
  );
};
