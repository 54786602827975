import { useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmail } from 'validator';

import { useDeleteUserDataMutation } from '@/apis/extended/userApi';

export const DataRequest = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');

  const [deleteUserData, { isLoading, isSuccess }] =
    useDeleteUserDataMutation();

  const isValid = useMemo(() => {
    return isEmail(email);
  }, [email]);

  const handleSubmit = () => {
    if (!isValid) return;
    deleteUserData(email);
  };

  return (
    <Container
      sx={{
        py: '40px',
        minHeight: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          maxWidth: '450px',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h1">Request Data Deletion</Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            color: theme.palette.greyShades.grey1,
            lineHeight: '150%',
          }}
        >
          We value your privacy and understand that you may want to delete your
          personal data from our platform. Please fill in your email address
          below to request the deletion of your data.
        </Typography>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          {isSuccess ? (
            <Typography variant="h4" textAlign="center">
              Your request has been submitted.
              <br />
              Please check your email to confirm the deletion.
            </Typography>
          ) : (
            <>
              <Typography>Enter your email address:</Typography>
              <TextField
                placeholder="Email"
                name="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <LoadingButton
                variant="contained"
                disabled={!isValid}
                loading={isLoading}
                onClick={handleSubmit}
              >
                Submit Request
              </LoadingButton>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};
