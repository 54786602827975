import { Box } from '@mui/material';

import { USER_LOAN_PROGRAM } from '@/config/constants';
import { useEstimation } from '@/hooks/useEstimation';
import { useProductStatus } from '@/hooks/useProductStatus';
import {
  Product,
  PRODUCT_APPROVE_STATUS,
  PRODUCT_QUALIFY_STATUS,
} from '@/types/ProductType';
import { ProductDetailCashToClose } from './ProductDetailCards/ProductDetailCashToClose';
import { ProductDetailClosingCosts } from './ProductDetailCards/ProductDetailClosingCosts';
import { ProductDetailLoanCosts } from './ProductDetailCards/ProductDetailLoanCosts';
import { ProductDetailLoanTerms } from './ProductDetailCards/ProductDetailLoanTerms';
import { ProductDetailOtherCosts } from './ProductDetailCards/ProductDetailOtherCosts';
import { ProductDetailProfitability } from './ProductDetailCards/ProductDetailProfitability';
import { ProductDetailProjectedIncome } from './ProductDetailCards/ProductDetailProjectedIncome';
import { ProductDetailSubtotalClosingCosts } from './ProductDetailCards/ProductDetailSubtotalClosingCosts';
import { ProductDetailNoEstimation } from './ProductDetailNoEstimation';

type ProductDetailEstimationProps = {
  product: Product;
};

export const ProductDetailEstimation = ({
  product,
}: ProductDetailEstimationProps) => {
  const { estimation } = useEstimation(product);
  const { approveStatus, qualifyStatus } = useProductStatus(product);
  const isLimited =
    approveStatus !== PRODUCT_APPROVE_STATUS.APPROVED &&
    qualifyStatus !== PRODUCT_QUALIFY_STATUS.QUALIFIED;

  if (!estimation) return <ProductDetailNoEstimation product={product} />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {!isLimited && <ProductDetailLoanTerms product={product} />}
      {!isLimited && <ProductDetailProfitability product={product} />}
      {!isLimited && estimation.type === USER_LOAN_PROGRAM.DSCR && (
        <ProductDetailProjectedIncome product={product} />
      )}
      {!isLimited && <ProductDetailClosingCosts product={product} />}
      {!isLimited && <ProductDetailLoanCosts product={product} />}
      {!isLimited && <ProductDetailOtherCosts product={product} />}
      {!isLimited && <ProductDetailSubtotalClosingCosts product={product} />}
      <ProductDetailCashToClose product={product} />
    </Box>
  );
};
