export enum USER_ROLE {
  ADMIN = 'admin',
  USER = 'user',
}

export const userRoles = [
  {
    value: USER_ROLE.ADMIN,
    label: 'Admin',
  },
  {
    value: USER_ROLE.USER,
    label: 'User',
  },
];

export enum USER_APPROVE_STATUS {
  NOT_SUBMITTED = 'not_submitted',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
export enum USER_QUALIFY_STATUS {
  INITIAL = 'initial',
  QUALIFY = 'qualify',
}

export enum CONTRACT_BUYER_STEP {
  BUYER_INFO = 0,
  OFFER_INFO = 1,
  THIRD_PARTY = 2,
  TIMELINE = 3,
  REVIEW = 4,
  COMPLETED = 5,
}
export const contractBuyerSteps = [
  'Buyer Information',
  'Offer Information',
  'Third Parties',
  'Timeline',
  'Review',
];

export enum CONTRACT_AGENT_STEP {
  SUMMARY = 0,
  AGENT_INFO = 1,
  OFFER_INFO = 2,
  SELLER_INFO = 3,
  REVIEW = 4,
  COMPLETED = 5,
}
export const contractAgentSteps = [
  'Transaction Summary',
  'Agent Information',
  'Offer Information',
  'Seller Information',
  'Review',
];

export enum CONTRACT_SELLER_STEP {
  SELLER_INFO = 0,
  PROPERTY_DISCLOSURES = 1,
  PROPERTY_INFO = 2,
  REVIEW = 3,
  COMPLETED = 4,
}
export const contractSellerSteps = [
  'Seller Information',
  'Property Disclosures',
  'Seller Property Information',
  'Review',
];

export enum USER_OCCUPANCY {
  DEFAULT = 'default',
  PRIMARY_RESIDENCE = 'primary_residence',
  SECONDARY_RESIDENCE = 'secondary_residence',
  INVESTMENT_PROPERTY = 'investment_property',
}
export const userOccupancys = [
  {
    label: 'Default',
    value: USER_OCCUPANCY.DEFAULT,
  },
  {
    label: 'Primary Residence',
    value: USER_OCCUPANCY.PRIMARY_RESIDENCE,
  },
  {
    label: 'Secondary Residence',
    value: USER_OCCUPANCY.SECONDARY_RESIDENCE,
  },
  {
    label: 'Investment Property',
    value: USER_OCCUPANCY.INVESTMENT_PROPERTY,
  },
];
export const userOccupancyMap = {
  [USER_OCCUPANCY.DEFAULT]: 'Default',
  [USER_OCCUPANCY.PRIMARY_RESIDENCE]: 'Primary Residence',
  [USER_OCCUPANCY.SECONDARY_RESIDENCE]: 'Secondary Residence',
  [USER_OCCUPANCY.INVESTMENT_PROPERTY]: 'Investment Property',
};

export enum USER_LOAN_ESTIMATION_TYPE {
  INVESTOR = 'investor',
  HOME_BUYER = 'home_buyer',
  REFINANCE = 'refinance',
  COMMERCIAL = 'commercial',
}
export const userLoanEstimationTypes = [
  {
    label: 'Investor',
    value: USER_LOAN_ESTIMATION_TYPE.INVESTOR,
  },
  {
    label: 'Home Buyer',
    value: USER_LOAN_ESTIMATION_TYPE.HOME_BUYER,
  },
  {
    label: 'Refinance',
    value: USER_LOAN_ESTIMATION_TYPE.REFINANCE,
  },
  {
    label: 'Commercial',
    value: USER_LOAN_ESTIMATION_TYPE.COMMERCIAL,
  },
];
export const userLoanEstimationTypeMap = {
  [USER_LOAN_ESTIMATION_TYPE.INVESTOR]: 'Investor',
  [USER_LOAN_ESTIMATION_TYPE.HOME_BUYER]: 'Home Buyer',
  [USER_LOAN_ESTIMATION_TYPE.REFINANCE]: 'Refinance',
  [USER_LOAN_ESTIMATION_TYPE.COMMERCIAL]: 'Commercial',
};

export enum USER_LOAN_PROGRAM {
  DEFAULT = 'default',
  DSCR = 'dscr',
  FHA = 'fha',
  FIX_AND_FLIP = 'fix_and_flip',
  USDA = 'usda',
  VA = 'va',
  CONVENTIONAL = 'conventional',
  CASH = 'cash',
  COMMERCIAL = 'commercial',
}
export const userLoanPrograms = [
  {
    label: 'Default',
    value: USER_LOAN_PROGRAM.DEFAULT,
  },
  {
    label: 'DSCR',
    value: USER_LOAN_PROGRAM.DSCR,
  },
  {
    label: 'FHA',
    value: USER_LOAN_PROGRAM.FHA,
  },
  {
    label: 'Fix and Flip',
    value: USER_LOAN_PROGRAM.FIX_AND_FLIP,
  },
  {
    label: 'USDA',
    value: USER_LOAN_PROGRAM.USDA,
  },
  {
    label: 'VA',
    value: USER_LOAN_PROGRAM.VA,
  },
  {
    label: 'Conventional',
    value: USER_LOAN_PROGRAM.CONVENTIONAL,
  },
];
export const userLoanProgramMap = {
  [USER_LOAN_PROGRAM.DEFAULT]: 'Default',
  [USER_LOAN_PROGRAM.DSCR]: 'DSCR',
  [USER_LOAN_PROGRAM.FHA]: 'FHA',
  [USER_LOAN_PROGRAM.FIX_AND_FLIP]: 'Fix and Flip',
  [USER_LOAN_PROGRAM.USDA]: 'USDA',
  [USER_LOAN_PROGRAM.VA]: 'VA',
  [USER_LOAN_PROGRAM.CONVENTIONAL]: 'Conventional',
  [USER_LOAN_PROGRAM.CASH]: 'Cash Offer',
  [USER_LOAN_PROGRAM.COMMERCIAL]: 'Commercial',
};

export const USER_LOAN_ESTIMATION_DICT = {
  [USER_LOAN_ESTIMATION_TYPE.INVESTOR]: {
    LOAN_PROGRAMS: [
      USER_LOAN_PROGRAM.DEFAULT,
      USER_LOAN_PROGRAM.DSCR,
      USER_LOAN_PROGRAM.FIX_AND_FLIP,
      USER_LOAN_PROGRAM.CONVENTIONAL,
    ],
    OCCUPANCY: [USER_OCCUPANCY.INVESTMENT_PROPERTY],
  },
  [USER_LOAN_ESTIMATION_TYPE.HOME_BUYER]: {
    LOAN_PROGRAMS: [
      USER_LOAN_PROGRAM.DEFAULT,
      USER_LOAN_PROGRAM.USDA,
      USER_LOAN_PROGRAM.VA,
      USER_LOAN_PROGRAM.CONVENTIONAL,
      USER_LOAN_PROGRAM.FHA,
    ],
    OCCUPANCY: [
      USER_OCCUPANCY.PRIMARY_RESIDENCE,
      USER_OCCUPANCY.SECONDARY_RESIDENCE,
      USER_OCCUPANCY.INVESTMENT_PROPERTY,
    ],
  },
  [USER_LOAN_ESTIMATION_TYPE.REFINANCE]: {
    LOAN_PROGRAMS: Object.values(USER_LOAN_PROGRAM),
    OCCUPANCY: Object.values(USER_OCCUPANCY),
  },
  [USER_LOAN_ESTIMATION_TYPE.COMMERCIAL]: {
    LOAN_PROGRAMS: [USER_LOAN_PROGRAM.DEFAULT],
    OCCUPANCY: [USER_OCCUPANCY.INVESTMENT_PROPERTY],
  },
};

export const userApproveStatuses = [
  {
    value: USER_APPROVE_STATUS.NOT_SUBMITTED,
    label: 'Not Submitted',
  },
  {
    value: USER_APPROVE_STATUS.APPROVED,
    label: 'Approved',
  },
  {
    value: USER_APPROVE_STATUS.REJECTED,
    label: 'Rejected',
  },
  {
    value: USER_APPROVE_STATUS.PENDING,
    label: 'Pending',
  },
];

// Contract
export enum CONTRACT_APPRAISAL_CONTINGENCY {
  AT_LEAST_PURCHASE_PRICE = 'at_least_purchase_price',
  AT_LEAST_LISTING_PRICE = 'at_least_listing_price',
  AT_LEST_WITHIN_RANGE = 'at_least_within_range',
  NONE = 'none',
  NOT_SELECTED = '',
}
export const contractAppraisalContingencies = [
  {
    value: CONTRACT_APPRAISAL_CONTINGENCY.AT_LEAST_PURCHASE_PRICE,
    label: 'At least Purchase Price',
  },
  {
    value: CONTRACT_APPRAISAL_CONTINGENCY.AT_LEAST_LISTING_PRICE,
    label: 'At least Listing Price',
  },
  {
    value: CONTRACT_APPRAISAL_CONTINGENCY.AT_LEST_WITHIN_RANGE,
    label: 'At least within the range of Purchase Price - Down payment',
  },
  {
    value: CONTRACT_APPRAISAL_CONTINGENCY.NONE,
    label: 'No Appraisal Contingency',
  },
];

export enum CONTRACT_FINANCING_CONTINGENCY {
  AT_LEAST_PURCHASE_PRICE = 'at_least_purchase_price',
  TIMELINE = 'timeline',
  NOT_SELECTED = '',
}
export const contractFinancingContingencies = [
  {
    value: CONTRACT_FINANCING_CONTINGENCY.AT_LEAST_PURCHASE_PRICE,
    label: 'At least Purchase Price',
  },
  {
    value: CONTRACT_FINANCING_CONTINGENCY.TIMELINE,
    label: 'Timeline',
  },
];

export enum CONTRACT_INSPECTION_CONTINGENCY {
  RESERVE_THE_RIGHT = 'reserve_the_right',
  WAIVE_THR_RIGHT = 'waive_the_right',
  NOT_SELECTED = '',
}
export const contractInspectionContingencies = [
  {
    value: CONTRACT_INSPECTION_CONTINGENCY.RESERVE_THE_RIGHT,
    label: 'Reserve the right to negotiate price post inspection',
  },
  {
    value: CONTRACT_INSPECTION_CONTINGENCY.WAIVE_THR_RIGHT,
    label: 'Waive the right to negotiate price post inspection',
  },
];

export enum CONTRACT_POST_CLOSE_OCCUPANCY {
  YES = 'yes',
  NO = 'no',
  NOT_SELECTED = '',
}
export const contractPostCloseOccupancies = [
  {
    value: CONTRACT_POST_CLOSE_OCCUPANCY.YES,
    label: 'Offer the buyer an option to rent the house post closing',
  },
  {
    value: CONTRACT_POST_CLOSE_OCCUPANCY.NO,
    label: 'Do not offer the buyer to rent house post closing',
  },
];

export enum CONTRACT_UNBEATABLE_CONTINGENCY {
  YES = 'yes',
  NO = 'no',
  NOT_SELECTED = '',
}
export const contractUnbeatableContingencies = [
  {
    value: CONTRACT_UNBEATABLE_CONTINGENCY.YES,
    label:
      'Unbeatable Loans LLC to receive $1,000 as contract negotiation fee.',
  },
  {
    value: CONTRACT_UNBEATABLE_CONTINGENCY.NO,
    label:
      'Seller to credit listed Buyers Agent Commission to buyer at closing, covering first the Unbeatable Loans LLC contract negotiation fee, followed by traditional closing costs.',
  },
];

export enum CONTRACT_TITLE_COMPANY_CHOICE_TIMELINE {
  RESERVED_BY_BUYER = 'reserved_by_buyer',
  WAIVE_RIGHT = 'waive_right',
  ELECT_AFFILIATED = 'elect_affiliated',
  NOT_SELECTED = '',
}
export const contractTitleCompanyChoiceTimelines = [
  {
    value: CONTRACT_TITLE_COMPANY_CHOICE_TIMELINE.RESERVED_BY_BUYER,
    label:
      'Right to choose Title/Settlement company is reserved by buyer and will be decided on or before',
  },
  {
    value: CONTRACT_TITLE_COMPANY_CHOICE_TIMELINE.WAIVE_RIGHT,
    label: 'Buyer waives right to choose title/settlement company',
  },
  {
    value: CONTRACT_TITLE_COMPANY_CHOICE_TIMELINE.ELECT_AFFILIATED,
    label:
      'Buyer elects to prefer the settlement company affiliated with Lender and has acknowledged affiliated business relationship',
  },
];

export enum CONTRACT_TRANSFER_TAX_ELECTION {
  SELLER_PAYS_ALL = 'seller_pays_all',
  BUYER_PAYS_EXCESS = 'buyer_pays_excess',
  NOT_SELECTED = '',
}
export const contractTransferTaxElections = [
  {
    value: CONTRACT_TRANSFER_TAX_ELECTION.SELLER_PAYS_ALL,
    label:
      'Buyer elects that the seller pays the entire cost of transfer taxes from proceeds of sale including excess over buyer agent compensation.',
  },
  {
    value: CONTRACT_TRANSFER_TAX_ELECTION.BUYER_PAYS_EXCESS,
    label:
      'Buyer maintains that the transfer taxes will be paid by buyer exceeding the buyers agent compensation, where buyers agent compensation is used to cover other closing costs.',
  },
];

export enum CONTRACT_COPY_OF_INSPECTION {
  PROVIDE_FULL_COPY = 'provide_full_copy',
  WITHHOLD_FULL_COPY = 'withhold_full_copy',
  NOT_SELECTED = '',
}
export const contractCopyOfInspections = [
  {
    value: CONTRACT_COPY_OF_INSPECTION.PROVIDE_FULL_COPY,
    label: 'Buyer to provide a full copy of inspection to seller.',
  },
  {
    value: CONTRACT_COPY_OF_INSPECTION.WITHHOLD_FULL_COPY,
    label:
      'Buyer reserves right to withhold full copy of inspection from seller.',
  },
];

export enum CONTRACT_INSPECTION_PRESENCE_OPTION {
  BE_PRESENT = 'be_present',
  WAIVE_RIGHT = 'waive_right',
  NOT_SELECTED = '',
}
export const contractInspectionPresenceOptions = [
  {
    value: CONTRACT_INSPECTION_PRESENCE_OPTION.BE_PRESENT,
    label: 'Buyer to be present at time of inspection.',
  },
  {
    value: CONTRACT_INSPECTION_PRESENCE_OPTION.WAIVE_RIGHT,
    label: 'Buyer waives right to be present at time of inspection.',
  },
];

export enum CONTRACT_DEPOSIT_OPTION {
  DEPOSIT1 = 'deposit1',
  DEPOSIT2 = 'deposit2',
  NONE = 'none',
  NOT_SELECTED = '',
}
export const contractDepositOptions: {
  value: CONTRACT_DEPOSIT_OPTION;
  label1: string;
  label2?: string;
  name?: 'deposit1Days' | 'deposit2Days';
}[] = [
  {
    value: CONTRACT_DEPOSIT_OPTION.DEPOSIT1,
    label1: 'Deposit within ',
    label2: ' days of contract execution.',
    name: 'deposit1Days',
  },
  {
    value: CONTRACT_DEPOSIT_OPTION.DEPOSIT2,
    label1: 'Deposit 2 within ',
    label2: ' days of contract execution.',
    name: 'deposit2Days',
  },
  {
    value: CONTRACT_DEPOSIT_OPTION.NONE,
    label1: 'No Deposit',
  },
];

// layout
export const HEADER_HEIGHT = 70;
export const HEADER_HEIGHT_ADDITIONAL = 50;
export const Z_INDEX = {
  HEADER: 1400,
};

// page
export const PRODUCT_COUNT_PER_PAGE = 20;
export const PRODUCT_APPROVE_PRICE_RATIO = 0.03;

export const DEFAULT_ADMIN_ITEM_COUNT_PER_PAGE = 20;

export const S3_FOLDER = {
  DOCUMENT: 'document',
  DISCLOSURE: 'disclosure',
};
