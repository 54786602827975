import { USER_LOAN_PROGRAM } from '@/config/constants';
import {
  CalculateClosingDisclosureProps,
  calculateTaxFactor,
  calculateTransferTaxFactor,
  chooseMarketInterestRate,
} from '.';

const calculatePrivateMortgageInsurance = (
  loanAmount: number,
  purchasePrice: number,
  creditScore: number
) => {
  const basePremiumRates = {
    excellentCredit: {
      LTV80: 0.0,
      LTV81: 0.0008,
      LTV85: 0.001,
      LTV90: 0.0015,
      LTV95: 0.002,
      LTVOver95: 0.0025,
    },
    goodCredit: {
      LTV80: 0.0,
      LTV81: 0.0008,
      LTV85: 0.0015,
      LTV90: 0.002,
      LTV95: 0.003,
      LTVOver95: 0.004,
    },
    fairCredit: {
      LTV80: 0.0,
      LTV85: 0.003,
      LTV90: 0.006,
      LTV95: 0.007,
      LTVOver95: 0.008,
    },
    poorCredit: {
      LTV80: 0.0,
      LTV85: 0.0075,
      LTV90: 0.0085,
      LTV95: 0.0095,
      LTVOver95: 0.011,
    },
  };
  type BasePremiumRateType =
    | 'excellentCredit'
    | 'goodCredit'
    | 'fairCredit'
    | 'poorCredit';
  type LTVType = 'LTV80' | 'LTV85' | 'LTV90' | 'LTV95' | 'LTVOver95';

  let creditTier: BasePremiumRateType = 'poorCredit';
  if (creditScore >= 750) {
    creditTier = 'excellentCredit';
  } else if (creditScore >= 700) {
    creditTier = 'goodCredit';
  } else if (creditScore >= 650) {
    creditTier = 'fairCredit';
  } else {
    creditTier = 'poorCredit';
  }

  let LTV: LTVType = 'LTVOver95';
  if (loanAmount / purchasePrice <= 0.8) {
    LTV = 'LTV80';
  } else if (loanAmount / purchasePrice <= 0.85) {
    LTV = 'LTV85';
  } else if (loanAmount / purchasePrice <= 0.9) {
    LTV = 'LTV90';
  } else if (loanAmount / purchasePrice <= 0.95) {
    LTV = 'LTV95';
  } else {
    LTV = 'LTVOver95';
  }

  const basePremiumRate = basePremiumRates[creditTier][LTV];

  const PMIRate = basePremiumRate * loanAmount;
  const monthlyPMI = PMIRate / 12;
  return monthlyPMI;
};

export const calculateConventionalClosingDisclosure = ({
  offerPrice,
  listPrice,
  downPayment = 0,
  zipCode = 0,
  hoaFees = 0,
  commission = 0,
  marketInterestRate = 7.5,
  creditScore = 0,
  loanTerm = 30,
}: CalculateClosingDisclosureProps) => {
  const purchasePrice = offerPrice || listPrice;

  const taxFactor = calculateTaxFactor(zipCode);
  const transferTaxFactor = calculateTransferTaxFactor(zipCode);
  const loanAmount = purchasePrice - downPayment;

  //database Constants
  const interestRate = chooseMarketInterestRate(
    marketInterestRate,
    creditScore
  );

  //change on user input on account page
  const numberOfPayments = loanTerm * 12;

  // constant fees
  const countyRecordingFees = 250;
  const stateRecordingFees = 150;
  const titleTitleSearchBase = 250;
  const recordingDeedBase = 75;
  const appraisalFeeBase = 550;
  const underwritingFee = 1295;
  const applicationFeeBase = 995;
  const recordingTitle = 75;
  const recordingMortgage = 75;
  const surveyFee = 250;
  const pestInspectionFee = 145;
  const floodMonitoringFee = 25;
  const floodDeterminationFee = 35;
  const creditReportFeeBase = 150;
  const processingFeeBase = 795;
  const titleSettlementAgentFee = 350;
  const earnestMoneyDeposit = 1000;
  const insuranceBinder = 150;
  const lenderCredits = 0;
  const initialEscrowMortgageInsurance = 0;
  const prepaidsPropertyTaxes = -100; //this is a credit from the buyer

  // factored calculations
  const annualPropertyTax = (purchasePrice * taxFactor) / 100;
  //UNFACTORED CALCULATIONS, NEED TO FIGURE OUT HOW TO INCLUDE CREDIT AND STATE FACTORS
  const optionalOwnersTitleInsuranceBase = purchasePrice * 0.00575; //State, Credit
  const recordingTransferTaxBase = purchasePrice * 0.01; //State dependent.
  const monthlyPMIPayment = calculatePrivateMortgageInsurance(
    loanAmount,
    purchasePrice,
    creditScore
  );
  const monthlyHomeownersInsurance = (purchasePrice * 0.01035) / 12;
  const monthlyPropertyTaxes = annualPropertyTax / 12;
  const prepaidsHomeownersInsurance = purchasePrice * 0.01035;
  const titleLendersTitleInsuranceBase = purchasePrice * 0.0045;
  const homeInspectionFeeBase = purchasePrice * 0.0035;
  const initialEscrowTaxes = monthlyPropertyTaxes * 6;
  const initialEscrowHOI = ((purchasePrice * 0.01) / 12) * 3;

  //these aren't going to change, they are determined
  const dailyInterestRate = interestRate / 365 / 100; // Convert annual rate to daily decimal
  const prepaidInterest = loanAmount * dailyInterestRate * 15; // Return the result rounded to 2 decimal places
  const monthlyInterestRate = interestRate / 12 / 100; // Convert annual rate to monthly decimal
  const principalAndInterest =
    (loanAmount *
      (monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments))) /
    (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  const transferTaxes = purchasePrice * transferTaxFactor;
  const estimatedEscrow =
    monthlyHomeownersInsurance + monthlyPropertyTaxes + monthlyPMIPayment;
  const piti = principalAndInterest + estimatedEscrow;
  const cleanPayment = piti;
  const otherCosts =
    optionalOwnersTitleInsuranceBase +
    pestInspectionFee +
    initialEscrowTaxes +
    prepaidsPropertyTaxes +
    prepaidInterest +
    prepaidsHomeownersInsurance +
    stateRecordingFees +
    countyRecordingFees +
    recordingMortgage +
    recordingTitle +
    recordingDeedBase +
    transferTaxes;
  const costTotals =
    insuranceBinder +
    titleTitleSearchBase +
    titleLendersTitleInsuranceBase +
    surveyFee +
    titleSettlementAgentFee +
    floodDeterminationFee +
    floodMonitoringFee +
    creditReportFeeBase +
    appraisalFeeBase +
    applicationFeeBase +
    underwritingFee +
    processingFeeBase +
    creditReportFeeBase;
  const closingCosts = otherCosts + costTotals;
  const cashToClose = closingCosts + downPayment + earnestMoneyDeposit;
  const cashOutsideClosing =
    appraisalFeeBase + earnestMoneyDeposit + homeInspectionFeeBase;
  const unbeatableCredit = (purchasePrice * commission) / 100; //this is where we put the buyers agent commission
  const unbeatableDeal = cashToClose - unbeatableCredit;

  // Perform calculations using the provided purchase price and constants
  // Prepare the closing disclosure object
  const closingDisclosure = {
    type: USER_LOAN_PROGRAM.CONVENTIONAL,
    offerPrice,
    listPrice,
    purchasePrice,
    commission,
    marketInterestRate,
    creditScore,
    downPayment,
    loanAmount,
    interestRate,

    cleanPayment,
    cashOutsideClosing,
    monthlyPMIPayment,
    processingFeeBase,
    principalAndInterest,
    homeInspectionFeeBase,
    loanTerm,
    optionalOwnersTitleInsuranceBase,
    hoaFees,
    earnestMoneyDeposit,
    piti,
    estimatedEscrow,
    monthlyPropertyTaxes,
    monthlyHomeownersInsurance,
    prepaidsHomeownersInsurance,
    prepaidsPropertyTaxes,
    prepaidInterest,
    titleSettlementAgentFee,
    titleLendersTitleInsuranceBase,
    titleTitleSearchBase,
    insuranceBinder,
    recordingDeedBase,
    appraisalFeeBase,
    underwritingFee,
    applicationFeeBase,
    pestInspectionFee,
    floodMonitoringFee,
    floodDeterminationFee,
    creditReportFeeBase,
    recordingTitle,
    recordingMortgage,
    countyRecordingFees,
    stateRecordingFees,
    surveyFee,
    initialEscrowTaxes,
    initialEscrowMortgageInsurance,
    initialEscrowHOI,
    lenderCredits,
    costTotals,
    otherCosts,
    closingCosts,
    cashToClose,
    unbeatableDeal,
    transferTaxes,
  };
  return closingDisclosure;
};

export type ProductConventionalEsitmation = ReturnType<
  typeof calculateConventionalClosingDisclosure
>;
