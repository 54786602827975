import { Card, CardContent, CardHeader } from '@mui/material';

import { CommonFormControl } from '@/components/Common/CommonFormControl';
import { useEstimation } from '@/hooks/useEstimation';
import { ProductDSCREsitmation } from '@/utils/calculator/DSCR';
import { formatNumber, formatPrice } from '@/utils/helper';
import { Product } from '@/types/ProductType';

type ProductDetailProjectedIncomeProps = {
  product: Product;
};

export const ProductDetailProjectedIncome = ({
  product,
}: ProductDetailProjectedIncomeProps) => {
  const { estimation } = useEstimation(product);
  const dscrEstimation = estimation as ProductDSCREsitmation;

  if (!estimation) return null;

  return (
    <Card>
      <CardHeader
        title="Projected Income"
        avatar={<i className="fa-light fa-inbox-full" />}
      />
      <CardContent>
        <CommonFormControl
          label="Projected Monthly Rent:"
          secondLabel={formatPrice(dscrEstimation.rent)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Debt Service Coverage Ratio:"
          secondLabel={formatNumber(dscrEstimation.dscr)}
          inLine
          labelWidth={270}
        />
        <CommonFormControl
          label="Monthly Cash Flow:"
          secondLabel={formatPrice(dscrEstimation.cashFlow)}
          inLine
          labelWidth={270}
        />
      </CardContent>
    </Card>
  );
};
