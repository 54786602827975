import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

import {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
} from '@/apis/extended/userApi';
import { CommonDialog } from '../Common/CommonDialog';
import { CommonFormControl } from '../Common/CommonFormControl';
import { CommonPhoneInput } from '../Common/CommonPhoneInput';
import { ManageDocumentsModal } from './ManageDocumentsModal';

type GetApproveModalProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export const GetApproveModal = ({ isOpen, onClose }: GetApproveModalProps) => {
  const { data, isFetching } = useGetCurrentUserQuery();
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isManageDocumentsModalOpen, setIsManageDocumentsModalOpen] =
    useState(false);
  const [form, setForm] = useState({
    phone: '',
  });

  useEffect(() => {
    setForm({
      phone: (data?.phone || '') + '',
    });
  }, [data]);

  const isUpdated = useMemo(() => {
    return form.phone !== data?.phone;
  }, [form, data]);

  const handleSave = async () => {
    setIsUpdating(true);

    updateUser({
      phone: form.phone,
    });

    setIsUpdating(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <CommonDialog
      isOpen={isOpen}
      title="Get Approved"
      icon="fa-light fa-screwdriver-wrench"
      customActions={
        <>
          <LoadingButton
            loading={isFetching || isUpdatingUser || isUpdating}
            disabled={!isUpdated}
            variant="contained"
            startIcon={<i className="fa-light fa-pencil" />}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </>
      }
      onClose={onClose}
    >
      <CommonFormControl label="Phone Number:" labelWidth={150}>
        <CommonPhoneInput
          value={form.phone}
          fullWidth
          onChange={(value) => {
            setForm({ ...form, phone: value });
          }}
        />
      </CommonFormControl>
      <CommonFormControl label="Document:" labelWidth={150} sx={{ mt: 2 }}>
        <Button onClick={() => setIsManageDocumentsModalOpen(true)}>
          Manage Documents
        </Button>
      </CommonFormControl>
      <ManageDocumentsModal
        isOpen={isManageDocumentsModalOpen}
        onClose={() => setIsManageDocumentsModalOpen(false)}
      />
    </CommonDialog>
  );
};
