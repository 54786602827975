import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import {
  CONTRACT_APPRAISAL_CONTINGENCY,
  CONTRACT_FINANCING_CONTINGENCY,
  CONTRACT_INSPECTION_CONTINGENCY,
  CONTRACT_POST_CLOSE_OCCUPANCY,
  CONTRACT_UNBEATABLE_CONTINGENCY,
  contractAppraisalContingencies,
  contractFinancingContingencies,
  contractInspectionContingencies,
  contractPostCloseOccupancies,
  contractUnbeatableContingencies,
} from '@/config/constants';
import {
  useGetContractQuery,
  useUpdateContractBuyerMutation,
} from '@/apis/extended/contractApi';
import { ContractContingency } from '@/types/ContractType';
import { CommonActions } from '../Common/CommonContract/CommonActions';
import { CommonPrevNextButtons } from '../Common/CommonContract/CommonPrevNextButtons';

const initialForm: ContractContingency = {
  appraisalContingency: CONTRACT_APPRAISAL_CONTINGENCY.NOT_SELECTED,
  financingContingency: CONTRACT_FINANCING_CONTINGENCY.NOT_SELECTED,
  inspectionContingency: CONTRACT_INSPECTION_CONTINGENCY.NOT_SELECTED,
  postCloseOccupancy: CONTRACT_POST_CLOSE_OCCUPANCY.NOT_SELECTED,
  unbeatableContingency: CONTRACT_UNBEATABLE_CONTINGENCY.NOT_SELECTED,
};

type ContractDetailBuyerContingencyProps = {
  contractId: string;
  onPrev: () => void;
  onNext: () => void;
};

export const ContractDetailBuyerContingency = ({
  contractId,
  onPrev,
  onNext,
}: ContractDetailBuyerContingencyProps) => {
  const { data: contract } = useGetContractQuery(contractId);
  const [updateContractBuyer, { isLoading }] = useUpdateContractBuyerMutation();

  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const contingency = contract?.contingency;
    if (!contingency) return;
    setForm({
      appraisalContingency: contingency.appraisalContingency,
      financingContingency: contingency.financingContingency,
      inspectionContingency: contingency.inspectionContingency,
      postCloseOccupancy: contingency.postCloseOccupancy,
      unbeatableContingency: contingency.unbeatableContingency,
    });
  }, [contract]);

  const isValid = useMemo(() => {
    return (
      form.appraisalContingency &&
      form.financingContingency &&
      form.inspectionContingency &&
      form.postCloseOccupancy &&
      form.unbeatableContingency
    );
  }, [form]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    await updateContractBuyer({
      contractId,
      contingency: {
        ...form,
      },
    }).unwrap();
    onNext();
  };

  return (
    <Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Contingencies"
          action={
            <CommonActions
              isLoading={isLoading}
              isNextDisabled={!isValid}
              onPrev={onPrev}
              onNext={handleSubmit}
            />
          }
          avatar={<i className="fa-light fa-flux-capacitor" />}
        />
        <CardContent>
          <Box>
            <Typography variant="h5">Appraisal Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="appraisalContingency"
              value={form.appraisalContingency}
              onChange={handleInputChange}
            >
              {contractAppraisalContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Financing Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="financingContingency"
              value={form.financingContingency}
              onChange={handleInputChange}
            >
              {contractFinancingContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Inspection Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="inspectionContingency"
              value={form.inspectionContingency}
              onChange={handleInputChange}
            >
              {contractInspectionContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Post-Close Occupancy:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="postCloseOccupancy"
              value={form.postCloseOccupancy}
              onChange={handleInputChange}
            >
              {contractPostCloseOccupancies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Unbeatable Contingency:</Typography>
            <RadioGroup
              sx={{ mt: 1 }}
              name="unbeatableContingency"
              value={form.unbeatableContingency}
              onChange={handleInputChange}
            >
              {contractUnbeatableContingencies.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </Box>
        </CardContent>
      </Card>
      <CommonPrevNextButtons
        sx={{ mt: 3 }}
        isLoading={isLoading}
        isNextDisabled={!isValid}
        onPrev={onPrev}
        onNext={handleSubmit}
      />
    </Box>
  );
};
