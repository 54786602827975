import { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';

import { useRemoveUserMutation } from '@/apis/extended/userApi';
import { logout } from '@/utils/auth';
import { RemoveUserModal } from '../ConfirmModals/RemoveUserModal';

export const ProfileDeleteAccount = () => {
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [removeUser] = useRemoveUserMutation();

  const handleRemoveUser = () => {
    removeUser();
    setIsRemoveUserModalOpen(false);
    logout();
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title="Delete Account"
        avatar={<i className="fa-light fa-trash-can" />}
      />
      <CardContent>
        <Typography variant="body1">
          Once you delete your user, there is no going back. Please be certain.
        </Typography>
        <Button
          variant="contained"
          color="error"
          sx={{ mt: 1 }}
          onClick={() => setIsRemoveUserModalOpen(true)}
        >
          Delete
        </Button>
      </CardContent>
      <RemoveUserModal
        isOpen={isRemoveUserModalOpen}
        onConfirm={handleRemoveUser}
        onClose={() => setIsRemoveUserModalOpen(false)}
      />
    </Card>
  );
};
